import React, { useState } from "react";
import { Typography } from "@mui/material";
import SelectorBox from "../../Components/SelectorBox/SelectorBox";
import InputBoxElement from "../../Components/InputBox/InputBox";
import { Stack, Box, Divider } from "@mui/material";
import { RevertList } from "../../Components/RevertList";
import { useSettingsContext } from "../../../contexts/SettingsContext";
import BooleanToggleElement from "../../Components/BooleanToggle";

import { useAuth } from "../../../contexts/AuthContext";
import { getUrlVariableValue } from "../../../utility-functions/utility-functions.js";
import {  Skeleton } from "@mui/material";

export default function AdditionalSettings() {
  const { getUID } = useAuth();
  const { settings, dispatch, changes, setChanges } = useSettingsContext();

  function handleChange(value, id) {
    if (id === "useEmailer") {
      // setEmailer(value);
      dispatch({
        type: "UPDATE_SETTINGS_PROPERTY",
        settingsId: getUrlVariableValue("settingsId"),
        uid: getUID(),
        path: `${getUrlVariableValue("settingsId")}.generalSettings.useEmailer`,
        value: value,
      });
    }

    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      id,
      settings[getUrlVariableValue("settingsId")].generalSettings[id],
      value
    );
    setChanges(newRev);

    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue("settingsId")}.generalSettings.${id}`,
      value: value,
    });
  }

 

  return (settings.hasOwnProperty("1") && settings.hasOwnProperty("staff"))  ? (
    <Box className="settings-page">
      <Typography variant="h2" className="settings-page__title">
        Admin
      </Typography>
      <Divider className="settings-page__divider" />
      <Stack spacing={2}>

        <Typography>Pay Period Info:</Typography>
        <SelectorBox
          id="payPeriod"
          onChange={handleChange}
          default={settings[getUrlVariableValue("settingsId")].generalSettings.payPeriod}
          size={200}
          options={["Bi-Weekly", "Semi-Monthly", "Monthly", "Weekly"]}
          label="Frequency"
          name="Frequency"
        ></SelectorBox>

        <Typography>Use PayWell's "Pay-Stub Emailer" Functionality:</Typography>
        <BooleanToggleElement
          onChange={handleChange}
          id="useEmailer"
          default={settings[getUrlVariableValue("settingsId")].generalSettings.useEmailer}
        ></BooleanToggleElement>

        {settings[getUrlVariableValue("settingsId")].generalSettings?.useEmailer ? (
          <Stack>
            <Typography>Custom email subject header:</Typography>
            <InputBoxElement
              id="customEmailSubject"
              onChange={handleChange}
              default={
                settings[getUrlVariableValue("settingsId")].generalSettings
                  .customEmailSubject
              }
              types={["text"]}
              size={100}
              label="Custom Email Subject"
            ></InputBoxElement>
          </Stack>
        ) : null}
      </Stack>
    </Box>
  ) : (
    <Box className="settings-page">
      <Skeleton>
        <Typography variant="h2" className="settings-page__title">
          Studios
        </Typography>
      </Skeleton>
      <Divider className="settings-page__divider" />
      <Skeleton className="settings-page__attribute-box settings-page__attribute-box--skeleton"></Skeleton>
    </Box>
  );
}
