import { sortArrayLastName } from "../../utility-functions/GeneralFunctions/SortArrayByLastName.js";
import { ClassCreation } from "../ClassCreation/utility-functions.js";
/**** CREATE CLASS DETAIL ****/
//import XLSX from "xlsx";
import XLSX from "@sheet/core";
import { DetailReferenceBox } from "../../utility-functions/ReportCompiler/classes/DetailReferenceBox.js";
import { CellReference } from "../../utility-functions/ReportCompiler/classes/CellReference.js";
import { CommissionDetailReferenceBox } from "../../utility-functions/utility-functions.js";
import { specialProcessorValues } from "../../pages/StudioSettings/CommonVariables.js";
import {
  getPulledLocations,
  initialReportCompiler,
} from "../ReportCompiler/utility-functions.js";
/**** CLASSES ****/
export class ExcelOutput {
  constructor(reportCompilerState) {
    this.reportCompilerState = reportCompilerState;
    this.classTypeBoxRangesArray = [];
    this.nonFormulaSheetCopy = null;
    this.commentColumn = 20;
    this.timeClockPayFormulas = [];
    this.blankExcelRow = ["", "", "", "", "", "", "", "", "", "", "", "", ""];
    this.alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    this.currencyLocations = []; // [Int: Cell Tab Index,  , ]
    this.cellRefByInstructor = [];
    this.detailInstructorRefBoxList = [];
    this.timeDetailReferenceList = [];
    this.commissionDetailRefBoxList = [];
    this.summaryStudioInstructorLocations = [];
    this.isInitialized = true;
  }

  /**** CREATE CLASS DETAIL ****/
  addClassDetailToExcelWorkbook(wb) {
    this.classTypeBoxRangesArray = [];
    let sheet_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .sessionName + " Detail";
    wb.SheetNames.push(sheet_name);
    const classDetail = this.createClassDetail();
    const classDetailWS = XLSX.utils.aoa_to_sheet(classDetail);
    this.addClassTypeTotalFormulas(classDetailWS);
    this.addClassTotalFormulas(classDetailWS);
    wb.Sheets[sheet_name] = classDetailWS;
  }

  createClassDetail() {
    const instructorArray =
      this.reportCompilerState.studiosInformation.instructorsArray;

    const classDetail = []; // ~~~~ is classDetail the same as "classes" property in ClassCreation class property?

    this.addDetailHeader(classDetail);

    for (let i = 0; i < instructorArray.length; i++) {
      const classDetailInstructorRefBox = new DetailReferenceBox(
        "Class",
        instructorArray[i].primaryName
      );
      classDetailInstructorRefBox.startingRef = classDetail.length + 2;

      this.addSessions(classDetail, instructorArray[i]);

      classDetailInstructorRefBox.endingRef = classDetail.length;

      //classDetail.push(this.blankExcelRow);
      classDetail.push(this.blankExcelRow);
      classDetail.push([
        instructorArray[i].primaryName + " Totals",
        "",
        "",
        "",
        0,
        0,
        "",
      ]);
      this.currencyLocations.push([0, 5, classDetail.length]);

      classDetail.push(this.blankExcelRow);
      classDetail.push(this.blankExcelRow);

      this.detailInstructorRefBoxList.push(classDetailInstructorRefBox);
    }
    return classDetail;
  }

  addSessions(sessionDetail, specialist) {
    let session_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .sessionName;
    let sessions_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .sessionsName;
    let location_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .locationName;
    const organizedTable = this.reportCompilerState.studiosInformation.classes;
    let bucketNames =
      this.reportCompilerState.inputFilesArrays.questions.classBuckets.sort(
        (a, b) => {
          if (a.type < b.type) return -1;
          if (a.type > b.type) return 1;
          return 0;
        }
      );
    let includeReschedules = this.reportCompilerState.inputFilesArrays.questions?.showRescheduledClassesInOutput ?? true;

    sessionDetail.push([specialist.getNameString()]);
    sessionDetail.push(this.blankExcelRow);

    for (let j = 0; j < bucketNames.length; j++) {
      let bucket = bucketNames[j];

      sessionDetail.push([
        bucket.type.replace(/(?<!^)([A-Z])/g, "-$1") + " " + sessions_name,
        "",
        "",
        "",
        "",
        "",
        "",
      ]);
      sessionDetail.push([
        session_name,
        location_name,
        "Date",
        "Time",
        session_name + " Size",
        "Payment",
        "Comments",
      ]);

      let start = sessionDetail.length;

      for (let x = 0; x < organizedTable.length; x++) {
        if (specialist.isNamed(organizedTable[x].instructor)) {
          if (organizedTable[x].type === bucket.type) {
            if(organizedTable[x].comment.includes("*RESCHEDULED*") && !includeReschedules){
              organizedTable[x].name = "*RESCHEDULED* - " + organizedTable[x].name;
              continue;
            }
              let sessionName = organizedTable[x].name;

              let clientCount = organizedTable[x].attendeeCount;
              if (bucket.hasSignUpsPostSession || bucket.type === "Intro") {
                const signUps = ClassCreation.findSignUps(organizedTable[x]);

                organizedTable[x].comment.push(
                  "(" +
                  signUps +
                  "/" +
                  organizedTable[x].attendeeCount +
                  ") signups"
                );
              }

              sessionDetail.push([
                sessionName,
                organizedTable[x].location,
                organizedTable[x].date.toDateString(),
                organizedTable[x].date.toLocaleTimeString("en-US"),
                clientCount,
                organizedTable[x].pay,
                organizedTable[x].commentsToString(),
              ]);
              this.cellRefByInstructor.push(
                new CellReference(
                  bucket.type,
                  specialist.primaryName,
                  organizedTable[x].location,
                  sessionDetail.length,
                  5,
                  organizedTable[x].pay
                )
              );
              organizedTable[x].addedToExcel = true;
              this.currencyLocations.push([0, 5, sessionDetail.length]);
          }
        }
      }
      this.classTypeBoxRangesArray.push({
        start: start,
        end: sessionDetail.length,
      });
      sessionDetail.push([
        bucket.type.replace(/(?<!^)([A-Z])/g, "-$1") + " Totals",
        "",
        "",
        "",
        0,
        0,
        "",
      ]);
      this.currencyLocations.push([0, 5, sessionDetail.length]);
      sessionDetail.push(this.blankExcelRow);
    }
  }

  findStaffFromName(name) {
    const staffArray = this.reportCompilerState.studiosInformation.staffArray;
    for (let i = 0; i < staffArray.length; i++) {
      if (staffArray[i].isNamed(name)) {
        return staffArray[i];
      }
    }
    return null;
  }

  lastIndexOfChangedDifference(numbers) {
    let prevDifference = Math.abs(numbers[0] - numbers[1]);
    let lastChangedIndex = -1;

    // Iterate through the numbers
    for (let i = 1; i < numbers.length - 1; i++) {
      let difference = Math.abs(
        parseFloat(numbers[i]) - parseFloat(numbers[i + 1])
      );
      if (prevDifference !== difference) {
        lastChangedIndex = i;
      }
      prevDifference = difference;
    }

    if (lastChangedIndex - 1 < 0) {
      return 0;
    }

    return lastChangedIndex - 1;
  }

  addClassTypeTotalFormulas(classDetailWS) {
    let session_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .sessionName;
    for (let u = 0; u < this.classTypeBoxRangesArray.length; u++) {
      let countClassRange =
        "E" +
        this.classTypeBoxRangesArray[u].start +
        ":E" +
        this.classTypeBoxRangesArray[u].end;
      let payClassRange =
        "F" +
        this.classTypeBoxRangesArray[u].start +
        ":F" +
        this.classTypeBoxRangesArray[u].end;
      let countClassTotalCell = "E" + (this.classTypeBoxRangesArray[u].end + 1);
      let payClassTotalCell = "F" + (this.classTypeBoxRangesArray[u].end + 1);
      //=SUMIF(E9:E11,">5") - (COUNTIF(E9:E11, ">5")*5)
      //let bonusAttendeeCount = this.reportCompilerState.inputFilesArrays.questions.useAttendeeCountClassTotals.value;
      // if(bonusAttendeeCount !== -1){
      //   let attendeesOverBonus = 'SUMIF(' + countClassRange + ', ">' + bonusAttendeeCount + '")';
      //   let classesOver = 'COUNTIF(' + countClassRange + ', ">' + bonusAttendeeCount + '")';
      //   let nonBonusAttendees = '(' + classesOver + '*' + bonusAttendeeCount + ')'
      //   classDetailWS[countClassTotalCell] = { t: "n", f: attendeesOverBonus + ' - ' + nonBonusAttendees};
      // }else{
      classDetailWS[countClassTotalCell] = {
        t: "n",
        f: "COUNTIF(" + countClassRange + ', "<>' + session_name + ' Size")',
      };
      //}
      classDetailWS[payClassTotalCell] = {
        t: "n",
        f: "SUM(" + payClassRange + ")",
        z: "$##,##0.00",
      };
    }
  }

  addClassTotalFormulas(classDetailWS) {
    for (let x = 0; x < this.detailInstructorRefBoxList.length; x++) {
      if (this.detailInstructorRefBoxList[x].type === "Class") {
        let typeRange =
          "A" +
          this.detailInstructorRefBoxList[x].startingRef +
          ":A" +
          this.detailInstructorRefBoxList[x].endingRef;
        let countRange =
          "E" +
          this.detailInstructorRefBoxList[x].startingRef +
          ":E" +
          this.detailInstructorRefBoxList[x].endingRef;
        let payRange =
          "F" +
          this.detailInstructorRefBoxList[x].startingRef +
          ":F" +
          this.detailInstructorRefBoxList[x].endingRef;
        let countTotalCell =
          "E" + (this.detailInstructorRefBoxList[x].endingRef + 2);
        let payTotalCell =
          "F" + (this.detailInstructorRefBoxList[x].endingRef + 2);
        let countSumFormula =
          "SUMIF(" + typeRange + ',"*Totals*",' + countRange + ")";
        let paySumFormula =
          "SUMIF(" + typeRange + ',"*Totals*",' + payRange + ")";
        classDetailWS[countTotalCell] = { t: "n", f: countSumFormula };
        classDetailWS[payTotalCell] = {
          t: "n",
          f: paySumFormula,
          z: "$##,##0.00",
        };
      }
    }
  }

  /**** CREATE COMMISSION DETAIL ****/
  addCommissionDetailToExcelWorkbook(wb) {
    let commissionDetailArray = [];
    let commission_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .commissionName + " Detail";
    if (this.reportCompilerState.inputFilesArrays.AGREE != null) {
      commissionDetailArray = this.createCommissionDetail();
    }
    wb.SheetNames.push(commission_name);
    const commissionDetailWS = XLSX.utils.aoa_to_sheet(commissionDetailArray);
    if(this.reportCompilerState.inputFilesArrays.questions.salesBuckets && this.reportCompilerState.inputFilesArrays.questions.salesBuckets.length > 0){
      commissionDetailWS['!autofilter'] = { ref: "A5:S5" };
      
      // Assuming the worksheet extends down to a reasonable row count
    const range = XLSX.utils.decode_range(commissionDetailWS['!ref']); // Decode range of the worksheet
    for (let R = range.s.r + 5; R <= range.e.r; R++) { // Start from row 6 (0-indexed + 5 for header row)
        const addressT = XLSX.utils.encode_cell({r: R, c: 19}); // Column T is 19th (0-indexed)
        const cellT = commissionDetailWS[addressT];

        if (cellT && cellT.v !== undefined) { // Check if cell exists and has a value
            const addressS = XLSX.utils.encode_cell({r: R, c: 18}); // Column S is 18th (0-indexed)
            const cellS = commissionDetailWS[addressS] || (commissionDetailWS[addressS] = { t: "s" }); // Ensure cell exists

            // Prepare to add comment
            if (!cellS.c) cellS.c = []; // Ensure there is a comment array
            cellS.c.hidden = true;
            const comment_part = {
                a: "PayWell", // Author of the comment
                t: cellT.v, // Text from column T
            };

            // Add comment part to the comment array
            cellS.c.push(comment_part);

            // Delete content in column T
            delete commissionDetailWS[addressT];
        }
      }
      //this.addNewSalesItems(commissionDetail);
    }
    wb.Sheets[commission_name] = commissionDetailWS;
  }

  createCommissionDetail() {
    const commissionDetail = [];

    this.addDetailHeader(commissionDetail);

    if(this.reportCompilerState.inputFilesArrays.questions.salesBuckets && this.reportCompilerState.inputFilesArrays.questions.salesBuckets.length > 0){
      this.addNewSalesItems(commissionDetail);
    }else {
      this.addPostIntroAgreements(commissionDetail);
      this.addNonIntroAgreements(commissionDetail);
      this.addUpgradeAgreements(commissionDetail);
      this.addRetail(commissionDetail);
    }

    return commissionDetail;
  }

  addNewSalesItems(commissionDetail) {
    commissionDetail.push([
      "Location", 
      "Date", 
      "Sold To", 
      "Payment",
      "Status", 
      "Sales Type",
      "Transaction Type", 
      "Description", 
      "Previous Sale", 
      "# of Previous Sales", 
      "Closed Salesperson", 
      "Closed Salesperson Pay", 
      "Secondary Sales Person", 
      "Secondary Sales Person Pay", 
      "Opened Salesperson", 
      "Opened Salesperson Pay", 
      "Instructor", 
      "Instructor Pay",
      "Comments",
    ]);
    const commissionArray =
      this.reportCompilerState.studiosInformation.commissions.sort(function (
        a,
        b
      ) {
        if (a.dateSold.getTime() < b.dateSold.getTime()) {
          return -1;
        }
        if (a.dateSold.getTime() > b.dateSold.getTime()) {
          return 1;
        }

        // Compare by location
        if (a.location < b.location) {
          return -1;
        }
        if (a.location > b.location) {
          return 1;
        }

        // If the locations are the same, compare by description
        if (a.customer < b.customer) {
          return -1;
        }
        if (a.customer > b.customer) {
          return 1;
        }

        // If both location and description are the same, return 0
        return 0;
      });

    for (let i = 0; i < commissionArray.length; i++) {
      let previous = "|" + commissionArray[i].previousPurchases.previousAgreementDate + "| " + commissionArray[i].previousPurchases.previousAgreements[0];
      if(commissionArray[i].previousPurchases.previousAmount === 0){
        previous = "-";
      }
      let rateName = commissionArray[i].type.bucketRate;
      let bucket = this.getCommissionBucket(commissionArray[i].type.bucketName);
      if(bucket?.rateNameOverrides && bucket?.rateNameOverrides[rateName]){
        rateName = bucket.rateNameOverrides[rateName];
      }
      commissionDetail.push([
        commissionArray[i].location,
        commissionArray[i].dateSold.toDateString(),
        commissionArray[i].customer,
        commissionArray[i].payment,
        rateName,
        commissionArray[i].type.bucketName,
        commissionArray[i].type.bucketType,
        commissionArray[i].description,
        previous,
        commissionArray[i].previousPurchases.previousAmount,
        commissionArray[i].salespeople.close,
        commissionArray[i].salespay.close,
        commissionArray[i].salespeople.secondary ?? "-",
        commissionArray[i].salespay.secondary,
        commissionArray[i].salespeople.open ?? "-",
        commissionArray[i].salespay.open,
        commissionArray[i].salespeople.instructor ?? "-",
        commissionArray[i].salespay.instructor,
        commissionArray[i].commentsToString(),
        commissionArray[i].type.extendedType
      ]);
      //if (true|| this.salespersonTypeIsIncluded("Post-Intro Agreement", "Open")) {
        this.addCommissionReferenceBox(
          commissionArray[i].type.bucketName + " " + commissionArray[i].type.statusType,
          commissionArray[i].salespeople.open,
          "Opened",
          commissionDetail.length,
          commissionArray[i].salespay.open
        );
      //}

      //if (true || this.salespersonTypeIsIncluded("Post-Intro Agreement", "Close")) {
        this.addCommissionReferenceBox(
          commissionArray[i].type.bucketName + " " + commissionArray[i].type.statusType,
          commissionArray[i].salespeople.close,
          "Closed",
          commissionDetail.length,
          commissionArray[i].salespay.close
        );
      //}
      // if (
      //   true || 
      //   this.salespersonTypeIsIncluded(
      //     "Post-Intro Agreement",
      //     "Instructor"
      //   ) ||
      //   parseInt(
      //     this.reportCompilerState.inputFilesArrays.questions
      //       .sessionSignupBonus.value
      //   ) > 0
      // ) {
        this.addCommissionReferenceBox(
          commissionArray[i].type.bucketName + " " + commissionArray[i].type.statusType,
          commissionArray[i].salespeople.instructor,
          "Instructor",
          commissionDetail.length,
          commissionArray[i].salespay.instructor
        );
      //}

      //if (true || this.salespersonTypeIsIncluded("Post-Intro Agreement", "Second")) {
        this.addCommissionReferenceBox(
          commissionArray[i].type.bucketName + " " + commissionArray[i].type.statusType,
          commissionArray[i].salespeople.secondary,
          "Secondary",
          commissionDetail.length,
          commissionArray[i].salespay.secondary
        );
      //}
      this.addCommissionCurrencyLocationArray(
        [3, 11, 13, 15, 17],
        commissionDetail.length
      );

      this.addSalesCellReference(
        commissionArray[i].type.bucketName,
        commissionArray[i].salespeople.open,
        commissionArray[i].location,
        commissionDetail.length,
        15
      )

      this.addSalesCellReference(
        commissionArray[i].type.bucketName,
        commissionArray[i].salespeople.close,
        commissionArray[i].location,
        commissionDetail.length,
        11
      );

      this.addSalesCellReference(
        commissionArray[i].type.bucketName,
        commissionArray[i].salespeople.instructor,
        commissionArray[i].location,
        commissionDetail.length,
        17
      );

      this.addSalesCellReference(
        commissionArray[i].type.bucketName,
        commissionArray[i].salespeople.secondary,
        commissionArray[i].location,
        commissionDetail.length,
        13
      );

      // this.addAgreementCellReference(
      //   commissionArray[i].salespeople.open,
      //   commissionArray[i].location,
      //   commissionDetail.length,
      //   14
      // );
      // this.addAgreementCellReference(
      //   commissionArray[i].salespeople.close,
      //   commissionArray[i].location,
      //   commissionDetail.length,
      //   10
      // );
      // this.addAgreementCellReference(
      //   commissionArray[i].salespeople.instructor,
      //   commissionArray[i].location,
      //   commissionDetail.length,
      //   16
      // );
      // this.addAgreementCellReference(
      //   commissionArray[i].salespeople.secondary,
      //   commissionArray[i].location,
      //   commissionDetail.length,
      //   12
      // );
    }
  }

  getCommissionBucket(name){
    let commissionObjects = this.reportCompilerState.inputFilesArrays.questions.salesBuckets;
    for(let i = 0; i < commissionObjects.length; i++){
      if(commissionObjects[i].name === name){
        return commissionObjects[i];
      }
    }
    return null;
  }

  addPostIntroAgreements(commissionDetail) {
    const commissionArray =
      this.reportCompilerState.studiosInformation.commissions.sort(function (
        a,
        b
      ) {
        if (a.dateSold.getTime() < b.dateSold.getTime()) {
          return -1;
        }
        if (a.dateSold.getTime() > b.dateSold.getTime()) {
          return 1;
        }

        // Compare by location
        if (a.location < b.location) {
          return -1;
        }
        if (a.location > b.location) {
          return 1;
        }

        // If the locations are the same, compare by description
        if (a.customer < b.customer) {
          return -1;
        }
        if (a.customer > b.customer) {
          return 1;
        }

        // If both location and description are the same, return 0
        return 0;
      });
    let introComCount = 0;

    for (let i = 0; i < commissionArray.length; i++) {
      if (commissionArray[i].type === "Post-Intro Agreement") {
        if (introComCount === 0) {
          this.addIntroHeader(commissionDetail);
        }
        commissionDetail.push([
          commissionArray[i].location,
          commissionArray[i].dateSold.toDateString(),
          commissionArray[i].customer,
          commissionArray[i].payment,
          commissionArray[i].description,
          commissionArray[i].salespeople.open,
          commissionArray[i].salespay.open,
          commissionArray[i].salespeople.close,
          commissionArray[i].salespay.close,
          commissionArray[i].salespeople.instructor,
          commissionArray[i].salespay.instructor,
          commissionArray[i].salespeople.secondary,
          commissionArray[i].salespay.secondary,
          commissionArray[i].commentsToString(),
        ]);
        //agreementsTable[k].addedToExcel = true;

        if (this.salespersonTypeIsIncluded("Post-Intro Agreement", "Open")) {
          this.addCommissionReferenceBox(
            "Intro",
            commissionArray[i].salespeople.open,
            "Opened",
            commissionDetail.length,
            commissionArray[i].salespay.open
          );
        }

        if (this.salespersonTypeIsIncluded("Post-Intro Agreement", "Close")) {
          this.addCommissionReferenceBox(
            "Intro",
            commissionArray[i].salespeople.close,
            "Closed",
            commissionDetail.length,
            commissionArray[i].salespay.close
          );
        }
        if (
          this.salespersonTypeIsIncluded(
            "Post-Intro Agreement",
            "Instructor"
          ) ||
          parseInt(
            this.reportCompilerState.inputFilesArrays.questions
              .sessionSignupBonus.value
          ) > 0
        ) {
          this.addCommissionReferenceBox(
            "Intro",
            commissionArray[i].salespeople.instructor,
            "Instructor",
            commissionDetail.length,
            commissionArray[i].salespay.instructor
          );
        }

        if (this.salespersonTypeIsIncluded("Post-Intro Agreement", "Second")) {
          this.addCommissionReferenceBox(
            "Intro",
            commissionArray[i].salespeople.secondary,
            "Secondary",
            commissionDetail.length,
            commissionArray[i].salespay.secondary
          );
        }
        this.addCommissionCurrencyLocationArray(
          [3, 6, 8, 10, 12],
          commissionDetail.length
        );

        this.addAgreementCellReference(
          commissionArray[i].salespeople.open,
          commissionArray[i].location,
          commissionDetail.length,
          6
        );
        this.addAgreementCellReference(
          commissionArray[i].salespeople.close,
          commissionArray[i].location,
          commissionDetail.length,
          8
        );
        this.addAgreementCellReference(
          commissionArray[i].salespeople.instructor,
          commissionArray[i].location,
          commissionDetail.length,
          10
        );
        this.addAgreementCellReference(
          commissionArray[i].salespeople.secondary,
          commissionArray[i].location,
          commissionDetail.length,
          12
        );

        introComCount++;
      }
    }
  }

  salespersonTypeIsIncluded(type, salesperson) {
    let commissionBuckets =
      this.reportCompilerState.inputFilesArrays.questions.commissionPayObjects;
    for (let i = 0; i < commissionBuckets.length; i++) {
      let bucket = commissionBuckets[i];
      if (bucket.type === type) {
        return bucket.salesPeoplePay[salesperson];
      }
    }
    return true;
  }

  addIntroHeader(commissionDetail) {
    let specialist_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .specialistName;
    let member_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .memberName;
    let location_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .locationName;
    commissionDetail.push(["Intros - New Agreements"]);
    commissionDetail.push(this.blankExcelRow);
    commissionDetail.push([
      location_name,
      "Date",
      "New " + member_name,
      "Payment",
      "Agreement Type",
      "Opened Booking",
      "Opened Pay",
      "Closed Booking",
      "Closed Pay",
      specialist_name,
      specialist_name + " Pay",
      "Secondary Sales Person",
      "Secondary Sales Person Pay",
      "Comments",
    ]);
  }

  addNonIntroAgreements(commissionDetail) {
    const commissionArray =
      this.reportCompilerState.studiosInformation.commissions.sort(function (
        a,
        b
      ) {
        if (a.dateSold.getTime() < b.dateSold.getTime()) {
          return -1;
        }
        if (a.dateSold.getTime() > b.dateSold.getTime()) {
          return 1;
        }

        // Compare by location
        if (a.location < b.location) {
          return -1;
        }
        if (a.location > b.location) {
          return 1;
        }

        // If the locations are the same, compare by description
        if (a.customer < b.customer) {
          return -1;
        }
        if (a.customer > b.customer) {
          return 1;
        }

        // If both location and description are the same, return 0
        return 0;
      });
    let nonIntroComCount = 0;

    for (let i = 0; i < commissionArray.length; i++) {
      if (commissionArray[i].type === "Non-Intro Agreement") {
        if (nonIntroComCount === 0) {
          this.addNonIntroHeader(commissionDetail);
        }
        commissionDetail.push([
          commissionArray[i].location,
          commissionArray[i].dateSold.toDateString(),
          commissionArray[i].customer,
          commissionArray[i].payment,
          commissionArray[i].description,
          commissionArray[i].salespeople.close,
          commissionArray[i].salespay.close,
          commissionArray[i].salespeople.secondary,
          commissionArray[i].salespay.secondary,
          commissionArray[i].commentsToString(),
        ]);
        //agreementsTable[k].addedToExcel = true;

        if (this.salespersonTypeIsIncluded("Non-Intro Agreement", "Close")) {
          this.addCommissionReferenceBox(
            "Non-Intro",
            commissionArray[i].salespeople.close,
            "Closed",
            commissionDetail.length,
            commissionArray[i].salespay.close
          );
        }
        if (this.salespersonTypeIsIncluded("Non-Intro Agreement", "Second")) {
          this.addCommissionReferenceBox(
            "Non-Intro",
            commissionArray[i].salespeople.secondary,
            "Secondary",
            commissionDetail.length,
            commissionArray[i].salespay.secondary
          );
        }
        this.addCommissionCurrencyLocationArray(
          [3, 6, 8],
          commissionDetail.length
        );

        this.addAgreementCellReference(
          commissionArray[i].salespeople.close,
          commissionArray[i].location,
          commissionDetail.length,
          6
        );
        this.addAgreementCellReference(
          commissionArray[i].salespeople.secondary,
          commissionArray[i].location,
          commissionDetail.length,
          8
        );

        nonIntroComCount++;
      }
    }
  }

  addNonIntroHeader(commissionDetail) {
    let member_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .memberName;
    let location_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .locationName;
    commissionDetail.push(this.blankExcelRow);
    commissionDetail.push(this.blankExcelRow);
    commissionDetail.push(["Non-Intros - New Agreements"]);
    commissionDetail.push(this.blankExcelRow);
    commissionDetail.push([
      location_name,
      "Date",
      "New " + member_name,
      "Payment",
      "Agreement Type",
      "Closed Booking",
      "Closed Pay",
      "Secondary Sales Person",
      "Secondary Sales Person Pay",
      "Comments",
    ]);
  }

  addUpgradeAgreements(commissionDetail) {
    const commissionArray =
      this.reportCompilerState.studiosInformation.commissions.sort(function (
        a,
        b
      ) {
        if (a.dateSold.getTime() < b.dateSold.getTime()) {
          return -1;
        }
        if (a.dateSold.getTime() > b.dateSold.getTime()) {
          return 1;
        }

        // Compare by location
        if (a.location < b.location) {
          return -1;
        }
        if (a.location > b.location) {
          return 1;
        }

        // If the locations are the same, compare by description
        if (a.customer < b.customer) {
          return -1;
        }
        if (a.customer > b.customer) {
          return 1;
        }

        // If both location and description are the same, return 0
        return 0;
      });
    let upgradeComCount = 0;

    for (let i = 0; i < commissionArray.length; i++) {
      if (commissionArray[i].type === "Upgrade/Downgrade") {
        if (upgradeComCount === 0) {
          this.addUpgradesHeader(commissionDetail);
        }
        commissionDetail.push([
          commissionArray[i].location,
          commissionArray[i].dateSold.toDateString(),
          commissionArray[i].customer,
          commissionArray[i].payment,
          commissionArray[i].description,
          commissionArray[i].previousPurchases.previousPurchase,
          commissionArray[i].previousPurchases.type,
          commissionArray[i].salespeople.close,
          commissionArray[i].salespay.close,
          commissionArray[i].salespeople.secondary,
          commissionArray[i].salespay.secondary,
          commissionArray[i].commentsToString(),
        ]);

        //agreementsTable[k].addedToExcel = true;

        if (this.salespersonTypeIsIncluded("Upgrade/Downgrade", "Close")) {
          this.addCommissionReferenceBox(
            "Upgrade-Downgrade",
            commissionArray[i].salespeople.close,
            "Closed",
            commissionDetail.length,
            commissionArray[i].salespay.close
          );
        }

        if (this.salespersonTypeIsIncluded("Upgrade/Downgrade", "Second")) {
          this.addCommissionReferenceBox(
            "Upgrade-Downgrade",
            commissionArray[i].salespeople.secondary,
            "Secondary",
            commissionDetail.length,
            commissionArray[i].salespay.secondary
          );
        }
        this.addCommissionCurrencyLocationArray(
          [3, 8, 10],
          commissionDetail.length
        );

        this.addAgreementCellReference(
          commissionArray[i].salespeople.close,
          commissionArray[i].location,
          commissionDetail.length,
          8
        );
        this.addAgreementCellReference(
          commissionArray[i].salespeople.secondary,
          commissionArray[i].location,
          commissionDetail.length,
          10
        );

        upgradeComCount++;
      }
    }
  }

  addUpgradesHeader(commissionDetail) {
    let member_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .memberName;
    let location_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .locationName;
    commissionDetail.push(this.blankExcelRow);
    commissionDetail.push(this.blankExcelRow);
    commissionDetail.push(["Upgrade/Downgrade"]);
    commissionDetail.push(this.blankExcelRow);
    commissionDetail.push([
      location_name,
      "Date",
      member_name,
      "Payment",
      "New Agreement",
      "Previous Agreement",
      "Upgrade/Downgrade",
      "Closed Booking",
      "Closed Pay",
      "Secondary Sales Person",
      "Secondary Sales Person Pay",
      "Comments",
    ]);
  }

  addRetail(commissionDetail) {
    const commissionArray =
      this.reportCompilerState.studiosInformation.commissions.sort(function (
        a,
        b
      ) {
        if (a.dateSold.getTime() < b.dateSold.getTime()) {
          return -1;
        }
        if (a.dateSold.getTime() > b.dateSold.getTime()) {
          return 1;
        }

        // Compare by location
        if (a.location < b.location) {
          return -1;
        }
        if (a.location > b.location) {
          return 1;
        }

        // If the locations are the same, compare by description
        if (a.customer < b.customer) {
          return -1;
        }
        if (a.customer > b.customer) {
          return 1;
        }

        // If both location and description are the same, return 0
        return 0;
      });
    let retailComCount = 0;

    for (let i = 0; i < commissionArray.length; i++) {
      if (commissionArray[i].type === "Retail") {
        if (retailComCount === 0) {
          this.addRetailHeader(commissionDetail);
        }
        commissionDetail.push([
          commissionArray[i].location,
          commissionArray[i].dateSold.toDateString(),
          commissionArray[i].customer,
          commissionArray[i].payment,
          commissionArray[i].description,
          commissionArray[i].salespeople.close,
          commissionArray[i].salespay.close,
          commissionArray[i].salespeople.secondary,
          commissionArray[i].salespay.secondary,
          commissionArray[i].commentsToString(),
        ]);
        //agreementsTable[k].addedToExcel = true;

        this.addCommissionReferenceBox(
          "Retail",
          commissionArray[i].salespeople.close,
          "Closed",
          commissionDetail.length,
          commissionArray[i].salespay.close
        );

        this.addCommissionReferenceBox(
          "Retail",
          commissionArray[i].salespeople.secondary,
          "Secondary",
          commissionDetail.length,
          commissionArray[i].salespay.secondary
        );

        this.addCommissionCurrencyLocationArray(
          [3, 6, 8],
          commissionDetail.length
        );

        if (commissionArray[i].description.includes("Enrollment")) {
          this.addAgreementCellReference(
            commissionArray[i].salespeople.close,
            commissionArray[i].location,
            commissionDetail.length,
            6
          );
          this.addAgreementCellReference(
            commissionArray[i].salespeople.secondary,
            commissionArray[i].location,
            commissionDetail.length,
            8
          );
        } else {
          this.addRetailCellReference(
            commissionArray[i].salespeople.close,
            commissionArray[i].location,
            commissionDetail.length,
            6
          );
          this.addRetailCellReference(
            commissionArray[i].salespeople.secondary,
            commissionArray[i].location,
            commissionDetail.length,
            8
          );
        }

        retailComCount++;
      }
    }
  }

  addRetailHeader(commissionDetail) {
    let location_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .locationName;
    commissionDetail.push(this.blankExcelRow);
    commissionDetail.push(this.blankExcelRow);
    commissionDetail.push(["Retail Sales"]);
    commissionDetail.push(this.blankExcelRow);
    commissionDetail.push([
      location_name,
      "Date",
      "Customer",
      "Payment",
      "Item",
      "Primary Sales Person",
      "Primary Sales Person Pay",
      "Secondary Sales Person",
      "Secondary Sales Person Pay",
      "Comments",
    ]);
  }

  addCommissionReferenceBox(type, staff, role, row, payAmount) {
    if (
      !this.reportCompilerState.inputFilesArrays.questions
        .includeOnlyCommissionableItems.value ||
      payAmount > 0
    ) {
      this.commissionDetailRefBoxList.push(
        new CommissionDetailReferenceBox(type, staff, role, row)
      );
    }
  }

  addSalesCellReference(type, staff, location, row, column) {
    this.cellRefByInstructor.push(
      new CellReference(type, staff, location, row, column)
    );
  }

  addAgreementCellReference(staff, location, row, column) {
    this.cellRefByInstructor.push(
      new CellReference("Agree", staff, location, row, column)
    );
  }

  addRetailCellReference(staff, location, row, column) {
    this.cellRefByInstructor.push(
      new CellReference("Retail", staff, location, row, column)
    );
  }

  // Adds to Commission Tab
  addCommissionCurrencyLocationArray(locations, row) {
    for (let i = 0; i < locations.length; i++) {
      this.currencyLocations.push([2, locations[i], row]);
    }
  }

  /**** CREATE PAYROLL PROCESSOR ****/
  addProcessorTabs(wb, sheetname) {
    let processorTable = [];
    let header = this.reportCompilerState.inputFilesArrays.questions.processorConfig.header;
    let staffNameOrder = this.createProcessorTemplate(processorTable, null);
    const stringProcessorTable = processorTable.map(row => row.map(String));
    const processorWS = XLSX.utils.aoa_to_sheet(stringProcessorTable);

    this.nonFormulaSheetCopy = XLSX.utils.aoa_to_sheet(stringProcessorTable);

    this.addProcessorReferences(wb, processorWS, staffNameOrder, null);

    this.cleanTab(processorWS);

    if(header && header.length > 0 && header !== ""){
      this.addHeaderRow(processorWS, header);
    }
    wb.Sheets[sheetname] = processorWS;

    if (
      this.reportCompilerState.inputFilesArrays.questions.processorConfig
        .locationSheetBreakout
    ) {
      //get locations ran
      let possibleLocations =
        this.reportCompilerState.payrollInformation.studiosInInput
          .sort()
          .reverse();

      for (let i = 0; i < possibleLocations.length; i++) {
        let processorTableInd = [];
        let staffNameOrderInd = this.createProcessorTemplate(
          processorTableInd,
          possibleLocations[i]
        );
        const stringProcessorTableInd = processorTableInd.map(row => row.map(String));
        const processorWSInd = XLSX.utils.aoa_to_sheet(stringProcessorTableInd);

        this.nonFormulaSheetCopy = XLSX.utils.aoa_to_sheet(stringProcessorTableInd);

        this.addProcessorReferences(
          wb,
          processorWSInd,
          staffNameOrderInd,
          possibleLocations[i]
        );

        this.cleanTab(processorWSInd);
        if(header){
          this.addHeaderRow(processorWSInd, header);
        }
        let sheetnameInd =
          sheetname + "-" + possibleLocations[i].replace("Club Pilates", "");
        if (sheetnameInd.length > 30) {
          sheetnameInd = sheetnameInd.substring(0, 30);
        }
        wb.Sheets[sheetnameInd] = processorWSInd;
        for (let s = 0; s < wb.SheetNames.length; s++) {
          if (sheetnameInd.includes(wb.SheetNames[s])) {
            // Insert new sheet name after the current sheet name
            wb.SheetNames.splice(s + 1, 0, sheetnameInd);

            // Optional: Break if you only want to insert after the first occurrence
            break;
          }
        }
        // wb.SheetNames.push(sheetnameInd);
      }
    }

    if (
      this.reportCompilerState.inputFilesArrays.questions.processorConfig
        .columnBreakoutName
    ) {
      let columnBreakoutName =
        this.reportCompilerState.inputFilesArrays.questions.processorConfig
          .columnBreakoutName;
      const distinctValues = this.getUniqueValues(
        processorWS,
        columnBreakoutName
      );

      this.createSheetsWithReferences(
        distinctValues,
        processorWS,
        columnBreakoutName,
        sheetname,
        wb
      );
    }
  }

  getUniqueValues(sheet, columnName) {
    const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 });
    const columnIndex = rows[0].indexOf(columnName);
    const uniqueValues = new Set();
    for (let i = 1; i < rows.length; i++) {
      uniqueValues.add(rows[i][columnIndex]);
    }
    return uniqueValues;
  }

  createSheetsWithReferences(
    values,
    originalSheet,
    columnName,
    originalSheetName,
    workbook
  ) {
    let header = this.reportCompilerState.inputFilesArrays.questions.processorConfig.header;
    const valuesArray = Array.from(values);
    for (let v = 0; v < valuesArray.length; v++) {
      const value = valuesArray[v];
      const newSheet = XLSX.utils.aoa_to_sheet([]);
      const rows = XLSX.utils.sheet_to_json(originalSheet, { header: 1 });
      const columnIndex = rows[0].indexOf(columnName);
      let rowLength = rows[0].length;

      let newRow = 1; // Start from the second row for data (after header)
      // Copy the header row
      for (let j = 0; j < rows[0].length; j++) {
        const headerCellRef = XLSX.utils.encode_cell({ r: 0, c: j });
        newSheet[headerCellRef] = { v: rows[0][j] };
      }

      for (let i = 1; i < rows.length; i++) {
        if (rows[i][columnIndex] === value) {
          for (let j = 0; j < rowLength; j++) {
            const cellRef = XLSX.utils.encode_cell({ r: newRow, c: j });
            const originalCellRef = XLSX.utils.encode_cell({ r: i, c: j });
            newSheet[cellRef] = {
              f: `'${originalSheetName}'!${originalCellRef}`,
            };
          }
          newRow++;
        }
      }

      let valueSheetName = originalSheetName + "-" + valuesArray[v];
      if (valueSheetName.length > 30) {
        valueSheetName = valueSheetName.substring(0, 30);
      }
      newSheet["!ref"] = XLSX.utils.encode_range({
        s: { r: 0, c: 0 },
        e: { r: rows.length, c: rows[0].length },
      });
      if(header){
        this.addHeaderRow(newSheet, header);
      }
      workbook.Sheets[valueSheetName] = newSheet;
      for (let s = 0; s < workbook.SheetNames.length; s++) {
        if (valueSheetName.includes(workbook.SheetNames[s])) {
          // Insert new sheet name after the current sheet name
          workbook.SheetNames.splice(s + 1, 0, valueSheetName);

          // Optional: Break if you only want to insert after the first occurrence
          break;
        }
      }
    }
  }

  createProcessorTemplate(processorTable, locationOverride) {
    this.addColumnHeaders(processorTable);
    let staffNameOrder = this.createStaffRows(processorTable, locationOverride);
    return staffNameOrder;
  }

  addColumnHeaders(processorTable) {
    let processorSetup =
      this.reportCompilerState.inputFilesArrays.questions.processorConfig
        .columns;
    let header = [];
    for (let i = 0; i < processorSetup.length; i++) {
      header.push(processorSetup[i].name);
    }
    processorTable.push(header);
  }

  createStaffRows(processorTable, locationOverride) {
    let processorSetup =
      this.reportCompilerState.inputFilesArrays.questions.processorConfig
        .columns;
    let staffArray =
      this.reportCompilerState.studiosInformation.staffArray.sort(
        sortArrayLastName
      );
    let staffNameOrder = [null];
    for (let i = 0; i < staffArray.length; i++) {
      let payComponentArray = this.createPayComponentArray();
      // let duplicateAttributeArray = this.createDuplicateAttributeArray(
      //   staffArray[i]
      // );

      let locations = this.createDuplicateLocationsArray(staffArray[i]);
      if (locationOverride !== null) {
        locations = [locationOverride];
      }
      let titles = this.createDuplicateTitlesArray(staffArray[i]);

      for (let p = 0; p < payComponentArray.length; p++) {
        for (let k = 0; k < locations.length; k++) {
          for (let t = 0; t < titles.length; t++) {
            let staffRow = [];
            for (let z = 0; z < processorSetup.length; z++) {
              if (processorSetup[z].constantValue !== null) {
                staffRow.push(processorSetup[z].constantValue);
                continue;
              } else if (processorSetup[z].staffAttribute === null) {
                staffRow.push("");
              } else if (processorSetup[z].staffAttribute.name === "titles") {
                staffRow.push(titles[t]);
              } else if (processorSetup[z].staffAttribute.name === "location") {
                staffRow.push(locations[k]);
              } else if (
                processorSetup[z].staffAttribute.name === "*Pay Component*"
              ) {
                staffRow.push(payComponentArray[p]);
              } else {
                let loc = [locations[k]];
                if (loc[0] === "") {
                  loc =
                    this.reportCompilerState.payrollInformation.studiosInInput;
                }
                let staffValue = this.findStaffAttributeValue(
                  staffArray[i],
                  processorSetup[z].staffAttribute,
                  loc
                );
                staffRow.push(staffValue);
              }
            }
            staffNameOrder.push(staffArray[i].getPrimaryName());
            processorTable.push(staffRow);
          }
        }
      }
    }
    return staffNameOrder;
  }

  createPayComponentArray() {
    let components =
      this.reportCompilerState.inputFilesArrays.questions.processorConfig
        .payComponents;
    if (components.length === 0) {
      return [""];
    }
    return components;
  }

  createDuplicateLocationsArray(staffObj) {
    let duplicateAttributes = this.findDuplicatorAttributes();
    let usingLocationAttribute = false;
    for (let i = 0; i < duplicateAttributes.length; i++) {
      if (duplicateAttributes[i].name === "location") {
        usingLocationAttribute = true;
      }
    }
    if (!usingLocationAttribute) {
      //confirm name is correct
      return [""]; //no lcoation duplicator
    }

    let locationsRunning =
      this.reportCompilerState.payrollInformation.studiosInInput;
    return staffObj.getValidLocations(locationsRunning);
  }

  createDuplicateTitlesArray(staffObj) {
    let duplicateAttributes = this.findDuplicatorAttributes();
    let usingTitlesAttribute = false;
    for (let i = 0; i < duplicateAttributes.length; i++) {
      if (duplicateAttributes[i].name === "titles") {
        usingTitlesAttribute = true;
      }
    }
    if (!usingTitlesAttribute) {
      //confirm name is correct
      return [""]; //no lcoation duplicator
    }

    let locationsRunning =
      this.reportCompilerState.payrollInformation.studiosInInput;
    return staffObj.getValidTitles(locationsRunning, "Primary");
  }

  findDuplicatorAttributes() {
    let config =
      this.reportCompilerState.inputFilesArrays.questions.processorConfig;
    let processorSetup = config.columns;
    let duplicates = [];
    for (let i = 0; i < processorSetup.length; i++) {
      if (
        processorSetup[i].staffAttribute !== undefined &&
        processorSetup[i].staffAttribute !== null &&
        processorSetup[i].staffAttribute.duplicate
      ) {
        duplicates.push(processorSetup[i].staffAttribute);
        //return processorSetup[i].staffAttribute;
      }
    }
    return duplicates;
  }

  createDuplicateAttributeArray(staffObj) {
    let duplicatorStaffAttribute = this.findDuplicatorAttribute();
    if (duplicatorStaffAttribute === null) {
      return [""]; //no duplicator
    }

    let locationsRunning =
      this.reportCompilerState.payrollInformation.studiosInInput;
    let duplicateAttributeArray = this.findStaffAttributeValue(
      staffObj,
      duplicatorStaffAttribute,
      locationsRunning
    );
    if (duplicateAttributeArray.length === 0) {
      duplicateAttributeArray = [duplicatorStaffAttribute.primaryString];
    }

    return duplicateAttributeArray.sort();
  }

  findDuplicatorAttribute() {
    let config =
      this.reportCompilerState.inputFilesArrays.questions.processorConfig;
    let processorSetup = config.columns;
    for (let i = 0; i < processorSetup.length; i++) {
      if (
        processorSetup[i].staffAttribute !== undefined &&
        processorSetup[i].staffAttribute !== null &&
        processorSetup[i].staffAttribute.duplicate
      ) {
        return processorSetup[i].staffAttribute;
      }
    }
    return null;
  }

  findStaffAttributeValue(staffObj, staffAttribute, locationsRunning) {
    //Pay Component Attribute
    if (staffAttribute.name === "*Pay Component*") {
      //staffAttribute.duplicate = true;
      return this.reportCompilerState.inputFilesArrays.questions.processorConfig
        .payComponents;
    }
    if(staffAttribute.name === "Pay Period Start Date"){
      return this.formatDate(this.reportCompilerState.payrollInformation.belPayPeriods[0]);
    }
    if(staffAttribute.name === "Pay Period End Date"){
      return this.formatDate(this.reportCompilerState.payrollInformation.belPayPeriods[1]);
    }
    if (staffAttribute.method) {
      return staffObj[staffAttribute.name]();
    }
    return staffObj.getAttributesFromLocations(
      staffAttribute.name,
      locationsRunning
    );
  }

  formatDate(date) {
    let day = date.getDate();
    let month = date.getMonth() + 1; // getMonth() returns 0-11
    let year = date.getFullYear();

    // Pad the month and day with leading zeros if necessary
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;

    return `${month}/${day}/${year}`;
}

  addProcessorReferences(wb, processorWS, staffNameOrder, locationOverride) {
    //if(processorSetup.paywellTab === "Staff"){
    if (processorWS["!ref"] === undefined) {
      return;
    }
    let count = 0;

    let range = XLSX.utils.decode_range(processorWS["!ref"]);
    for (let row = range.s.r; row <= range.e.r; row++) {
      this.findProcessorRowReferences(
        wb,
        processorWS,
        row,
        staffNameOrder[count],
        locationOverride
      );
      count++;
    }
    //}
  }

  findProcessorRowReferences(
    wb,
    processorWS,
    row,
    staffName,
    locationOverride
  ) {
    let processorSetup =
      this.reportCompilerState.inputFilesArrays.questions.processorConfig
        .columns;

    if (locationOverride !== null) {
      let locations =
        this.reportCompilerState.payrollInformation.studiosInInput;
      for (let i = 0; i < locations.length; i++) {
        processorSetup.map((column, index) => {
          for (let j = 0; j < column.excelAttributes.length; j++) {
            let primaryValues = column.excelAttributes[j].primaryValues;
            let newPrimaryValues = primaryValues.map((item) => {
              if (item === locations[i] || item === "*All Studios*") {
                return locationOverride;
              }
              return item;
            });
            processorSetup[index].excelAttributes[j].primaryValues =
              newPrimaryValues;

            let secondaryValues = column.excelAttributes[j].secondaryValues;
            let newSecondaryValues = secondaryValues.map((item) => {
              if (item === locations[i] || item === "*All Studios*") {
                return locationOverride;
              }
              return item;
            });
            processorSetup[index].excelAttributes[j].secondaryValues =
              newSecondaryValues;
          }
        });
      }
    }

    let staffWS = this.findExcelTab(wb, staffName);
    if (staffWS === undefined) {
      return;
    }

    for (let i = 0; i < processorSetup.length; i++) {
      if (
        processorSetup[i].excelAttributes === undefined ||
        processorSetup[i].excelAttributes.length === 0
      ) {
        continue;
      }
      let excelAttributes = processorSetup[i].excelAttributes;
      for (let b = 0; b < excelAttributes.length; b++) {
        let excelAttribute = excelAttributes[b];
        if (
          excelAttribute.rowAttribute !== null &&
          excelAttribute.rowAttribute !== undefined
        ) {
          let rowAttribute = excelAttribute.rowAttribute;
          let columnIndex = this.findColumnIndex(rowAttribute.columnName);
          if (columnIndex !== -1) {
            let rowAddress = XLSX.utils.encode_cell({ r: row, c: columnIndex });
            let rowValue = processorWS[rowAddress].v;
            // if(!rowValue.includes(rowAttribute.columnValue)){
            //     continue;
            // }
            if (
              !this.checkValueEquivalence(rowValue, rowAttribute.columnValue)
            ) {
              continue;
            }
          }
          if (
            excelAttribute.secondRowAttribute !== undefined &&
            excelAttribute.secondRowAttribute.columnName !== undefined &&
            excelAttribute.secondRowAttribute.columnName !== "None"
          ) {
            let secondRowAttribute = excelAttribute.secondRowAttribute;
            let columnIndex = this.findColumnIndex(
              secondRowAttribute.columnName
            );
            if (columnIndex !== -1) {
              let rowAddress = XLSX.utils.encode_cell({
                r: row,
                c: columnIndex,
              });
              let rowValue = processorWS[rowAddress].v;
              // if(!rowValue.includes(secondRowAttribute.columnValue)){
              //     continue;
              // }
              if (
                !this.checkValueEquivalence(
                  rowValue,
                  secondRowAttribute.columnValue
                )
              ) {
                continue;
              }
            }
          }
        }
        if (this.isSpecialValue(excelAttribute.valueColumn)) {
          let specialFormula = this.getSpecialFormula(
            wb,
            staffWS,
            excelAttribute,
            row,
            staffName
          );
          if (specialFormula === null) {
            continue;
          }
          if (specialFormula.formula) {
            processorWS[XLSX.utils.encode_cell({ r: row, c: i })] = {
              t: "n",
              f: specialFormula.output,
            };
          } else {
            processorWS[XLSX.utils.encode_cell({ r: row, c: i })] = {
              t: "s",
              v: specialFormula.output,
            };
          }
          continue;
        }
        let cellReferences = this.findCellReference(
          wb,
          staffWS,
          excelAttribute,
          row
        );
        if (cellReferences.length > 0) {
          let formula = this.createCellReferenceFormula(
            cellReferences,
            staffName
          );
          processorWS[XLSX.utils.encode_cell({ r: row, c: i })] = {
            t: "n",
            f: formula,
          };
        }
      }
    }
  }

  checkValueEquivalence(excelValue, mappingValue) {
    if (excelValue === undefined || mappingValue === undefined) {
      return false;
    }
    let not = false;
    if (mappingValue.charAt(0) === "!") {
      not = true;
      mappingValue = mappingValue.substring(1);
    }
    if (
      mappingValue.charAt(0) === "<" &&
      mappingValue.charAt(mappingValue.length - 1) === ">"
    ) {
      let trueValue = mappingValue.substring(1, mappingValue.length - 1);
      if (not) {
        return trueValue !== excelValue;
      }
      return trueValue === excelValue;
    } else {
      if (not) {
        return !excelValue.includes(mappingValue);
      }
      return excelValue.includes(mappingValue);
    }
  }

  isSpecialValue(value) {
    if (!isNaN(value)) {
      return false;
    }
    const nonSpecialValues = specialProcessorValues.filter(
      (val) => val.length === 1
    );

    for (let j = 0; j < nonSpecialValues.length; j++) {
      if (nonSpecialValues[j].includes(value)) {
        return false;
      }
    }
    return true;
  }

  valueIncluded(inputs, value) {
    for (let i = 0; i < inputs.length; i++) {
      if (this.checkValueEquivalence(value, inputs[i])) {
        return true;
      }
    }
    return false;
  }

  getSpecialFormula(wb, staffWS, excelAttribute, row, staffName) {
    let staffObj = this.findStaffObject(staffName, this.reportCompilerState);

    let value = excelAttribute.valueColumn;

    let primaryLookupColumn = excelAttribute.primaryLookupColumn;
    let primaryValues = excelAttribute.primaryValues;

    let secondaryLookupColumn = excelAttribute.secondaryLookupColumn;
    let secondaryValues = excelAttribute.secondaryValues;

    let truePrimaryValues = this.lookupProcessorCellValues(primaryValues, row);
    let trueSecondaryValues = this.lookupProcessorCellValues(
      secondaryValues,
      row
    );

    if (value === "Non-Instruction Hours") {
      let range = XLSX.utils.decode_range(staffWS["!ref"]);
      let hourAmountLocation = null;
      let classCountLocations = [];
      for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
        let nextCell =
          staffWS[
          XLSX.utils.encode_cell({ r: rowNum, c: primaryLookupColumn })
          ];
        let primaryCellValue = null;
        if (nextCell !== undefined) {
          primaryCellValue = this.findValueFromNextCell(wb, nextCell);
        }

        let secondaryCellValue = "~";
        if (secondaryLookupColumn !== null) {
          let secondNextCell =
            staffWS[
            XLSX.utils.encode_cell({ r: rowNum, c: secondaryLookupColumn })
            ];
          if (secondNextCell !== undefined) {
            secondaryCellValue = this.findValueFromNextCell(wb, secondNextCell);
          }
        }

        if (
          primaryCellValue !== null &&
          secondaryCellValue !== null &&
          primaryCellValue === "Regular Totals" &&
          (secondaryCellValue === "~" ||
            this.valueIncluded(trueSecondaryValues, secondaryCellValue) ||
            trueSecondaryValues.length === 0)
        ) {
          hourAmountLocation = XLSX.utils.encode_cell({ r: rowNum, c: 4 });
          continue;
        }

        if (
          primaryCellValue !== null &&
          secondaryCellValue !== null &&
          this.valueIncluded(truePrimaryValues, primaryCellValue) &&
          (secondaryCellValue === "~" ||
            this.valueIncluded(trueSecondaryValues, secondaryCellValue) ||
            trueSecondaryValues.length === 0)
        ) {
          classCountLocations.push(XLSX.utils.encode_cell({ r: rowNum, c: 0 }));
          continue;
        }
      }
      if (classCountLocations.length === 0 || hourAmountLocation === null) {
        return null;
      }
      let halfFormula = this.createCellCountFormula(
        classCountLocations,
        staffName
      );
      let formula =
        "MAX(0," +
        "'" +
        staffName +
        "'!" +
        hourAmountLocation +
        " - " +
        halfFormula +
        ")";
      return { formula: true, output: formula };
    } else if (value.includes("Class Base Amount")) {
      let result = this.findBucketFromString(value);
      let rateName = null;
      if (result) {
        rateName = result.getBucketRateString();
      }
      //FUCK
      let baseRate = this.getBaseRate(
        staffObj,
        trueSecondaryValues[0],
        rateName
      );

      // for (let i = 0; i < truePrimaryValues.length; i++) {
      //   if (truePrimaryValues[i].includes("Semi")) {
      //     baseRate = staffObj.getPrivateRate(trueSecondaryValues[0])[1];
      //   }
      // }

      let range = XLSX.utils.decode_range(staffWS["!ref"]);
      let classCountLocations = [];
      for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
        let nextCell =
          staffWS[
          XLSX.utils.encode_cell({ r: rowNum, c: primaryLookupColumn })
          ];
        let primaryCellValue = null;
        if (nextCell !== undefined) {
          primaryCellValue = this.findValueFromNextCell(wb, nextCell);
        }

        let secondaryCellValue = "~";
        if (secondaryLookupColumn !== null) {
          let secondNextCell =
            staffWS[
            XLSX.utils.encode_cell({ r: rowNum, c: secondaryLookupColumn })
            ];
          if (secondNextCell !== undefined) {
            secondaryCellValue = this.findValueFromNextCell(wb, secondNextCell);
          }
        }

        let countCellValue = "~";
        try {
          let countNextCell =
            staffWS[XLSX.utils.encode_cell({ r: rowNum, c: 4 })];
          if (countNextCell === undefined || countNextCell === "undefined") {
            continue;
          }
          countCellValue = this.findValueFromNextCell(wb, countNextCell);
        } catch (e) {
          continue;
        }

        let payCellValue = "~";
        try {
          let payNextCell =
            staffWS[XLSX.utils.encode_cell({ r: rowNum, c: 5 })];
          if (payNextCell === undefined) {
            continue;
          }

          payCellValue = this.findValueFromNextCell(wb, payNextCell);
        } catch (e) {
          console.warn(e);
          continue;
        }

        if (
          parseFloat(countCellValue) === 0 &&
          parseFloat(payCellValue) === 0
        ) {
          continue;
        }

        if (
          primaryCellValue !== null &&
          secondaryCellValue !== null &&
          this.valueIncluded(truePrimaryValues, primaryCellValue) &&
          (secondaryCellValue === "~" ||
            this.valueIncluded(trueSecondaryValues, secondaryCellValue) ||
            trueSecondaryValues.length === 0)
        ) {
          classCountLocations.push(XLSX.utils.encode_cell({ r: rowNum, c: 0 }));
          continue;
        }
      }
      if (classCountLocations.length === 0 || baseRate === null) {
        return null;
      }
      let halfFormula = this.createCellCountFormula(
        classCountLocations,
        staffName
      );
      let formula = baseRate + " * " + halfFormula;
      return { formula: true, output: formula };
    } else if (value.includes("Class Bonus Amount")) {
      let result = this.findBucketFromString(value);
      let rateName = null;
      if (result) {
        rateName = result.getBucketRateString();
      }
      if (rateName === null) {
        return null;
      }
      let baseRate = this.getBaseRate(
        staffObj,
        trueSecondaryValues[0],
        rateName
      );
      // let baseRate = this.getBaseRate(staffObj, trueSecondaryValues[0]);

      // for (let i = 0; i < truePrimaryValues.length; i++) {
      //   if (truePrimaryValues[i].includes("Semi")) {
      //     baseRate = staffObj.getPrivateRate(trueSecondaryValues[0])[1];
      //   }
      // }

      let range = XLSX.utils.decode_range(staffWS["!ref"]);
      let classCountLocations = [];
      for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
        let nextCell =
          staffWS[
          XLSX.utils.encode_cell({ r: rowNum, c: primaryLookupColumn })
          ];
        let primaryCellValue = null;
        if (nextCell !== undefined) {
          primaryCellValue = this.findValueFromNextCell(wb, nextCell);
        }

        let secondaryCellValue = "~";
        if (secondaryLookupColumn !== null) {
          let secondNextCell =
            staffWS[
            XLSX.utils.encode_cell({ r: rowNum, c: secondaryLookupColumn })
            ];
          if (secondNextCell !== undefined) {
            secondaryCellValue = this.findValueFromNextCell(wb, secondNextCell);
          }
        }

        let countCellValue = "~";
        try {
          let countNextCell =
            staffWS[XLSX.utils.encode_cell({ r: rowNum, c: 4 })];
          if (countNextCell === undefined || countNextCell === "undefined") {
            continue;
          }
          countCellValue = this.findValueFromNextCell(wb, countNextCell);
        } catch (e) {
          continue;
        }

        let payCellValue = "~";
        try {
          let payNextCell =
            staffWS[XLSX.utils.encode_cell({ r: rowNum, c: 5 })];
          if (payNextCell === undefined) {
            continue;
          }

          payCellValue = this.findValueFromNextCell(wb, payNextCell);
        } catch (e) {
          continue;
        }

        if (
          parseFloat(countCellValue) === 0 &&
          parseFloat(payCellValue) === 0
        ) {
          continue;
        }

        if (
          primaryCellValue !== null &&
          secondaryCellValue !== null &&
          this.valueIncluded(truePrimaryValues, primaryCellValue) &&
          (secondaryCellValue === "~" ||
            this.valueIncluded(trueSecondaryValues, secondaryCellValue) ||
            trueSecondaryValues.length === 0)
        ) {
          classCountLocations.push(XLSX.utils.encode_cell({ r: rowNum, c: 5 }));
          continue;
        }
      }
      if (classCountLocations.length === 0 || baseRate === null) {
        return null;
      }
      let totalPay = this.createCellReferenceFormula(
        classCountLocations,
        staffName
      );
      let totalBase = this.createCellCountFormula(
        classCountLocations,
        staffName
      );
      let formula = totalPay + "- (" + baseRate + " * " + totalBase + ")";
      return { formula: true, output: formula };
    } else if (value === "Staff Attribute: Hourly Rate") {
      return {
        formula: false,
        output: staffObj.getHourlyRate(trueSecondaryValues[0]),
      };
    } else if (value === "Staff Attribute: Secondary Hourly Rate") {
      return {
        formula: false,
        output: staffObj.getSecondHourlyRate(trueSecondaryValues[0]),
      };
    } else if (value === "Staff Attribute: Overtime Rate") {
      return {
        formula: false,
        output: 1.5 * staffObj.getHourlyRate(trueSecondaryValues[0]),
      };
    } else if (value === "Staff Attribute: Double Overtime Rate") {
      return {
        formula: false,
        output: 2 * staffObj.getHourlyRate(trueSecondaryValues[0]),
      };
    } else if (value.includes("*Count*") && !value.includes("(non-zero)")) {
      let range = XLSX.utils.decode_range(staffWS["!ref"]);
      let classCountLocations = [];
      for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
        let nextCell =
          staffWS[
          XLSX.utils.encode_cell({ r: rowNum, c: primaryLookupColumn })
          ];
        let primaryCellValue = null;
        if (nextCell !== undefined) {
          primaryCellValue = this.findValueFromNextCell(wb, nextCell);
        }

        let secondaryCellValue = "~";
        if (secondaryLookupColumn !== null) {
          let secondNextCell =
            staffWS[
            XLSX.utils.encode_cell({ r: rowNum, c: secondaryLookupColumn })
            ];
          if (secondNextCell !== undefined) {
            secondaryCellValue = this.findValueFromNextCell(wb, secondNextCell);
          }
        }

        let countCellValue = "~";
        let countNextCell =
          staffWS[XLSX.utils.encode_cell({ r: rowNum, c: 4 })];
        if (countNextCell === undefined || countNextCell === "undefined") {
          continue;
        }
        try {
          countCellValue = this.findValueFromNextCell(wb, countNextCell);
        } catch (e) {
          continue;
        }

        let payCellValue = "~";
        let payNextCell = staffWS[XLSX.utils.encode_cell({ r: rowNum, c: 5 })];
        if (payNextCell === undefined) {
          continue;
        }
        try {
          payCellValue = this.findValueFromNextCell(wb, payNextCell);
        } catch (e) {
          continue;
        }

        if (
          parseFloat(countCellValue) === 0 &&
          parseFloat(payCellValue) === 0
        ) {
          continue;
        }

        if (
          primaryCellValue !== null &&
          secondaryCellValue !== null &&
          this.valueIncluded(truePrimaryValues, primaryCellValue) &&
          (secondaryCellValue === "~" ||
            this.valueIncluded(trueSecondaryValues, secondaryCellValue) ||
            trueSecondaryValues.length === 0)
        ) {
          classCountLocations.push(XLSX.utils.encode_cell({ r: rowNum, c: 0 }));
          continue;
        }
      }
      if (classCountLocations.length === 0) {
        return null;
      }

      let countFormula = this.createCellCountFormula(
        classCountLocations,
        staffName
      );
      return { formula: true, output: countFormula };
    } else if (value === "*Count (non-zero)*") {
      let range = XLSX.utils.decode_range(staffWS["!ref"]);
      let classCountLocations = [];
      for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
        let nextCell = staffWS[XLSX.utils.encode_cell({ r: rowNum, c: 4 })];
        let primaryCellValue = null;
        if (nextCell !== undefined) {
          primaryCellValue = this.findValueFromNextCell(wb, nextCell);
        }

        let secondaryCellValue = "~";
        if (secondaryLookupColumn !== null) {
          let secondNextCell =
            staffWS[
            XLSX.utils.encode_cell({ r: rowNum, c: secondaryLookupColumn })
            ];
          if (secondNextCell !== undefined) {
            secondaryCellValue = this.findValueFromNextCell(wb, secondNextCell);
          }
        }

        if (
          primaryCellValue !== null &&
          parseInt(primaryCellValue) !== 0 &&
          secondaryCellValue !== null &&
          this.valueIncluded(truePrimaryValues, primaryCellValue) &&
          (secondaryCellValue === "~" ||
            this.valueIncluded(trueSecondaryValues, secondaryCellValue) ||
            trueSecondaryValues.length === 0)
        ) {
          classCountLocations.push(XLSX.utils.encode_cell({ r: rowNum, c: 0 }));
          continue;
        }
      }
      if (classCountLocations.length === 0) {
        return null;
      }

      let countFormula = this.createCellCountFormula(
        classCountLocations,
        staffName
      );
      return { formula: true, output: countFormula };
    } else if (value.includes("Staff Attribute: ") && value.includes("Base")) {
      //change to add sessions type in this dynamically
      let result = this.findBucketFromString(value);
      let rateName = null;
      if (result) {
        rateName = result.getBucketRateString();
      }

      if (rateName === null) {
        return null;
      }
      let baseRate = this.getBaseRate(
        staffObj,
        trueSecondaryValues[0],
        rateName
      );

      return { formula: false, output: baseRate };
    } else if (value.includes("Staff Attribute: ")) {
      const regex = /Staff Attribute:\s*(.*)/;
      const match = value.match(regex);
      let attr = match ? match[1] : null;
      if (attr === null) {
        return null;
      }
      return {
        formula: false,
        output: staffObj.getProperty(trueSecondaryValues[0], attr),
      };
    } else if (value === "True Class Hours") {
      let range = XLSX.utils.decode_range(staffWS["!ref"]);
      let trueHours = 0;
      for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
        let nextCell =
          staffWS[
          XLSX.utils.encode_cell({ r: rowNum, c: primaryLookupColumn })
          ];
        let primaryCellValue = null;
        if (nextCell !== undefined) {
          primaryCellValue = this.findValueFromNextCell(wb, nextCell);
        }

        let secondaryCellValue = "~";
        if (secondaryLookupColumn !== null) {
          let secondNextCell =
            staffWS[
            XLSX.utils.encode_cell({ r: rowNum, c: secondaryLookupColumn })
            ];
          if (secondNextCell !== undefined) {
            secondaryCellValue = this.findValueFromNextCell(wb, secondNextCell);
          }
        }

        let countCellValue = "~";
        try {
          let countNextCell =
            staffWS[XLSX.utils.encode_cell({ r: rowNum, c: 4 })];
          if (countNextCell === undefined || countNextCell === "undefined") {
            continue;
          }
          countCellValue = this.findValueFromNextCell(wb, countNextCell);
        } catch (e) {
          continue;
        }

        let payCellValue = "~";
        try {
          let payNextCell =
            staffWS[XLSX.utils.encode_cell({ r: rowNum, c: 5 })];
          if (payNextCell === undefined) {
            continue;
          }

          payCellValue = this.findValueFromNextCell(wb, payNextCell);
        } catch (e) {
          continue;
        }
        if (
          parseFloat(countCellValue) === 0 &&
          parseFloat(payCellValue) === 0
        ) {
          continue;
        }

        if (
          primaryCellValue !== null &&
          secondaryCellValue !== null &&
          this.valueIncluded(truePrimaryValues, primaryCellValue) &&
          (secondaryCellValue === "~" ||
            this.valueIncluded(trueSecondaryValues, secondaryCellValue) ||
            trueSecondaryValues.length === 0)
        ) {
          let locationCell =
            staffWS[XLSX.utils.encode_cell({ r: rowNum, c: 1 })];
          let location = null;
          if (locationCell !== undefined) {
            location = this.findValueFromNextCell(wb, locationCell);
          }

          let dateCell = staffWS[XLSX.utils.encode_cell({ r: rowNum, c: 2 })];
          let date = null;
          if (dateCell !== undefined) {
            date = this.findValueFromNextCell(wb, dateCell);
          }

          let timeCell = staffWS[XLSX.utils.encode_cell({ r: rowNum, c: 3 })];
          let time = null;
          if (timeCell !== undefined) {
            time = this.findValueFromNextCell(wb, timeCell);
          }

          let foundClass = this.findClass(location, staffName, date, time);
          if (foundClass !== null) {
            trueHours += foundClass.sessionLength;
          }
          //trueHours += foundClassLength;

          //classCountLocations.push(XLSX.utils.encode_cell({r:rowNum, c:5}));
          continue;
        }
      }
      if (trueHours === 0) {
        return null;
      }
      return { formula: true, output: "SUM(" + trueHours / 60 + ")" };
    } else if (value === "True Non-Class Hours") {
      let range = XLSX.utils.decode_range(staffWS["!ref"]);
      let totalHours = 0;
      for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
        let nextCell = staffWS[XLSX.utils.encode_cell({ r: rowNum, c: 0 })];
        let primaryCellValue = null;
        if (nextCell !== undefined) {
          primaryCellValue = this.findValueFromNextCell(wb, nextCell);
        }

        let secondaryCellValue = "~";
        if (secondaryLookupColumn !== null) {
          let secondNextCell =
            staffWS[
            XLSX.utils.encode_cell({ r: rowNum, c: secondaryLookupColumn })
            ];
          if (secondNextCell !== undefined) {
            secondaryCellValue = this.findValueFromNextCell(wb, secondNextCell);
          }
        }

        if (
          primaryCellValue !== null &&
          secondaryCellValue !== null &&
          this.valueIncluded(["Regular Totals"], primaryCellValue) &&
          (secondaryCellValue === "~" ||
            this.valueIncluded(trueSecondaryValues, secondaryCellValue) ||
            trueSecondaryValues.length === 0)
        ) {
          let hoursCell = staffWS[XLSX.utils.encode_cell({ r: rowNum, c: 4 })];
          let hoursValue = null;
          if (hoursCell !== undefined) {
            hoursValue = this.findValueFromNextCell(wb, hoursCell);
          }
          if (hoursValue !== null) {
            totalHours += parseFloat(hoursValue);
          }
          //classCountLocations.push(XLSX.utils.encode_cell({r:rowNum, c:0}));
          continue;
        }
      }

      range = XLSX.utils.decode_range(staffWS["!ref"]);
      let trueHours = 0;
      for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
        let nextCell =
          staffWS[
          XLSX.utils.encode_cell({ r: rowNum, c: primaryLookupColumn })
          ];
        let primaryCellValue = null;
        if (nextCell !== undefined) {
          primaryCellValue = this.findValueFromNextCell(wb, nextCell);
        }

        let secondaryCellValue = "~";
        if (secondaryLookupColumn !== null) {
          let secondNextCell =
            staffWS[
            XLSX.utils.encode_cell({ r: rowNum, c: secondaryLookupColumn })
            ];
          if (secondNextCell !== undefined) {
            secondaryCellValue = this.findValueFromNextCell(wb, secondNextCell);
          }
        }

        let countCellValue = "~";
        try {
          let countNextCell =
            staffWS[XLSX.utils.encode_cell({ r: rowNum, c: 4 })];
          if (countNextCell === undefined || countNextCell === "undefined") {
            continue;
          }
          countCellValue = this.findValueFromNextCell(wb, countNextCell);
        } catch (e) {
          continue;
        }

        let payCellValue = "~";
        try {
          let payNextCell =
            staffWS[XLSX.utils.encode_cell({ r: rowNum, c: 5 })];
          if (payNextCell === undefined) {
            continue;
          }

          payCellValue = this.findValueFromNextCell(wb, payNextCell);
        } catch (e) {
          continue;
        }
        if (
          parseFloat(countCellValue) === 0 &&
          parseFloat(payCellValue) === 0
        ) {
          continue;
        }

        if (
          primaryCellValue !== null &&
          secondaryCellValue !== null &&
          this.valueIncluded(truePrimaryValues, primaryCellValue) &&
          (secondaryCellValue === "~" ||
            this.valueIncluded(trueSecondaryValues, secondaryCellValue) ||
            trueSecondaryValues.length === 0)
        ) {
          let locationCell =
            staffWS[XLSX.utils.encode_cell({ r: rowNum, c: 1 })];
          let location = null;
          if (locationCell !== undefined) {
            location = this.findValueFromNextCell(wb, locationCell);
          }

          let dateCell = staffWS[XLSX.utils.encode_cell({ r: rowNum, c: 2 })];
          let date = null;
          if (dateCell !== undefined) {
            date = this.findValueFromNextCell(wb, dateCell);
          }

          let timeCell = staffWS[XLSX.utils.encode_cell({ r: rowNum, c: 3 })];
          let time = null;
          if (timeCell !== undefined) {
            time = this.findValueFromNextCell(wb, timeCell);
          }

          let foundClass = this.findClass(location, staffName, date, time);
          if (foundClass !== null) {
            trueHours += foundClass.sessionLength;
          }
          continue;
        }
      }
      trueHours = trueHours / 60;

      if (trueHours === 0 && totalHours === 0) {
        return null;
      }

      let difference = totalHours - trueHours;
      if (difference < 0) {
        difference = 0;
      }
      return { formula: true, output: "SUM(" + difference + ")" };
    } else if (
      typeof value === "string" &&
      value.charAt(0) === "[" &&
      value.charAt(value.length - 1) === "]"
    ) {
      let strValue = value.substring(1, value.length - 1);
      return { formula: true, output: 'CONCATENATE("' + strValue + '")' };
    } else if (
      typeof value === "string" &&
      value.charAt(0) === "{" &&
      value.charAt(value.length - 1) === "}"
    ) {
      let strValue = value.substring(1, value.length - 1);
      return { formula: false, output: strValue };
    } else if (value.includes("Per Head Bonus")) {
      //change to add sessions type in this dynamically
      let result = this.findBucketFromString(value);
      let rateName = null;
      if (result) {
        rateName = result.getBucketRateString();
      }

      if (rateName === null) {
        return null;
      }
      let rateArray = staffObj.getProperty(trueSecondaryValues[0], rateName);
      //let rateArray = staffObj[rateName][trueSecondaryValues[0]];
      let perHead = this.mostCommonDifference(rateArray);
      return { formula: false, output: perHead };
    } else if (value.includes("Per Head Bonus")) {
      let bucket = this.findBucketFromString(value);
      let bucketRateStr = bucket.getBucketRateString();
      let rateArray = staffObj.getProperty(
        trueSecondaryValues[0],
        bucketRateStr
      );
      let perHead = this.mostCommonDifference(rateArray);
      return { formula: false, output: perHead };
    } else if (value === "Intro Conversion") {
      let range = XLSX.utils.decode_range(staffWS["!ref"]);
      let introConversions = 0;
      for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
        let nextCell =
          staffWS[
          XLSX.utils.encode_cell({ r: rowNum, c: primaryLookupColumn })
          ];
        let primaryCellValue = null;
        if (nextCell !== undefined) {
          primaryCellValue = this.findValueFromNextCell(wb, nextCell);
        }

        let secondaryCellValue = "~";
        if (secondaryLookupColumn !== null) {
          let secondNextCell =
            staffWS[
            XLSX.utils.encode_cell({ r: rowNum, c: secondaryLookupColumn })
            ];
          if (secondNextCell !== undefined) {
            secondaryCellValue = this.findValueFromNextCell(wb, secondNextCell);
          }
        }

        if (
          primaryCellValue !== null &&
          secondaryCellValue !== null &&
          this.valueIncluded(truePrimaryValues, primaryCellValue) &&
          (secondaryCellValue === "~" ||
            this.valueIncluded(trueSecondaryValues, secondaryCellValue) ||
            trueSecondaryValues.length === 0)
        ) {
          let locationCell =
            staffWS[XLSX.utils.encode_cell({ r: rowNum, c: 1 })];
          let location = null;
          if (locationCell !== undefined) {
            location = this.findValueFromNextCell(wb, locationCell);
          }

          let dateCell = staffWS[XLSX.utils.encode_cell({ r: rowNum, c: 2 })];
          let date = null;
          if (dateCell !== undefined) {
            date = this.findValueFromNextCell(wb, dateCell);
          }

          let timeCell = staffWS[XLSX.utils.encode_cell({ r: rowNum, c: 3 })];
          let time = null;
          if (timeCell !== undefined) {
            time = this.findValueFromNextCell(wb, timeCell);
          }

          let foundClass = this.findClass(location, staffName, date, time);
          if (foundClass !== null) {
            for (let i = 0; i < foundClass.attendeeList.length; i++) {
              let attendee = foundClass.attendeeList[i];
              if (attendee.signedUpAfterSession) {
                introConversions += 1;
              }
            }
          }

          //classCountLocations.push(XLSX.utils.encode_cell({r:rowNum, c:5}));
          continue;
        }
      }
      if (introConversions === 0) {
        return null;
      }
      return { formula: true, output: "SUM(" + introConversions + ")" };
    } else if (value.includes("Bonus Threshold Attendees")) {
      let bucket = this.findBucketFromString(value);
      let rateName = bucket.getBucketRateString();

      let baseRate = this.getBaseRate(
        staffObj,
        trueSecondaryValues[0],
        rateName
      );

      let range = XLSX.utils.decode_range(staffWS["!ref"]);
      let classCountLocations = [];
      for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
        let nextCell =
          staffWS[
          XLSX.utils.encode_cell({ r: rowNum, c: primaryLookupColumn })
          ];
        let primaryCellValue = null;
        if (nextCell !== undefined) {
          primaryCellValue = this.findValueFromNextCell(wb, nextCell);
        }

        let secondaryCellValue = "~";
        if (secondaryLookupColumn !== null) {
          let secondNextCell =
            staffWS[
            XLSX.utils.encode_cell({ r: rowNum, c: secondaryLookupColumn })
            ];
          if (secondNextCell !== undefined) {
            secondaryCellValue = this.findValueFromNextCell(wb, secondNextCell);
          }
        }

        let countCellValue = "~";
        try {
          let countNextCell =
            staffWS[XLSX.utils.encode_cell({ r: rowNum, c: 4 })];
          if (countNextCell === undefined || countNextCell === "undefined") {
            continue;
          }
          countCellValue = this.findValueFromNextCell(wb, countNextCell);
        } catch (e) {
          continue;
        }

        let payCellValue = "~";
        try {
          let payNextCell =
            staffWS[XLSX.utils.encode_cell({ r: rowNum, c: 5 })];
          if (payNextCell === undefined) {
            continue;
          }

          payCellValue = this.findValueFromNextCell(wb, payNextCell);
        } catch (e) {
          continue;
        }

        if (
          parseFloat(countCellValue) === 0 &&
          parseFloat(payCellValue) === 0
        ) {
          continue;
        }

        if (
          primaryCellValue !== null &&
          secondaryCellValue !== null &&
          this.valueIncluded(truePrimaryValues, primaryCellValue) &&
          (secondaryCellValue === "~" ||
            this.valueIncluded(trueSecondaryValues, secondaryCellValue) ||
            trueSecondaryValues.length === 0)
        ) {
          classCountLocations.push(XLSX.utils.encode_cell({ r: rowNum, c: 5 }));
          continue;
        }
      }
      if (classCountLocations.length === 0 || baseRate === null) {
        return null;
      }

      let rateArray = staffObj.getProperty(trueSecondaryValues[0], rateName);
      let perHead = this.mostCommonDifference(rateArray);
      if (perHead === 0) {
        perHead = 1;
      }

      let totalPay = this.createCellReferenceFormula(
        classCountLocations,
        staffName
      );
      let totalBase = this.createCellCountFormula(
        classCountLocations,
        staffName
      );
      let formula =
        "(" +
        totalPay +
        "- (" +
        baseRate +
        " * " +
        totalBase +
        ") ) /" +
        perHead;

      return { formula: true, output: formula };
    } else if (value.charAt(0) === "=") {
      // let regex = /([A-Z]+[a-z]*)(?!\$)/g;

      // let result = value.replace(regex, function(match) {
      //     return match + (row + 1);
      // });
      // let regex = /([A-Z]+[a-z]*)(?!\$)/g;

      // let result = value.replace(regex, function(match) {
      // if (match.toUpperCase() === "IF" || match.toUpperCase() === "OR" || match.toUpperCase() === "AND" || match.toUpperCase() === "ABS" || match.toUpperCase() === "MIN" || match.toUpperCase() === "MAX" || match.toUpperCase() === "SUM" || match.toUpperCase() === "LEN" || match.toUpperCase() === "MID") {
      //     return match; // Return the match without any modification
      // } else {
      //     return match + (row + 1);
      // }
      // });

      let formula = value.slice(1);
      let ifError = true;
      //check if '=='
      if (formula.charAt(0) === "=") {
        ifError = false;
        formula = formula.slice(1);
      }

      //this is for the delete empty string side of this
      let ignoreRemove = false;
      if (formula.charAt(0) === "*") {
        ignoreRemove = true;
        formula = formula.slice(1);
      }

      let regex = /\b[A-Za-z]{1,2}\b/g;

      let result = formula.replace(regex, function (match, index) {
        //let matchPosition = formula.indexOf(match);

        // If the character before the match is an alphabetical character, return the match as is
        if (
          (index > 0 && /[A-Za-z]/.test(formula.charAt(index - 1))) ||
          (index + match.length < formula.length &&
            /[A-Za-z]/.test(formula.charAt(index + match.length)))
        ) {
          return match;
        }

        // If the match is one of the specified strings, return the match as is
        if (["IF", "OR"].includes(match.toUpperCase())) {
          return match;
        } else {
          // Add the row value if the next character isn't a "$"
          if (formula.charAt(index + match.length) !== "$") {
            return match + (row + 1);
          }
          return match;
        }
      });

      let finalFormula = "IFERROR(" + result + ',"")';
      if (!ifError) {
        finalFormula = result;
      }

      if (ignoreRemove) {
        finalFormula = "IF(TRUE," + finalFormula + ',"")';
      }

      return { formula: true, output: finalFormula };
    } else if (value === "Attendees") {
      let range = XLSX.utils.decode_range(staffWS["!ref"]);
      let attendees = 0;
      for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
        let nextCell =
          staffWS[
          XLSX.utils.encode_cell({ r: rowNum, c: primaryLookupColumn })
          ];
        let primaryCellValue = null;
        if (nextCell !== undefined) {
          primaryCellValue = this.findValueFromNextCell(wb, nextCell);
        }

        let secondaryCellValue = "~";
        if (secondaryLookupColumn !== null) {
          let secondNextCell =
            staffWS[
            XLSX.utils.encode_cell({ r: rowNum, c: secondaryLookupColumn })
            ];
          if (secondNextCell !== undefined) {
            secondaryCellValue = this.findValueFromNextCell(wb, secondNextCell);
          }
        }

        // let countCellValue = "~";
        // try{
        //     let countNextCell = staffWS[
        //         XLSX.utils.encode_cell({r: rowNum, c: 4})
        //     ];
        //     if(countNextCell === undefined || countNextCell === "undefined"){
        //         continue;
        //     }
        //     countCellValue = findValueFromNextCell(wb, countNextCell);
        // }catch(e){
        //     continue;
        // }

        // let payCellValue = "~";
        // try{
        //     let payNextCell = staffWS[
        //         XLSX.utils.encode_cell({r: rowNum, c: 5})
        //     ];
        //     if(payNextCell === undefined){
        //         continue;
        //     }

        //     payCellValue = findValueFromNextCell(wb, payNextCell);

        // }catch(e){
        //     continue;
        // }
        // if(parseFloat(countCellValue) === 0 && parseFloat(payCellValue) === 0){
        //     continue;
        // }

        if (
          primaryCellValue !== null &&
          secondaryCellValue !== null &&
          this.valueIncluded(truePrimaryValues, primaryCellValue) &&
          (secondaryCellValue === "~" ||
            this.valueIncluded(trueSecondaryValues, secondaryCellValue) ||
            trueSecondaryValues.length === 0)
        ) {
          let locationCell =
            staffWS[XLSX.utils.encode_cell({ r: rowNum, c: 1 })];
          let location = null;
          if (locationCell !== undefined) {
            location = this.findValueFromNextCell(wb, locationCell);
          }

          let dateCell = staffWS[XLSX.utils.encode_cell({ r: rowNum, c: 2 })];
          let date = null;
          if (dateCell !== undefined) {
            date = this.findValueFromNextCell(wb, dateCell);
          }

          let timeCell = staffWS[XLSX.utils.encode_cell({ r: rowNum, c: 3 })];
          let time = null;
          if (timeCell !== undefined) {
            time = this.findValueFromNextCell(wb, timeCell);
          }

          let foundClass = this.findClass(location, staffName, date, time);
          if (foundClass !== null) {
            attendees += foundClass.attendeeCount;
          }

          //classCountLocations.push(XLSX.utils.encode_cell({r:rowNum, c:5}));
          continue;
        }
      }
      if (attendees === 0) {
        return null;
      }
      return { formula: false, output: attendees };
    } else if (value === "Intro Conversion Percent") {
      let attendeeExcelAttribute = excelAttribute;
      attendeeExcelAttribute.valueColumn = "Attendees";
      let introAttendees = this.getSpecialFormula(
        wb,
        staffWS,
        attendeeExcelAttribute,
        row,
        staffName
      );

      let conversionExcelAttribute = excelAttribute;
      conversionExcelAttribute.valueColumn = "Intro Conversion";
      let introSignUps = this.getSpecialFormula(
        wb,
        staffWS,
        conversionExcelAttribute,
        row,
        staffName
      );
      if (introAttendees !== null && introSignUps !== null) {
        return { formula: false, output: introSignUps / introAttendees };
      }
      return null;
    } else if (value === "Gusto Non-Job Hours") {
      let range = XLSX.utils.decode_range(staffWS["!ref"]);
      let hourAmountLocations = [];
      let classCountLocations = [];

      for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
        let nextCell =
          staffWS[
          XLSX.utils.encode_cell({ r: rowNum, c: primaryLookupColumn })
          ];
        let primaryCellValue = null;
        if (nextCell !== undefined) {
          primaryCellValue = this.findValueFromNextCell(wb, nextCell);
        }

        let secondaryCellValue = "~";
        if (secondaryLookupColumn !== null) {
          let secondNextCell =
            staffWS[
            XLSX.utils.encode_cell({ r: rowNum, c: secondaryLookupColumn })
            ];
          if (secondNextCell !== undefined) {
            secondaryCellValue = this.findValueFromNextCell(wb, secondNextCell);
          }
        }

        let countCellValue = "~";
        try {
          let countNextCell =
            staffWS[XLSX.utils.encode_cell({ r: rowNum, c: 4 })];
          if (countNextCell === undefined || countNextCell === "undefined") {
            continue;
          }
          countCellValue = this.findValueFromNextCell(wb, countNextCell);
        } catch (e) {
          continue;
        }

        let payCellValue = "~";
        try {
          let payNextCell =
            staffWS[XLSX.utils.encode_cell({ r: rowNum, c: 5 })];
          if (payNextCell === undefined) {
            continue;
          }

          payCellValue = this.findValueFromNextCell(wb, payNextCell);
        } catch (e) {
          continue;
        }

        if (
          parseFloat(countCellValue) === 0 &&
          parseFloat(payCellValue) === 0
        ) {
          continue;
        }

        if (
          primaryCellValue !== null &&
          secondaryCellValue !== null &&
          (primaryCellValue.includes("Class") ||
            primaryCellValue.includes("Private") ||
            primaryCellValue.includes("Intro")) &&
          primaryCellValue.includes("Totals") &&
          (secondaryCellValue === "~" ||
            this.valueIncluded(trueSecondaryValues, secondaryCellValue) ||
            trueSecondaryValues.length === 0)
        ) {
          hourAmountLocations.push(XLSX.utils.encode_cell({ r: rowNum, c: 4 }));
          continue;
        }

        if (
          primaryCellValue !== null &&
          secondaryCellValue !== null &&
          this.valueIncluded(truePrimaryValues, primaryCellValue) &&
          (secondaryCellValue === "~" ||
            this.valueIncluded(trueSecondaryValues, secondaryCellValue) ||
            trueSecondaryValues.length === 0)
        ) {
          classCountLocations.push(XLSX.utils.encode_cell({ r: rowNum, c: 0 }));
          continue;
        }
      }
      if (
        classCountLocations.length === 0 ||
        hourAmountLocations.length === 0
      ) {
        return null;
      }
      let halfFormula = this.createCellCountFormula(
        classCountLocations,
        staffName
      );
      let otherHalfFormula = this.createCellReferenceFormula(
        hourAmountLocations,
        staffName
      );
      let formula = "MAX(0," + otherHalfFormula + " - " + halfFormula + ")";
      return { formula: true, output: formula };
    } else if (value === "Gusto Hours") {
      let range = XLSX.utils.decode_range(staffWS["!ref"]);
      let hourAmountLocations = [];
      let overtimeAmountLocations = [];

      for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
        let nextCell =
          staffWS[
          XLSX.utils.encode_cell({ r: rowNum, c: primaryLookupColumn })
          ];
        let primaryCellValue = null;
        if (nextCell !== undefined) {
          primaryCellValue = this.findValueFromNextCell(wb, nextCell);
        }

        let secondaryCellValue = "~";
        if (secondaryLookupColumn !== null) {
          let secondNextCell =
            staffWS[
            XLSX.utils.encode_cell({ r: rowNum, c: secondaryLookupColumn })
            ];
          if (secondNextCell !== undefined) {
            secondaryCellValue = this.findValueFromNextCell(wb, secondNextCell);
          }
        }

        let countCellValue = "~";
        try {
          let countNextCell =
            staffWS[XLSX.utils.encode_cell({ r: rowNum, c: 4 })];
          if (countNextCell === undefined || countNextCell === "undefined") {
            continue;
          }
          countCellValue = this.findValueFromNextCell(wb, countNextCell);
        } catch (e) {
          continue;
        }

        let payCellValue = "~";
        try {
          let payNextCell =
            staffWS[XLSX.utils.encode_cell({ r: rowNum, c: 5 })];
          if (payNextCell === undefined) {
            continue;
          }

          payCellValue = this.findValueFromNextCell(wb, payNextCell);
        } catch (e) {
          continue;
        }

        if (
          parseFloat(countCellValue) === 0 &&
          parseFloat(payCellValue) === 0
        ) {
          continue;
        }

        if (
          primaryCellValue !== null &&
          secondaryCellValue !== null &&
          this.valueIncluded(truePrimaryValues, primaryCellValue) &&
          (secondaryCellValue === "~" ||
            this.valueIncluded(trueSecondaryValues, secondaryCellValue) ||
            trueSecondaryValues.length === 0)
        ) {
          hourAmountLocations.push(XLSX.utils.encode_cell({ r: rowNum, c: 4 }));
          continue;
        }

        if (
          primaryCellValue !== null &&
          secondaryCellValue !== null &&
          this.valueIncluded(["Overtime"], primaryCellValue) &&
          (secondaryCellValue === "~" ||
            this.valueIncluded(trueSecondaryValues, secondaryCellValue) ||
            trueSecondaryValues.length === 0)
        ) {
          overtimeAmountLocations.push(
            XLSX.utils.encode_cell({ r: rowNum, c: 4 })
          );
          continue;
        }
      }
      if (hourAmountLocations.length === 0) {
        return null;
      }

      let hoursFormula = this.createCellReferenceFormula(
        hourAmountLocations,
        staffName
      );

      if (overtimeAmountLocations.length === 0) {
        return { formula: true, output: hoursFormula };
      } else {
        let overtimeFormula = this.createCellReferenceFormula(
          overtimeAmountLocations,
          staffName
        );
        let formula = "MAX(0," + hoursFormula + " - " + overtimeFormula + ")";
        return { formula: true, output: formula };
      }
    } else if (value === "Commission Proportion") {
      let commission_name =
        this.reportCompilerState.inputFilesArrays.questions.namingConventions
          .commissionName + " Detail";

      let commissionAmounts = [];
      for (let i = 0; i < this.cellRefByInstructor.length; i++) {
        //if (staffName === this.cellRefByInstructor[i].instructor) {
        if (
          truePrimaryValues
            .toString()
            .includes(this.cellRefByInstructor[i].type)
        ) {
          if (
            trueSecondaryValues
              .toString()
              .includes(this.cellRefByInstructor[i].studio)
          ) {
            commissionAmounts.push(
              "'" +
              commission_name +
              "'!" +
              this.indexToLetter(this.cellRefByInstructor[i].column) +
              this.cellRefByInstructor[i].row
            );
          }
        }
        //}
        // if(staffName === this.cellRefByInstructor[i].instructor && truePrimaryValues.includes(this.cellRefByInstructor[i].type) && trueSecondaryValues.includes(this.cellRefByInstructor[i].studio)){
        //     commissionAmounts.push("'Commission Detail'!" + this.cellRefByInstructor[i].column + this.cellRefByInstructor[i].row);
        // }
      }

      let time_name =
        this.reportCompilerState.inputFilesArrays.questions.namingConventions
          .timeName + " Detail";

      let staffHours = [];
      for (let i = 0; i < this.cellRefByInstructor.length; i++) {
        if (
          staffName === this.cellRefByInstructor[i].instructor &&
          "Time" === this.cellRefByInstructor[i].type &&
          trueSecondaryValues.includes(this.cellRefByInstructor[i].studio)
        ) {
          staffHours.push(
            "'" +
            time_name +
            "'!" +
            this.indexToLetter(this.cellRefByInstructor[i].column - 1) +
            this.cellRefByInstructor[i].row
          );
        }
      }

      let totalHours = [];
      for (let i = 0; i < this.cellRefByInstructor.length; i++) {
        if (
          "Time" === this.cellRefByInstructor[i].type &&
          trueSecondaryValues.includes(this.cellRefByInstructor[i].studio)
        ) {
          totalHours.push(
            "'" +
            time_name +
            "'!" +
            this.indexToLetter(this.cellRefByInstructor[i].column - 1) +
            this.cellRefByInstructor[i].row
          );
        }
      }

      if (commissionAmounts.length === 0) {
        return null;
      }
      if (staffHours.length === 0) {
        return null;
      }

      let commissionFormula = this.generateSumFormulas(commissionAmounts);
      let hoursFormula = this.generateSumFormulas(staffHours);
      let totalHoursFormula = this.generateSumFormulas(totalHours);

      let formulaFinal =
        "(" +
        hoursFormula +
        "/" +
        totalHoursFormula +
        ") * " +
        commissionFormula;
      return { formula: true, output: "ROUND(" + formulaFinal + ", 2)" };
    } else if (value.charAt(0) === "#") {
      //come back
      let match = value.match(/_(.*)_/);
      let valueBetweenUnderscores = match ? match[1] : null;

      let newExcelAttribute = JSON.parse(JSON.stringify(excelAttribute));
      newExcelAttribute.valueColumn = valueBetweenUnderscores;
      let cellReferences = this.findCellReference(
        wb,
        staffWS,
        newExcelAttribute,
        row
      );
      if (cellReferences.length > 0) {
        let formula = this.createCustomCellReferenceFormula(
          cellReferences,
          staffName,
          value.substring(1),
          valueBetweenUnderscores
        );

        formula = this.replaceAnyAttributeValues(
          formula,
          staffObj,
          trueSecondaryValues[0]
        );
        // let formula = this.createCellReferenceFormula(
        //   cellReferences,
        //   staffName
        // );
        // processorWS[XLSX.utils.encode_cell({ r: row, c: i })] = {
        //   t: "n",
        //   f: formula,
        // };
        return { formula: true, output: formula };
      }
    } else if (value.includes("Class Bookings")) {
      let staffObj = this.findStaffObject(staffName);
      let classes = this.reportCompilerState.studiosInformation.classes;
      let primaryValues = excelAttribute.primaryValues;

      let secondaryValues = excelAttribute.secondaryValues;

      let truePrimaryValues = this.lookupProcessorCellValues(
        primaryValues,
        row
      );
      let trueSecondaryValues = this.lookupProcessorCellValues(
        secondaryValues,
        row
      );
      let bookingCount = 0;
      for (let i = 0; i < classes.length; i++) {
        let tempClass = classes[i];
        let nameValid = false;
        for (let j = 0; j < truePrimaryValues.length; j++) {
          if (tempClass.name.includes(truePrimaryValues[j])) {
            nameValid = true;
          }
        }

        let locationValid = false;
        for (let j = 0; j < trueSecondaryValues.length; j++) {
          if (tempClass.location.includes(trueSecondaryValues[j])) {
            locationValid = true;
          }
        }
        if (nameValid && locationValid) {
          for (let a = 0; a < tempClass.attendeeList.length; a++) {
            let bucket = this.findClassBucket(tempClass.type);
            let attendee = tempClass.attendeeList[a];
            if (
              staffObj.isNamed(attendee.loggedBy) &&
              this.isCompletedStatus(bucket, attendee)
              //(bucket.classStatuses.includes(attendee.bookingEventType) || bucket.classStatuses.includes(attendee.bookingStatus) )
            ) {
              bookingCount++;
            }
          }
        }
      }
      if (bookingCount > 0) {
        let multiplier = this.extractNumberFromPattern(value, staffObj, trueSecondaryValues[0]);
        // if(multiplier !== null){
        //   bookingCount = bookingCount * multiplier;
        // }

        return { formula: true, output: bookingCount + "*" + multiplier };
      }
    } else if (value.includes("Unique Instances")) {
      let allValues = [];
      let range = XLSX.utils.decode_range(staffWS["!ref"]);
      for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
        let nextCell =
          staffWS[
          XLSX.utils.encode_cell({
            r: rowNum,
            c: parseInt(primaryLookupColumn),
          })
          ];
        let primaryCellValue = null;
        if (nextCell !== undefined) {
          primaryCellValue = this.findValueFromNextCell(wb, nextCell);
        }

        let secondaryCellValue = null;
        if (secondaryLookupColumn !== null) {
          let secondNextCell =
            staffWS[
            XLSX.utils.encode_cell({
              r: rowNum,
              c: parseInt(secondaryLookupColumn),
            })
            ];
          if (secondNextCell !== undefined) {
            secondaryCellValue = this.findValueFromNextCell(wb, secondNextCell);
          }
        }

        if (primaryCellValue !== null) {
          for (let i = 0; i < truePrimaryValues.length; i++) {
            if (
              this.checkValueEquivalence(primaryCellValue, truePrimaryValues[i])
            ) {
              //if(primaryCellValue.includes(truePrimaryValues[i])){
              if (secondaryCellValue !== null) {
                for (let x = 0; x < trueSecondaryValues.length; x++) {
                  if (
                    this.checkValueEquivalence(
                      secondaryCellValue,
                      trueSecondaryValues[x]
                    )
                  ) {
                    let outputValue =
                      this.columnIndex(this.extractLetterFromPattern(value)) -
                      1;
                    //allValues.push(primaryCellValue);
                    let outputCell =
                      staffWS[
                      XLSX.utils.encode_cell({
                        r: rowNum,
                        c: parseInt(outputValue),
                      })
                      ];
                    let outputCellValue = null;
                    if (outputCell !== undefined) {
                      outputCellValue = this.findValueFromNextCell(
                        wb,
                        outputCell
                      );
                      allValues.push(outputCellValue);
                    }
                  }
                }
              }
            }
          }
        }
      }
      if (allValues.length > 0) {
        let uniqueValues = [...new Set(allValues)];
        let multiplier = this.extractNumberFromPattern(value, staffObj, trueSecondaryValues[0]);
        let uniqueOutput = uniqueValues.length;
        // if(multiplier !== null){
        //   uniqueOutput = uniqueOutput * multiplier;
        // }

        return { formula: true, output: uniqueOutput + "*" + multiplier };
      }
    } else if (value.includes("Session Length")){
      let foundClasses = [];
      //Session Length [15 || 30 || 60 || null] {intro: 60, private: 90} (Hours || Minutes)
      // {options}
      //Session Length [30] {intro: 60} (Hours)

      let round = null;
      const regexRound = /\[([^\]]+)\]/;
      const matchRound = value.match(regexRound);
      round = matchRound ? parseFloat(matchRound[1]) : null;

      let length = 1; //Minutes
      const regexLength = /\(([^)]+)\)/;
      const matchLength = value.match(regexLength);
      let lengthValue = matchLength ? matchLength[1] : "Minutes"; // "Hours" or "Minutes"
      if(lengthValue === "Hours"){
        length = 60;
      }
      
      const matchOptions = value.match(/\{[^}]*\}/);
      let optionsObject = {};
    
      if (matchOptions) {
          // Transform the JSON-like string into a valid JSON string
          const validJsonString = matchOptions[0].replace(/(\w+)(?=\s*:\s*\d+)/g, '"$1"');
          
          try {
              // Parse the valid JSON string into a JavaScript object
              optionsObject = JSON.parse(validJsonString);
          } catch (error) {
              console.log("Options incorrectly formatted.");
          }
      } else {
          console.log("No matching string found.");
      }

      let range = XLSX.utils.decode_range(staffWS["!ref"]);
      let trueTime = 0;
      for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
        let nextCell =
          staffWS[
          XLSX.utils.encode_cell({ r: rowNum, c: primaryLookupColumn })
          ];
        let primaryCellValue = null;
        if (nextCell !== undefined) {
          primaryCellValue = this.findValueFromNextCell(wb, nextCell);
        }

        let secondaryCellValue = "~";
        if (secondaryLookupColumn !== null) {
          let secondNextCell =
            staffWS[
            XLSX.utils.encode_cell({ r: rowNum, c: secondaryLookupColumn })
            ];
          if (secondNextCell !== undefined) {
            secondaryCellValue = this.findValueFromNextCell(wb, secondNextCell);
          }
        }

        let countCellValue = "~";
        try {
          let countNextCell =
            staffWS[XLSX.utils.encode_cell({ r: rowNum, c: 4 })];
          if (countNextCell === undefined || countNextCell === "undefined") {
            continue;
          }
          countCellValue = this.findValueFromNextCell(wb, countNextCell);
        } catch (e) {
          continue;
        }

        let payCellValue = "~";
        try {
          let payNextCell =
            staffWS[XLSX.utils.encode_cell({ r: rowNum, c: 5 })];
          if (payNextCell === undefined) {
            continue;
          }

          payCellValue = this.findValueFromNextCell(wb, payNextCell);
        } catch (e) {
          continue;
        }
        if (
          parseFloat(countCellValue) === 0 &&
          parseFloat(payCellValue) === 0
        ) {
          continue;
        }

        if (
          primaryCellValue !== null &&
          secondaryCellValue !== null &&
          this.valueIncluded(truePrimaryValues, primaryCellValue) &&
          (secondaryCellValue === "~" ||
            this.valueIncluded(trueSecondaryValues, secondaryCellValue) ||
            trueSecondaryValues.length === 0)
        ) {
          let locationCell =
            staffWS[XLSX.utils.encode_cell({ r: rowNum, c: 1 })];
          let location = null;
          if (locationCell !== undefined) {
            location = this.findValueFromNextCell(wb, locationCell);
          }

          let dateCell = staffWS[XLSX.utils.encode_cell({ r: rowNum, c: 2 })];
          let date = null;
          if (dateCell !== undefined) {
            date = this.findValueFromNextCell(wb, dateCell);
          }

          let timeCell = staffWS[XLSX.utils.encode_cell({ r: rowNum, c: 3 })];
          let time = null;
          if (timeCell !== undefined) {
            time = this.findValueFromNextCell(wb, timeCell);
          }

          let foundClass = this.findClass(location, staffName, date, time);
          foundClasses.push(foundClass);
          if (foundClass !== null) {
            let sessionLength = foundClass.sessionLength;
            let optionsKeys = Object.keys(optionsObject);
            if(optionsKeys.length > 0){
              for(let k = 0; k < optionsKeys.length; k++){
                if(foundClass.name.includes(optionsKeys[k])){
                  sessionLength = optionsObject[optionsKeys[k]];
                }
              }
            }
            if(round !== null){
              sessionLength = Math.round(sessionLength / round) * round
            }
            trueTime += sessionLength / length;
          }
          continue;
        }
      }
      if (trueTime === 0) {
        return null;
      }
      return { formula: true, output: 'SUM('+trueTime+')' };

    } else if (value === "Commission Proportion Updated") {

      let summaryCellRefs = this.findSummaryRows(wb, trueSecondaryValues);
      let timeAmount = summaryCellRefs[0];
      let commissionAmount = summaryCellRefs[1];
      // let commission_name =
      //   this.reportCompilerState.inputFilesArrays.questions.namingConventions
      //     .commissionName + " Detail";

      // let commissionAmounts = [];
      // for (let i = 0; i < this.cellRefByInstructor.length; i++) {
      //   //if (staffName === this.cellRefByInstructor[i].instructor) {
      //   if (
      //     truePrimaryValues
      //       .toString()
      //       .includes(this.cellRefByInstructor[i].type)
      //   ) {
      //     if (
      //       trueSecondaryValues
      //         .toString()
      //         .includes(this.cellRefByInstructor[i].studio)
      //     ) {
      //       commissionAmounts.push(
      //         "'" +
      //         commission_name +
      //         "'!" +
      //         this.indexToLetter(this.cellRefByInstructor[i].column) +
      //         this.cellRefByInstructor[i].row
      //       );
      //     }
      //   }
      //   //}
      //   // if(staffName === this.cellRefByInstructor[i].instructor && truePrimaryValues.includes(this.cellRefByInstructor[i].type) && trueSecondaryValues.includes(this.cellRefByInstructor[i].studio)){
      //   //     commissionAmounts.push("'Commission Detail'!" + this.cellRefByInstructor[i].column + this.cellRefByInstructor[i].row);
      //   // }
      // }

      let time_name =
        this.reportCompilerState.inputFilesArrays.questions.namingConventions
          .timeName + " Detail";

      let staffHours = [];
      for (let i = 0; i < this.cellRefByInstructor.length; i++) {
        if (
          staffName === this.cellRefByInstructor[i].instructor &&
          "Time" === this.cellRefByInstructor[i].type &&
          trueSecondaryValues.includes(this.cellRefByInstructor[i].studio)
        ) {
          staffHours.push(
            "'" +
            time_name +
            "'!" +
            this.indexToLetter(this.cellRefByInstructor[i].column - 1) +
            (this.cellRefByInstructor[i].row)
          );
        }
      }

      // let totalHours = [];
      // for (let i = 0; i < this.cellRefByInstructor.length; i++) {
      //   if (
      //     "Time" === this.cellRefByInstructor[i].type &&
      //     trueSecondaryValues.includes(this.cellRefByInstructor[i].studio)
      //   ) {
      //     totalHours.push(
      //       "'" +
      //       time_name +
      //       "'!" +
      //       this.indexToLetter(this.cellRefByInstructor[i].column - 1) +
      //       this.cellRefByInstructor[i].row
      //     );
      //   }
      // }

      // if (commissionAmounts.length === 0) {
      //   return null;
      // }
      if (staffHours.length === 0) {
        return null;
      }

      

      //let commissionFormula = this.generateSumFormulas(commissionAmounts);
      let hoursFormula = this.generateSumFormulas(staffHours);
      //let totalHoursFormula = this.generateSumFormulas(totalHours);

      let formulaFinal =
        "(" +
        hoursFormula +
        "/" +
        timeAmount +
        ") * " +
        commissionAmount;
      return { formula: true, output: "ROUND(" + formulaFinal + ", 2)" };
    }
    return null;
  }

  findSummaryRows(wb, trueSecondaryValues) {
    // Access the "Summary" sheet
    const ws = wb.Sheets['Summary'];
    
    if (!ws) {
        console.error('Sheet "Summary" not found.');
        return;
    }
    
    // Get and decode the range of the sheet
    const range = XLSX.utils.decode_range(ws['!ref']);
    
    // Array to store matching cell references
    const matchingCellRefs = [];
    
    // Iterate through the first column (column A)
    for (let row = range.s.r; row <= range.e.r; row++) {
        const cellRef = XLSX.utils.encode_cell({r: row, c: 0}); // 0 for column A
        const cell = ws[cellRef];
        
        if (cell) {
            let matchFound = false;
            // Iterate through each value in trueSecondaryValues
            for (let i = 0; i < trueSecondaryValues.length; i++) {
                // Check if cell value includes any of the secondary values
                if (cell.v.includes(trueSecondaryValues[i] + " Totals")) {
                    matchFound = true;
                    break; // Exit the loop early if a match is found
                }
            }
            
            // If a match is found, construct references for columns "N" and "U"
            if (matchFound) {
                // Construct references for columns "N" (column index 13) and "U" (column index 20)
                const cellRefN = XLSX.utils.encode_cell({r: row, c: 13}); // "N" column
                const cellRefU = XLSX.utils.encode_cell({r: row, c: 20}); // "U" column
                
                // Prefix with sheet name to form full cell reference (e.g., "Summary!N13")
                matchingCellRefs.push(`'Summary'!${cellRefN}`, `'Summary'!${cellRefU}`);
            }
        }
    }
    
    return matchingCellRefs;
}

  replaceAnyAttributeValues(formula, staffObj, location) {
    const regex = /\^(.*?)\^/g;
    let matches = formula.match(regex);
    let attributes = [];
    if (matches) {
      attributes = matches.map((match) => match.slice(1, -1));
    }

    for (let i = 0; i < attributes.length; i++) {
      let attribute = attributes[i];
      let attributeValue = this.getAttributeValue(
        attribute,
        staffObj,
        location
      );
      formula = formula.replace("^" + attribute + "^", attributeValue);
    }

    return formula;
  }

  getAttributeValue(attribute, staffObj, location) {
    if (attribute === "hourly") {
      return staffObj.getProperty(location, "hourly");
    } else if (attribute === "secondary hourly") {
      return staffObj.getProperty(location, "secondaryHourly");
    } else if (attribute.includes("base rate")) {
      let bucket = this.findBucketFromString(attribute);
      let bucketRateStr = bucket.getBucketRateString();
      //let rateArray = staffObj.getProperty(location, bucketRateStr);
      let baseRate = this.getBaseRate(staffObj, location, bucketRateStr);
      //let baseRate = this.mostCommonDifference(rateArray);
      return baseRate;
    } else if (attribute.includes("per head rate")) {
      let bucket = this.findBucketFromString(attribute);
      let bucketRateStr = bucket.getBucketRateString();
      let rateArray = staffObj.getProperty(location, bucketRateStr);
      let baseRate = this.mostCommonDifference(rateArray);
      return baseRate;
    }

    return 1;
  }

  isCompletedStatus(bucket, attendee) {
    let validStatuses = bucket.classStatuses;
    for (let i = 0; i < validStatuses.length; i++) {
      if (
        attendee.bookingEventType.includes(validStatuses[i]) ||
        attendee.bookingStatus.includes(validStatuses[i])
      ) {
        return true;
      }
    }
    return false;
  }

  extractNumberFromPattern(str, staffObj, location) {
    // The regex will match the pattern '*' followed by '(', 
    // then capture one or more digits, and finally ')'.
    const match = str.match(/[*]\((\d+)\)/);

    if (match && match[1]) {
      return parseInt(match[1], 10);
    }

    // New regex to match pattern with two carrots and parentheses
    // It captures the content between two '^' characters.
    const carrotMatch = str.match(/\(\^[^\)]+\^\)/);

    if (carrotMatch) {
      // Extract the content between two '^' without the parentheses and carrots
      const value = carrotMatch[0].match(/\^\^(.*)\^\^/)[1];
      // Call a function with the extracted value and return its result
      let theValue = this.replaceAnyAttributeValues(value, staffObj, location);
      return theValue
    }

    return 1;
  }

  extractLetterFromPattern(str) {
    // The regex will match the pattern '_' followed by '(',
    // then capture exactly one letter, and finally ')'.
    const match = str.match(/_([A-Za-z])_/);

    if (match && match[1]) {
      return match[1];
    }

    return null;
  }

  columnIndex(columnLabel) {
    let index = 0;
    // Loop through each character in the column label
    for (let i = 0; i < columnLabel.length; i++) {
      const charCode = columnLabel[i].toUpperCase().charCodeAt(0);
      if (charCode < 65 || charCode > 90) {
        // Ensure it's a letter
        throw new Error("Invalid character encountered: " + columnLabel[i]);
      }
      // Convert letter to number (A -> 1, B -> 2, ..., Z -> 26)
      const num = charCode - 64;
      // Update index based on place value (think of it like base 26)
      index = index * 26 + num;
    }
    return index;
  }

  findClassBucket(type) {
    let buckets =
      this.reportCompilerState.inputFilesArrays.questions.classBuckets;
    for (let i = 0; i < buckets.length; i++) {
      if (buckets[i].type === type) {
        return buckets[i];
      }
    }
    return null;
  }

  getStandardBucketName(lowerCase) {
    let buckets =
      this.reportCompilerState.inputFilesArrays.questions.classBuckets;
    let result = buckets.find(
      (session) => session.isStandardSessionType === true
    );
    let bucketName = result ? result.type : "Group";
    if (lowerCase) {
      return bucketName.charAt(0).toLowerCase() + bucketName.slice(1);
    }
    return bucketName;
  }

  // findBucketFromString(value){
  //   let buckets = this.reportCompilerState.inputFilesArrays.questions.classBuckets;
  //   let result = buckets.find(session => value === session.type);
  //   if(!result){
  //     result = buckets.find(session => session.isStandardSessionType === true);
  //   }
  //   return result;
  // }

  findBucketFromString(value) {
    let buckets =
      this.reportCompilerState.inputFilesArrays.questions.classBuckets;
    // Get all matches instead of just the first
    let matches = buckets.filter((session) => value.includes(session.type));

    // If more than one match, find the most similar (the longest match)
    if (matches.length > 1) {
      matches.sort((a, b) => b.type.length - a.type.length); // Sort by length of type descending
      return matches[0]; // The first element is the most similar match
    } else if (matches.length === 1) {
      return matches[0]; // Only one match found
    } else {
      // No matches found, return the standard session type
      return buckets.find((session) => session.isStandardSessionType === true);
    }
  }

  getBaseRate(staffObj, location, rateName) {
    let baseRateZero = staffObj.getRate(location, rateName)[0];
    let baseRateOne = staffObj.getRate(location, rateName)[1];
    let baseRateTwo = staffObj.getRate(location, rateName)[2];
    if (
      parseFloat(baseRateZero) === parseFloat(baseRateOne) ||
      parseFloat(baseRateZero) === 0 ||
      parseFloat(baseRateOne) +
      (parseFloat(baseRateOne) - parseFloat(baseRateZero)) !==
      parseFloat(baseRateTwo)
    ) {
      return baseRateOne;
    } else {
      return baseRateZero;
    }
  }

  indexToLetter(index) {
    const base = "A".charCodeAt(0);
    const letter = String.fromCharCode(base + index);
    return letter;
  }

  generateSumFormulas(cellValues) {
    const formula = "SUM(" + cellValues.join(", ") + ")";
    return formula;
  }

  mostCommonDifference(array) {
    if (array.length <= 1) return 0;

    array.sort((a, b) => a - b);
    let differences = new Map();

    for (let i = 1; i < array.length; i++) {
      let difference = array[i] - array[i - 1];
      if (difference === 0) continue;
      if (differences.has(difference)) {
        differences.set(difference, differences.get(difference) + 1);
      } else {
        differences.set(difference, 1);
      }
    }

    let mostCommonDifference = 0;
    let maxCount = 0;
    for (let [difference, count] of differences.entries()) {
      if (count > maxCount) {
        maxCount = count;
        mostCommonDifference = difference;
      }
    }

    return mostCommonDifference;
  }

  findClass(location, staffName, date, time) {
    for (
      let i = 0;
      i < this.reportCompilerState.studiosInformation.classes.length;
      i++
    ) {
      let tempClass = this.reportCompilerState.studiosInformation.classes[i];
      let staffObject = this.findStaffObject(
        staffName,
        this.reportCompilerState
      );

      let staffMatch = staffObject.isNamed(tempClass.instructor);
      let locationMatch = location === tempClass.location;

      let dateStr = tempClass.date.toDateString();
      let dateMatch = date === dateStr;

      let timeStr = tempClass.getClassTime();
      let timeMatch = time === timeStr;
      if (staffMatch && locationMatch && dateMatch && timeMatch) {
        //return tempClass.sessionLength;
        return tempClass;
      }
    }
    return null;
  }

  findStaffObject(name) {
    let staffArray = this.reportCompilerState.studiosInformation.staffArray;
    for (let i = 0; i < staffArray.length; i++) {
      if (staffArray[i].isNamed(name)) {
        return staffArray[i];
      }
    }
    return null;
  }

  findExcelTab(wb, staffName) {
    return wb.Sheets[staffName];
  }

  findColumnIndex(columnName) {
    let processorSetup =
      this.reportCompilerState.inputFilesArrays.questions.processorConfig
        .columns;
    for (let i = 0; i < processorSetup.length; i++) {
      if (processorSetup[i].name === columnName) {
        return i;
      }
    }
    return -1;
  }

  findCellReference(wb, staffWS, excelAttribute, processorRow) {
    let primaryLookupColumn = excelAttribute.primaryLookupColumn;
    let primaryValues = excelAttribute.primaryValues;

    let secondaryLookupColumn = excelAttribute.secondaryLookupColumn;
    let secondaryValues = excelAttribute.secondaryValues;

    let truePrimaryValues = this.lookupProcessorCellValues(
      primaryValues,
      processorRow
    );
    let trueSecondaryValues = this.lookupProcessorCellValues(
      secondaryValues,
      processorRow
    );

    let valueColumn = excelAttribute.valueColumn;

    let cells = [];

    // Iterate through excel
    let range = XLSX.utils.decode_range(staffWS["!ref"]);
    for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
      let nextCell =
        staffWS[
        XLSX.utils.encode_cell({
          r: rowNum,
          c: parseInt(primaryLookupColumn),
        })
        ];
      let primaryCellValue = null;
      if (nextCell !== undefined) {
        primaryCellValue = this.findValueFromNextCell(wb, nextCell);
      }

      let secondaryCellValue = null;
      if (secondaryLookupColumn !== null) {
        let secondNextCell =
          staffWS[
          XLSX.utils.encode_cell({
            r: rowNum,
            c: parseInt(secondaryLookupColumn),
          })
          ];
        if (secondNextCell !== undefined) {
          secondaryCellValue = this.findValueFromNextCell(wb, secondNextCell);
        }
      }

      if (primaryCellValue !== null) {
        for (let i = 0; i < truePrimaryValues.length; i++) {
          if (
            this.checkValueEquivalence(primaryCellValue, truePrimaryValues[i])
          ) {
            //if(primaryCellValue.includes(truePrimaryValues[i])){
            if (secondaryCellValue !== null) {
              for (let x = 0; x < trueSecondaryValues.length; x++) {
                if (
                  this.checkValueEquivalence(
                    secondaryCellValue,
                    trueSecondaryValues[x]
                  )
                ) {
                  //if(secondaryCellValue.includes(trueSecondaryValues[x])){
                  let cellStr = XLSX.utils.encode_cell({
                    r: rowNum,
                    c: parseInt(valueColumn),
                  });
                  if (!cells.includes(cellStr)) {
                    cells.push(cellStr);
                  }
                }
              }
            } else {
              let cellStr = XLSX.utils.encode_cell({
                r: rowNum,
                c: parseInt(valueColumn),
              });
              if (!cells.includes(cellStr)) {
                cells.push(cellStr);
              }
              //cells.push(XLSX.utils.encode_cell({r: rowNum, c: parseInt(valueColumn)}));
            }
          }
        }
      }
    }
    return cells;
  }

  lookupProcessorCellValues(values, row) {
    let worksheet = this.nonFormulaSheetCopy;
    let cellValues = [...values];
    for (let i = 0; i < values.length; i++) {
      if (values[i].includes("|")) {
        let columnName = values[i].substring(1, values[i].length - 1);
        let columnIndex = this.findColumnIndex(columnName);
        let value =
          worksheet[XLSX.utils.encode_cell({ r: row, c: columnIndex })];
        if (value !== undefined) {
          cellValues[i] = value.v;
        }
      }
    }
    return cellValues;
  }

  newlookupProcessorCellValues(values, row) {
    let worksheet = this.nonFormulaSheetCopy;
    let cellValues = values.map((value) => {
      // Regular expression to match |column_name|
      const regex = /\|([^|]+)\|/g;
      let modifiedValue = value;

      let match;
      while ((match = regex.exec(value)) !== null) {
        // Extract column name from the match
        let columnName = match[1];
        let columnIndex = this.findColumnIndex(columnName);

        // Retrieve the value from the worksheet
        let cell =
          worksheet[XLSX.utils.encode_cell({ r: row, c: columnIndex })];
        let cellValue = cell !== undefined ? cell.v : "";

        // Replace the matched pattern with the cell value
        modifiedValue = modifiedValue.replace(match[0], cellValue);
      }

      return modifiedValue;
    });

    return cellValues;
  }

  findValueFromNextCell(wb, cell) {
    if (cell.f === undefined) {
      return cell.v;
    }
    let formulaStr = cell.f;
    let firstQuoteIndex = formulaStr.indexOf("'");
    let secondQuoteIndex = formulaStr.indexOf("'", firstQuoteIndex + 1);
    let tabName = formulaStr.substring(firstQuoteIndex + 1, secondQuoteIndex);
    let sheet = wb.Sheets[tabName];
    let cellRef = formulaStr.substring(formulaStr.indexOf("!") + 1);

    let ampersandCount = (formulaStr.match(/&/g) || []).length;
    if (ampersandCount >= 2) {
      // Extract cell references and additional strings
      let parts = formulaStr.split("&").map(part => part.trim());
      let resultStr = "";

      for (let part of parts) {
        if (part.includes("'")) {
          let cellRef = part.substring(part.indexOf("!") + 1).replace(/[^\w]/g, '');
          try {
            resultStr += sheet[cellRef].v;
          } catch (e) {
            return null;
          }
        } else {
          resultStr += part.replace(/["']/g, '');
        }
      }
    return resultStr; // If not more than two '&', return the cell value
    }

    let addOn = "";
    if (formulaStr.includes("&")) {
      addOn = formulaStr.substring(formulaStr.indexOf("&") + 2);
      cellRef = cellRef.substring(0, cellRef.indexOf("&"));
      if (cellRef[cellRef.length - 1] === " ") {
        cellRef = cellRef.substring(0, cellRef.length - 1);
      }
    }

    try {
      return sheet[cellRef].v + addOn;
    } catch (e) {
      return null;
    }
  }

  createCellReferenceFormula(cellReferences, staffName) {
    let formula = "SUM(";
    for (let i = 0; i < cellReferences.length; i++) {
      formula += "'" + staffName + "'!" + cellReferences[i] + ",";
    }
    return "ROUND(" + formula.substring(0, formula.length - 1) + "), 2)";
  }

  createCustomCellReferenceFormula(
    cellReferences,
    staffName,
    customFormula,
    value
  ) {
    let cells = "";
    for (let i = 0; i < cellReferences.length; i++) {
      cells += "'" + staffName + "'!" + value + cellReferences[i] + ",";
    }
    cells = cells.substring(0, cells.length - 1);
    let outputFormula = customFormula.replace(/_(.*?)_/g, cells);
    return outputFormula;
  }

  createCellCountFormula(cellReferences, staffName) {
    let formula = "COUNTA(";
    for (let i = 0; i < cellReferences.length; i++) {
      formula += "'" + staffName + "'!" + cellReferences[i] + ",";
    }
    return formula.substring(0, formula.length - 1) + ")";
  }

  cleanTab(processorTab) {
    let removeEmptyRows =
      this.reportCompilerState.inputFilesArrays.questions.processorConfig
        .deleteEmptyRows;

    if (removeEmptyRows) {
      this.removeEmpties(processorTab);
    }

    let replacements =
      this.reportCompilerState.inputFilesArrays.questions.processorConfig
        .replacements;
    if (replacements !== undefined) {
      for (let i = 0; i < replacements.length; i++) {
        processorTab = this.replaceValue(
          processorTab,
          replacements[i].value,
          replacements[i].replacement
        );
      }
    }

    return processorTab;
  }

  // removeEmpties(tab) {
  //   let emptyRows = this.findEmptyRows(tab);
  //   for (let i = 0; i < emptyRows.length; i++) {
  //     var variable = XLSX.utils.decode_range(tab["!ref"]);
  //     let row_index = emptyRows[i] - i;
  //     for (var R = row_index; R < variable.e.r; ++R) {
  //       for (var C = variable.s.c; C <= variable.e.c; ++C) {
  //         tab[ExcelOutput.ec(R, C)] = tab[ExcelOutput.ec(R + 1, C)];
  //       }
  //     }
  //     variable.e.r--;
  //     tab["!ref"] = XLSX.utils.encode_range(variable.s, variable.e);
  //   }
  // }

  removeEmpties(tab) {
    let emptyRows = this.findEmptyRows(tab);

    for (let i = 0; i < emptyRows.length; i++) {
      var variable = XLSX.utils.decode_range(tab["!ref"]);
      let row_index = emptyRows[i] - i; // Adjust row_index for each subsequent deletion

      for (var R = row_index; R < variable.e.r; ++R) {
        for (var C = variable.s.c; C <= variable.e.c; ++C) {
          tab[ExcelOutput.ec(R, C)] = tab[ExcelOutput.ec(R + 1, C)];
        }
      }
      variable.e.r--;
      tab["!ref"] = XLSX.utils.encode_range(variable.s, variable.e);
    }

    // Update formulas after removing empty rows
    this.updateFormulas(tab);
}

updateFormulas(tab) {
    var variable = XLSX.utils.decode_range(tab["!ref"]);

    for (var R = variable.s.r; R <= variable.e.r; ++R) {
      for (var C = variable.s.c; C <= variable.e.c; ++C) {
        let cell = tab[ExcelOutput.ec(R, C)];
        if (cell && cell.f) {

          let currentRow = R+1;
          // Update the formula to adjust for deleted rows
          cell.f = this.adjustFormula(cell.f, currentRow);
        }
      }
    }
}

adjustFormula(formula, currentRow) {
  // Use a regular expression to find all local row references in the formula
  return formula.replace(/(?<![!])([A-Z]+)(\d+)/g, (match, col, originalRow) => {
    return `${col}${currentRow}`;
  });
}
  

  addHeaderRow(ws, header) {
    // Ensure the worksheet has a '!ref' (range), if not, establish a base range
    if (!ws['!ref']) {
        ws['!ref'] = 'A1:A1';
    }

    // Decode the current range of the worksheet
    const range = XLSX.utils.decode_range(ws['!ref']);

    // Adjust the range to accommodate the new row at the top
    range.e.r += 1; // Extend the range by one row at the end

    // Iterate over each cell from the bottom right to top left to avoid overwriting cells
    for (let R = range.e.r; R > 0; R--) {
        for (let C = range.s.c; C <= range.e.c; C++) {
            const targetCellRef = XLSX.utils.encode_cell({ r: R, c: C });
            const sourceCellRef = XLSX.utils.encode_cell({ r: R - 1, c: C });

            // If the source cell exists, copy it to the target cell location
            if (ws[sourceCellRef]) {
                ws[targetCellRef] = ws[sourceCellRef];
            } else {
                // Ensure any undefined cell in the new row is cleared if previously existed
                if (ws[targetCellRef]) delete ws[targetCellRef];
            }
        }
    }

    // Now set the value of A1 to "header"
    ws['A1'] = { v: header, t: "s" }; // Set the value and type of cell A1

    //delete all first row values except for A1
    for (let C = range.s.c + 1; C <= range.e.c; C++) {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
      delete ws[cellAddress];
    }

    // Update the worksheet range to include the new row
    ws['!ref'] = XLSX.utils.encode_range(range);
}


  addHeaderToSheet(ws, header) {
    // Assuming `ws` is your worksheet object and it's already defined

    // Ensure the worksheet has a '!ref' (range), initialize if necessary
    if (!ws['!ref']) {
        ws['!ref'] = 'A1:A1';
    }
    const range = XLSX.utils.decode_range(ws['!ref']);
    
    // Extend the range to cover the first row if not already included
    range.e.r = Math.max(range.e.r, 0); // Ensure the first row is included
    
    // Set header in the first cell of the first row (A1)
    const headerCellAddress = XLSX.utils.encode_cell({r: 0, c: 0});
    ws[headerCellAddress] = {v: header, t: "s"};
    
    // Iterate over the remaining columns in the first row and clear them
    for(let C = 1; C <= range.e.c; ++C) { // Start from second column (C=1)
        const cellAddress = XLSX.utils.encode_cell({r: 0, c: C});
        
        // Create or clear the cell
        ws[cellAddress] = {v: null, t: "z"}; // Setting type 'z' to indicate blank cell
        delete ws[cellAddress].f; // Remove formula if exists
    }

    // Update the worksheet's '!ref' to reflect potential changes to the range
    ws['!ref'] = XLSX.utils.encode_range(range);
}

  insert_rows(ws/*:WorkSheet*/, start_row/*:number*/, nrows/*:number*/, opts) {
    var crefregex = /(^|[^._A-Z0-9])([$]?)([A-Z]{1,2}|[A-W][A-Z]{2}|X[A-E][A-Z]|XF[A-D])([$]?)([1-9]\d{0,5}|10[0-3]\d{4}|104[0-7]\d{3}|1048[0-4]\d{2}|10485[0-6]\d|104857[0-6])(?![_.\(A-Za-z0-9])/g;
    function dup(obj) { return JSON.parse(JSON.stringify(obj)); }

    function clamp_range(range) {
      if(range.e.r >= (1<<20)) range.e.r = (1<<20)-1;
      if(range.e.c >= (1<<14)) range.e.c = (1<<14)-1;
      return range;
    }

    if(!ws) throw new Error("operation expects a worksheet");
    var dense = Array.isArray(ws);
    if(!nrows) nrows = 1;
    if(!start_row) start_row = 0;
    if(!opts) opts = {};
  
    /* extract original range */
    var range = XLSX.utils.decode_range(ws["!ref"]);
    var R = 0, C = 0;
  
    var formula_cb = function($0, $1, $2, $3, $4, $5) {
      var _R = XLSX.utils.decode_row($5), _C = XLSX.utils.decode_col($3);
      if(!opts.fill ? (_R >= start_row) : (R >= start_row)) _R += nrows;
      return $1+($2=="$" ? $2+$3 : XLSX.utils.encode_col(_C))+($4=="$" ? $4+$5 : XLSX.utils.encode_row(_R));
    };
  
    var addr, naddr, newcell;
    /* move cells and update formulae */
    if(dense) {
      /* cells after the insert */
      for(R = range.e.r; R >= start_row; --R) {
        if(ws[R]) ws[R].forEach(function(cell) { if(cell.f) cell.f = cell.f.replace(crefregex, formula_cb); });
        ws[R+nrows] = ws[R];
      }
  
      /* TODO: dense mode; newly created space */
      for(R = start_row; R < start_row + nrows; ++R) ws[R] = [];
  
      /* cells before insert */
      for(R = 0; R < start_row; ++R) {
        if(ws[R]) ws[R].forEach(function(cell) { if(cell.f) cell.f = cell.f.replace(crefregex, formula_cb); });
      }
      range.e.r += nrows;
    } else {
      /* cells after the insert */
      for(R = range.e.r; R >= start_row; --R) {
        for(C = range.s.c; C <= range.e.c; ++C) {
          addr = XLSX.utils.encode_cell({r:R, c:C});
          naddr = XLSX.utils.encode_cell({r:R+nrows, c:C});
          if(!ws[addr]) { delete ws[naddr]; continue; }
          if(opts.fill && (ws[addr].s || ws[addr].f)) {
            newcell = {};
            if(ws[addr].s) newcell.s = dup(ws[addr].s);
            if(ws[addr].f) { newcell.f = ws[addr].f; newcell.t = ws[addr].t; }
            else { newcell.t = "z"; }
          }
          if(ws[addr].f) ws[addr].f = ws[addr].f.replace(crefregex, formula_cb);
          ws[naddr] = ws[addr];
          if(opts.fill) { ws[addr] = newcell; console.log(ws[addr], newcell); }
          if(range.e.r < R + nrows) range.e.r = R + nrows;
        }
      }
  
      /* newly created space */
      if(!opts.fill) for(R = start_row; R < start_row + nrows; ++R) {
        for(C = range.s.c; C <= range.e.c; ++C) {
          addr = XLSX.utils.encode_cell({r:R, c:C});
          delete ws[addr];
        }
      }
  
      /* cells before insert */
      for(R = 0; R < start_row; ++R) {
        for(C = range.s.c; C <= range.e.c; ++C) {
          addr = XLSX.utils.encode_cell({r:R, c:C});
          if(ws[addr] && ws[addr].f) ws[addr].f = ws[addr].f.replace(crefregex, formula_cb);
        }
      }
    }
  
    /* write new range */
    ws["!ref"] = XLSX.utils.encode_range(clamp_range(range));
  
    /* merge cells */
    if(ws["!merges"]) ws["!merges"].forEach(function(merge, idx) {
      var mergerange;
      switch(typeof merge) {
        case 'string': mergerange = XLSX.utils.decode_range(merge); break;
        case 'object': mergerange = merge; break;
        default: throw new Error("Unexpected merge ref " + merge);
      }
      if(mergerange.s.r >= start_row) mergerange.s.r += nrows;
      if(mergerange.e.r >= start_row) mergerange.e.r += nrows;
      clamp_range(mergerange);
      ws["!merges"][idx] = mergerange;
    });
  
    /* rows TODO: fill */
    var rowload = [start_row, 0];
    for(R = 0; R < nrows; ++R) rowload.push(void 0);
    if(ws["!rows"]) ws["!rows"].splice.apply(ws["!rows"], rowload);
  
    /* tables */
    if(ws["!tables"]) ws["!tables"].forEach(function(tbl) {
      var tblrange;
      switch(typeof tbl.ref) {
        case 'string': tblrange = XLSX.utils.decode_range(tbl.ref); break;
        case 'object': tblrange = tbl.ref; break;
        default: throw new Error("Unexpected table ref " + tbl.ref);
      }
      if(tblrange.s.r >= start_row) tblrange.s.r += nrows;
      if(tblrange.e.r >= start_row) tblrange.e.r += nrows;
      clamp_range(tblrange);
      tbl.ref = typeof tbl.ref == 'string' ? XLSX.utils.encode_range(tblrange) : tblrange;
    });
  
    /* data validations TODO: update formulae */
    if(ws["!validations"]) ws["!validations"].forEach(function(dv) {
      var dvrange;
      switch(typeof dv.ref) {
        case 'string': dvrange = XLSX.utils.decode_range(dv.ref); break;
        case 'object': dvrange = dv.ref; break;
        default: throw new Error("Unexpected DV ref " + dv.ref);
      }
      if(dvrange.s.r >= start_row) dvrange.s.r += nrows;
      if(dvrange.e.r >= start_row) dvrange.e.r += nrows;
      clamp_range(dvrange);
      dv.ref = typeof dv.ref == 'string' ? XLSX.utils.encode_range(dvrange) : dvrange;
    });
  
    /* conditional formats TODO: update formulae */
    if(ws["!condfmt"]) ws["!condfmt"].forEach(function(cf) {
      var cfrange;
      switch(typeof cf.ref) {
        case 'string': cfrange = XLSX.utils.decode_range(cf.ref); break;
        case 'object': cfrange = cf.ref; break;
        default: throw new Error("Unexpected CF ref " + cf.ref);
      }
      if(cfrange.s.r >= start_row) cfrange.s.r += nrows;
      if(cfrange.e.r >= start_row) cfrange.e.r += nrows;
      clamp_range(cfrange);
      cf.ref = typeof cf.ref == 'string' ? XLSX.utils.encode_range(cfrange) : cfrange;
    });
  }
  

  // removeEmpties(tab) {
  //   const emptyRows = this.findEmptyRows(tab);
  //   const variable = XLSX.utils.decode_range(tab["!ref"]);
  //   const { s, e } = variable;

  //   for (let row_index of emptyRows) {
  //     for (let R = row_index; R < e.r; ++R) {
  //       for (let C = s.c; C <= e.c; ++C) {
  //         tab[ExcelOutput.ec(R, C)] = tab[ExcelOutput.ec(R + 1, C)];
  //       }
  //     }

  //     e.r--;
  //     tab["!ref"] = XLSX.utils.encode_range(s, e);
  //   }
  // }

  static ec(r, c) {
    return XLSX.utils.encode_cell({ r: r, c: c });
  }

  findEmptyRows(tab) {
    let emptyIndexes = [];
    var rowNum;
    var colNum;
    var range = XLSX.utils.decode_range(tab["!ref"]);

    for (rowNum = range.s.r + 1; rowNum <= range.e.r; rowNum++) {
      let empty = true;

      for (colNum = range.s.c; colNum <= range.e.c; colNum++) {
        var nextCell = tab[XLSX.utils.encode_cell({ r: rowNum, c: colNum })];

        if (
          nextCell !== undefined &&
          nextCell.f !== undefined &&
          nextCell.f.length > 0 &&
          !nextCell.f.includes("IF(TRUE") &&
          !(nextCell.f.includes('IFERROR'))
        ) {
          empty = false;
          //break;  // Exit the inner loop as soon as a non-empty cell is found
        } else{
          //console.log("Formula: " + nextCell.f + " " + nextCell.f?.toLowerCase());
        }
      }

      if (empty) {
        emptyIndexes.push(rowNum);
      }
    }

    return emptyIndexes;
  }

  replaceValue(tab, value, replacement) {
    var rowNum;
    var colNum;
    var range = XLSX.utils.decode_range(tab["!ref"]);
    for (rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
      for (colNum = range.s.c; colNum <= range.e.c; colNum++) {
        var nextCell = tab[XLSX.utils.encode_cell({ r: rowNum, c: colNum })];
        if (typeof nextCell === "undefined") {
          //Do nothing
        } else {
          if (nextCell.v === value) {
            tab[XLSX.utils.encode_cell({ r: rowNum, c: colNum })].v =
              replacement;
          }
        }
      }
    }
    return tab;
  }

  /**** CREATE SCHEDULE TAB ****/
  createScheduleTabTemplate(increment) {
    //find all employees who had time
    let staff = this.reportCompilerState.studiosInformation.staffArray;
    let onlyShowPaidHoursSchedule = this.reportCompilerState.inputFilesArrays.questions.onlyShowPaidHoursSchedule.value;
    let worksheetArray = [];

    for (let i = 0; i < staff.length; i++) {
      let staffTime = this.getEmployeesTimeDetail(staff[i]);
      let classes = this.getEmployeesClasses(staff[i]);
      if (staffTime.length === 0) {
        continue;
      }

      let timeSpread = this.getTimeSpread(staffTime, classes, increment);
      if (timeSpread === null || timeSpread.length <= 1) {
        continue;
      }
      let daySpread = this.getDaySpread(staffTime);
      if (daySpread.length === 0) {
        continue;
      }

      worksheetArray.push([staff[i].primaryName + "*NAME*"]);
      worksheetArray.push([""]);
      let dates = [""];
      let descriptions = [""];
      for (let d = 0; d < daySpread.length; d++) {
        dates.push(daySpread[d]);
        dates.push("");

        descriptions.push("Time");
        descriptions.push("Sessions");
      }
      worksheetArray.push(dates);
      worksheetArray.push(descriptions);
      worksheetArray.push(["*blank space*"]);

      for (let j = 0; j < timeSpread.length; j++) {
        let row = [];
        let currentTime;
        try {
          currentTime = this.timeStringToDate(timeSpread[j]);
        } catch (e) {
          console.log(e);
          continue;
        }

        row.push(timeSpread[j]);
        for (let k = 0; k < daySpread.length; k++) {
          // let withinTimeHours = 0;
          // let withinTimeHoursPreviousClockOut;
          let output;
          let cellTimeInputs = [];
          for (let m = 0; m < staffTime.length; m++) {
            let noClockOut = true;
            let dateValue = this.findDateValues(staffTime[m].description);
            let timeValues = this.findClockValues(staffTime[m].description);

            let timeIn;
            try {
              timeIn = this.timeStringToDate(timeValues[0]);
            } catch (e) {
              console.log(e);
              continue;
            }

            // Create a new Date object for timeOut based on timeIn
            let timeOut;
            try {
              timeOut = new Date(timeIn.getTime());
            } catch (e) {
              console.log(e);
              continue;
            }

            // Increment the minutes of timeOut
            timeOut.setMinutes(timeOut.getMinutes() + increment);

            if (timeValues.length > 1) {
              timeOut = this.timeStringToDate(timeValues[1]);
              noClockOut = false;
            }

            if (dateValue[0] === daySpread[k]) {
              output = this.isWithinWorkingHours(
                currentTime,
                timeIn,
                timeOut,
                increment
              );
              if (output.within) {
                output.type = noClockOut ? "no-clockout" : "clockin";
                output.rate = staffTime[m].payRate;
                output.clockIn = timeIn;
                output.clockout = timeOut;
                if (!onlyShowPaidHoursSchedule || parseFloat(staffTime[m].payRate) > 0) {
                  cellTimeInputs.push(output);
                }
                // if(withinTimeHours > 0 && withinTimeHoursPreviousClockOut && timeIn.getTime() < withinTimeHoursPreviousClockOut.getTime()){
                //   output.type = "multiple-clockins";
                //   if(noClockOut){
                //     output.type = "multiple-clockins-no-clockout";
                //   }
                // }else{
                //   output.type = "clockin";
                //   if(noClockOut){
                //     output.type = "no-clockout";
                //   }
                // }

                // withinTimeHoursPreviousClockOut = timeOut;
                // withinTimeHours += 1;
                //break;
              }
            }
          }
          if (cellTimeInputs.length === 0) {
            row.push("");
          } else if (cellTimeInputs.length === 1) {
            row.push(JSON.stringify(cellTimeInputs[0]));
          } else {
            let halfClocks = 0;
            for (let i = 0; i < cellTimeInputs.length; i++) {
              halfClocks += cellTimeInputs[i].percentage;
            }
            if (halfClocks > 1) {
              cellTimeInputs[0].type = "multiple-clockins";
            }
            row.push(JSON.stringify(cellTimeInputs[0]));
          }

          //let withinClassHours = false;
          let cellClassInputs = [];
          for (let c = 0; c < classes.length; c++) {
            let [classStartDate, classEndDate] = this.getClassStartEnd(
              classes[c]
            );
            if (this.formatDate(classes[c].date) === daySpread[k]) {
              let output = this.isWithinWorkingHours(
                currentTime,
                classStartDate,
                classEndDate,
                increment
              );
              if (output.within) {
                if (
                  parseFloat(classes[c].pay) > 0 
                  // &&
                  // parseFloat(classes[c].attendeeCount) > 0
                ) {
                  output.type = "session";
                  output.rate = classes[c].pay;
                  cellClassInputs.push(output);
                } else {
                  output.type = "cancelled-session";
                  output.rate = 0;
                  cellClassInputs.push(output);
                }
              }
            }
          }
          if (cellClassInputs.length === 0) {
            row.push("");
          } else if (cellClassInputs.length === 1) {
            row.push(JSON.stringify(cellClassInputs[0]));
          } else {
            let validSessions = [];
            for(let i = 0; i < cellClassInputs.length; i++){
              if(cellClassInputs[i].rate > 0){
                validSessions.push(cellClassInputs[i]);
              }
            }
            if(validSessions.length === 0){
              row.push(JSON.stringify(cellClassInputs[0]));
            }else if(validSessions.length === 1){
              row.push(JSON.stringify(validSessions[0]));
            }else{
              validSessions[0].type = "multiple-sessions";
              row.push(JSON.stringify(validSessions[0]));
            }
          }
        }
        worksheetArray.push(row);
      }

      worksheetArray.push([""]);
      worksheetArray.push([""]);
    }

    let xlsxWorsheet = XLSX.utils.aoa_to_sheet(worksheetArray);

    return xlsxWorsheet;
  }

  isWithinWorkingHours(current, clockIn, clockOut, increment) {
    if (current >= clockIn && current <= clockOut) {
      return { within: true, percentage: 1 };
    } else if (current < clockIn || current > clockOut) {
      return { within: false, percentage: 0 };
    }

    let incrementedTime = new Date(current.getTime() + increment * 60000);
    let isGreaterThanOrEqual =
      incrementedTime >= clockIn && incrementedTime <= clockOut;
    if (isGreaterThanOrEqual) {
      let differenceInMilliseconds =
        (incrementedTime.getTime() - clockIn.getTime()) / 60000;
      return {
        within: true,
        percentage: differenceInMilliseconds / increment,
        top: true,
      };
    }
    let decrementTime = new Date(current.getTime() - increment * 60000);
    let isLessThanOrEqual =
      decrementTime <= clockOut && decrementTime >= clockIn;
    if (isLessThanOrEqual) {
      let differenceInMilliseconds =
        (clockOut.getTime() - decrementTime.getTime()) / 60000;
      return {
        within: true,
        percentage: differenceInMilliseconds / increment,
        top: false,
      };
    }
    return { within: false, percentage: 0 };
  }

  getClassStartEnd(classObj) {
    let time = classObj.getClassTime();
    let timeStart = this.timeStringToDate(this.removeSeconds(time));
    let timeEnd = new Date(
      timeStart.getTime() + classObj.sessionLength * 60000
    );
    return [timeStart, timeEnd];
  }

  removeSeconds(timeString) {
    // Remove the last 6 characters (including ':00 ' and AM/PM)
    return timeString.slice(0, -6) + timeString.slice(-3);
  }

  formatDate(date) {
    let day = date.getDate();
    let month = date.getMonth() + 1; // getMonth() returns month from 0-11
    let year = date.getFullYear();

    // No need to add leading zero
    return `${month}/${day}/${year}`;
  }

  getEmployeesTimeDetail(employee) {
    let time = this.reportCompilerState.inputFilesArrays.TIME;

    let timeFound = [];

    for (let i = 0; i < time.length; i++) {
      if (employee.isNamed(time[i].staffName)) {
        if (this.hasDetailedTime(time[i])) {
          timeFound.push(time[i]);
        }
      }
    }

    return timeFound;
  }

  hasDetailedTime(timeEvent) {
    let clock_found = this.findClockValues(timeEvent.description);
    let date_found = this.findDateValues(timeEvent.description);

    return date_found && clock_found !== null && clock_found.length > 0;
  }

  getEmployeesClasses(employee) {
    let classes = this.reportCompilerState.studiosInformation.classes;
    let classesFound = [];
    for (let i = 0; i < classes.length; i++) {
      if (employee.isNamed(classes[i].instructor)) {
        classesFound.push(classes[i]);
      }
    }
    return classesFound;
  }

  getTimeSpread(time, classes, increment) {
    let timeEntry = this.parseTimeEntry(time[0]);
    let firstTime = timeEntry.timeIn;
    let lastTime = timeEntry.timeOut;

    for (let i = 0; i < time.length; i++) {
      timeEntry = this.parseTimeEntry(time[i]);
      if (timeEntry.timeIn && this.compareTimes(timeEntry.timeIn, firstTime)) {
        firstTime = timeEntry.timeIn;
      }
      try {
        if (timeEntry.timeOut && this.compareTimes(lastTime, timeEntry.timeOut)) {
          lastTime = timeEntry.timeOut;
        }
      } catch (e) {
        console.log(e);
        // console.log(timeEntry.timeOut);
      }

    }

    for (let j = 0; j < classes.length; j++) {
      try {
        let [classStartDate, classEndDate] = this.getClassStartEnd(classes[j]);
        let classStart = this.formatDateToString(classStartDate);
        let classEnd = this.formatDateToString(classEndDate);

        if (this.compareTimes(classStart, firstTime)) {
          firstTime = classStart;
        }
        if (this.compareTimes(lastTime, classEnd)) {
          lastTime = classEnd;
        }
      } catch (e) {
        console.log(e);
      }
    }

    let timeIncrements = this.getTimeIncrements(firstTime, lastTime, increment);

    return timeIncrements;
  }

  getTimeIncrements(startTime, endTime, increment) {
    try {
      // Helper function to convert a time string to a Date object
      function timeStringToDate(timeString) {
        const [time, period] = timeString.split(' ');
        let [hours, minutes] = time.split(':').map(Number);

        // Convert to 24-hour format
        if (period === 'PM' && hours < 12) {
          hours += 12;
        } else if (period === 'AM' && hours === 12) {
          hours = 0;
        }

        // Create a new date with the current date but with specified hours and minutes
        const date = new Date();
        date.setHours(hours, minutes, 0, 0);
        return date;
      }

      const result = [];
      let currentDate = timeStringToDate(startTime);
      const endDate = timeStringToDate(endTime);

      // Round down to the nearest 10 minutes
      currentDate.setMinutes(Math.floor(currentDate.getMinutes() / increment) * increment);

      while (currentDate <= endDate) {
        result.push(this.formatDateToString(currentDate));
        currentDate.setMinutes(currentDate.getMinutes() + increment); // Increment by 10 minutes
      }

      result.push(this.formatDateToString(currentDate));

      return result;
    } catch (e) {
      console.log(e);
      // console.log(startTime);
      // console.log(endTime);
    }
    return null;
  }

  // Helper function to format a Date object back into a time string
  formatDateToString(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12; // Hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return `${hours}:${minutes} ${ampm}`;
  }

  compareTimes(time1, time2) {
    // Helper function to check if the input is a Date object
    try {
      const isDate = (date) => date instanceof Date;

      if (time1 === null && isDate(time2)) {
        return time2;
      } else if (time2 === null && isDate(time1)) {
        return time1;
      } else if (time1 === null && time2 === null) {
        return false;
      }

      // Convert time strings to Date objects if they are not already
      const dateTime1 = isDate(time1) ? time1 : this.timeStringToDate(time1);
      const dateTime2 = isDate(time2) ? time2 : this.timeStringToDate(time2);

      // Compare the Date objects
      return dateTime1 < dateTime2;
    } catch (e) {
      console.log(e);
      // console.log(time1);
      // console.log(time2);
    }
    return false;
  }

  timeStringToDate(timeString) {
    // Create a base date, e.g., today's date
    const baseDate = new Date();

    // Extract hours and minutes from the time string
    const timeParts = timeString.match(/(\d+):(\d+) ([AP]M)/);
    let hours = parseInt(timeParts[1], 10);
    const minutes = parseInt(timeParts[2], 10);
    const ampm = timeParts[3];

    // Adjust hours for 12-hour AM/PM format
    if (ampm === "PM" && hours < 12) {
      hours += 12;
    } else if (ampm === "AM" && hours === 12) {
      hours = 0;
    }

    // Set hours and minutes to the base date
    baseDate.setHours(hours, minutes, 0, 0);

    return baseDate;
  }

  parseTimeEntry(timeEvent) {
    let entry = {
      date: null,
      timeIn: null,
      timeOut: null,
    };
    try {
      let clock_found = this.findClockValues(timeEvent.description);
      let date_found = this.findDateValues(timeEvent.description);


      if (date_found === null || clock_found === null) {
        return entry;
      }

      if (date_found.length !== 0) {
        entry.date = date_found[0];
      }

      if (clock_found.length !== 0) {
        entry.timeIn = clock_found[0];
        if (clock_found.length > 1) {
          entry.timeOut = clock_found[1];
        }
      }

      return entry;
    } catch (e) {
      console.log(e);
      //console.log(timeEvent);
    }
    return entry;
  }

  findClockValues(str) {
    // Regular expression to match time format HH:MM AM/PM
    const timeRegex = /\b\d{1,2}:\d{2} [AP]M\b/g;

    // Using match() to find all instances that match the pattern
    return str.match(timeRegex);
  }

  findDateValues(str) {
    // Regular expression to match date formats MM/DD/YYYY, MM/DD/YY, M/D/YYYY
    const dateRegex = /\b(?:\d{1,2}\/\d{1,2}\/\d{2,4})\b/g;

    // Using match() to find all instances that match the pattern
    return str.match(dateRegex);
  }

  getDaySpread(time) {
    let firstDay = this.findDateValues(time[0].description)[0];
    let daysWorked = [];
    if (firstDay !== null) {
      daysWorked.push(firstDay);
    }

    for (let i = 0; i < time.length; i++) {
      let workedDate = this.findDateValues(time[i].description)[0];
      if (workedDate && !daysWorked.includes(workedDate)) {
        daysWorked.push(workedDate);
      }
    }

    return daysWorked;
  }

  addTimeScheduleSheet(wb, worksheet) {
    // Iterate through cells to find dates and merge with the adjacent cell
    function isDate(value) {
      return value instanceof Date;
    }

    // Iterate through cells to apply logic based on cell value
    for (let cell in worksheet) {
      if (cell[0] === "!") continue; // Skip non-cell entries like '!ref'

      let cellObj = worksheet[cell];

      if (cellObj && cellObj.v === "*blank space*") {
        // Regular expression to extract the row number from the cell reference
        let rowNumber = parseInt(cell.match(/\d+$/)[0], 10);

        // Ensure the '!rows' property exists
        if (!worksheet["!rows"]) worksheet["!rows"] = [];

        // Set the height for the row
        worksheet["!rows"][rowNumber - 1] = { hpx: 5 };
        cellObj.v = "";
      }
      if (cellObj && cellObj.v.includes("*NAME*")) {
        cellObj.s = { bold: true, sz: 16 };
        cellObj.v = cellObj.v.replace("*NAME*", "");
      }
      // Check for date value
      if (isDate(cellObj.v) || cellObj.v.includes("/")) {
        // Define merge range
        let mergeRange = {
          s: {
            r: XLSX.utils.decode_cell(cell).r,
            c: XLSX.utils.decode_cell(cell).c,
          }, // start cell
          e: {
            r: XLSX.utils.decode_cell(cell).r,
            c: XLSX.utils.decode_cell(cell).c + 1,
          }, // end cell (next cell)
        };

        // Add merge range
        if (!worksheet["!merges"]) worksheet["!merges"] = [];
        worksheet["!merges"].push(mergeRange);

        let cellStyle = {
          alignment: { horizontal: "center", vertical: "center" },
          font: { bold: true },
        };

        // Apply the style to the start cell of the merge range
        let startCellRef = XLSX.utils.encode_cell(mergeRange.s);
        worksheet[startCellRef].s = cellStyle;
      }
      // Check for 't' value
      else if (cellObj.v.includes("{")) {
        let object = JSON.parse(cellObj.v);
        let color = "c9f2f8";
        if (object.type === "session") {
          color = "c9f8d0";
        } else if (object.type === "cancelled-session") {
          color = "f8c9da";
        } else if (object.type === "no-clockout") {
          color = "ED1C06";
        } else if (object.type === "multiple-clockins") {
          color = "1C2951";
        } else if (object.type === "multiple-clockins-no-clockout") {
          color = "000000";
        } else if (object.type === "multiple-sessions") {
          color = "617541";
        }
        if (object.rate !== undefined && parseFloat(object.rate) === 0) {
          //function lightenHexColor(hex, factor) {
          let factor = 0.75;
          // Ensure the factor is between 0 and 1.
          factor = Math.max(0, Math.min(1, factor));

          // Convert hex to RGB
          let r = parseInt(color.substring(0, 2), 16);
          let g = parseInt(color.substring(2, 4), 16);
          let b = parseInt(color.substring(4, 6), 16);

          // Lighten each component by moving it towards 255 by the specified factor
          r = Math.floor(r + (255 - r) * factor);
          g = Math.floor(g + (255 - g) * factor);
          b = Math.floor(b + (255 - b) * factor);

          // Convert back to hex and return
          color = ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
          //}
        }
        if (object.percentage === 1) {
          cellObj.s = { fgColor: { rgb: color } };
        } else if (object.percentage === 0) {
          cellObj.s = { fgColor: { rgb: "FFFFFF" } };
        } else {
          let gradientStops;
          if (!object.top) {
            // If object.top is true, color the top and white the bottom
            gradientStops = [
              { v: 0, rgb: color }, // Color at the top
              { v: object.percentage, rgb: color }, // Color until the specified percentage
              { v: object.percentage + 0.01, rgb: "FFFFFF" }, // Then white
              { v: 1, rgb: "FFFFFF" }, // White at the bottom
            ];
          } else {
            // If object.top is false, white the top and color the bottom
            gradientStops = [
              { v: 0, rgb: "FFFFFF" }, // White at the top
              { v: 1 - object.percentage, rgb: "FFFFFF" }, // White until the specified percentage
              { v: 1 - object.percentage + 0.01, rgb: color }, // Then color
              { v: 1, rgb: color }, // Color at the bottom
            ];
          }
          cellObj.s = {
            angle: 90, // top to bottom
            stops: gradientStops,
          };
        }
        delete cellObj.v;
      }
    }
    XLSX.utils.book_append_sheet(wb, worksheet, "Schedule (BETA)");
  }

  /**** CREATE STAFF TABS ****/

  addStaffTabs(wb) {
    const staffTabArray = [];
    this.buildStaffTabsRef(staffTabArray);
    for (let i = 0; i < staffTabArray.length; i++) {
      const instructorData = XLSX.utils.aoa_to_sheet(staffTabArray[i].slice(1));
      const staffObj = this.findStaffFromName(staffTabArray[i][0][0]);
      if (!staffObj) {
        console.warn("Staff not found: " + staffTabArray[i][0]);
      }
      this.addStaffTabReferences(instructorData, staffObj);
      XLSX.utils.book_append_sheet(wb, instructorData, staffTabArray[i][0]);
    }
  }

  buildStaffTabsRef(staffTabArray) {
    const staffArray = this.reportCompilerState.studiosInformation.staffArray;
    for (let i = 0; i < staffArray.length; i++) {
      staffTabArray.push([[staffArray[i].primaryName]]);

      let staffRefBoxSize = 0;
      for (let f = 0; f < this.detailInstructorRefBoxList.length; f++) {
        if (
          staffArray[i].isNamed(this.detailInstructorRefBoxList[f].staffName)
        ) {
          staffRefBoxSize +=
            this.detailInstructorRefBoxList[f].endingRef -
            this.detailInstructorRefBoxList[f].startingRef +
            1;
        }
      }

      for (let d = 0; d < this.commissionDetailRefBoxList.length; d++) {
        if (
          staffArray[i].isNamed(this.commissionDetailRefBoxList[d].staffName)
        ) {
          staffRefBoxSize += 1;
        }
      }

      for (let j = 0; j < staffRefBoxSize + 25; j++) {
        if (j - 6 < 0) {
          staffTabArray[staffTabArray.length - 1].push([""]);
        } else {
          staffTabArray[staffTabArray.length - 1].push([
            "",
            "",
            "",
            "",
            "",
            "",
            "",
          ]);
        }
      }
    }
  }

  addStaffTabReferences(excelSheet, staff) {
    let staffTabIndex = this.addStaffTabHeader(excelSheet, staff);

    let staffRefBox = this.findStaffReferenceBox("Class", staff);
    if (staffRefBox != null) {
      staffTabIndex = this.addStaffTabClassReferences(
        excelSheet,
        staffRefBox,
        staffTabIndex
      );
    }

    staffRefBox = this.findStaffReferenceBox("Time", staff);
    let staffRefBoxes = this.findStaffReferenceBoxes("Time", staff);

    //if (staffRefBox != null) {
    staffTabIndex = this.addStaffTabTimeDetailReferences(
      excelSheet,
      staffRefBoxes,
      staffTabIndex,
      staff
    );
    // if(staffRefBox != null){
    //   staffTabIndex = addStaffTabTimeReferences(
    //     excelSheet,
    //     staffRefBox,
    //     staffTabIndex
    //   );
    // }

    //}
    

    if (
      this.reportCompilerState.inputFilesArrays.questions.includeCommissionTabs
        .value &&
      this.hasCommission(staff) &&
      !this.reportCompilerState.inputFilesArrays.questions
        .summaryTabCommissionForIndividualTabs.value
    ) {
      staffTabIndex = this.addStaffTabCommissionReferences(
        excelSheet,
        staff,
        staffTabIndex
      );
    }

    if (
      this.reportCompilerState.inputFilesArrays.questions
        .summaryTabCommissionForIndividualTabs.value
    ) {
      staffTabIndex = this.addCommissionFromSummaryTab(
        excelSheet,
        staff,
        staffTabIndex
      );
    }

    this.addStaffTabFooter(excelSheet, staff, staffTabIndex);
  }

  addStaffTabHeader(excelSheet, staff) {
    const payPeriod = this.reportCompilerState.payrollInformation.belPayPeriods;

    excelSheet["A" + 1] = {
      t: "s",
      v: this.findStaffLocationsWorkedString(staff),
    };
    let dateRange = "??";
    try {
      dateRange =
        payPeriod[0].toDateString() + " - " + payPeriod[1].toDateString();
    } catch (e) {
      //console.log(e);
    }
    excelSheet["A" + 2] = {
      t: "s",
      v: dateRange,
    };
    excelSheet["A" + 3] = { t: "s", v: "" };
    excelSheet["A" + 4] = { t: "s", v: "" };
    excelSheet["A" + 5] = { t: "s", v: staff.getNameString() };
    excelSheet["A" + 6] = { t: "s", v: "" };
    return 7;
  }

  findStaffLocationsWorkedString(staff) {
    // const studioArray = this.reportCompilerState.studiosInformation.studiosArray; ~~~~
    const studioArray =
      this.reportCompilerState.payrollInformation.studiosInInput.sort();
    let staffLocationsWorked = "";

    for (let i = 0; i < studioArray.length; i++) {
      if (this.staffWorkedAtStudio(studioArray[i], staff)) {
        staffLocationsWorked = staffLocationsWorked + studioArray[i] + "/";
      }
    }
    if (staffLocationsWorked.length > 0) {
      return staffLocationsWorked.substring(0, staffLocationsWorked.length - 1);
    }
    return "N/A";
  }

  findStaffReferenceBox(detail, staff) {
    for (let i = 0; i < this.detailInstructorRefBoxList.length; i++) {
      if (
        staff.isNamed(this.detailInstructorRefBoxList[i].staffName) &&
        this.detailInstructorRefBoxList[i].type === detail
      ) {
        return this.detailInstructorRefBoxList[i];
      }
    }
    return null;
  }

  findStaffReferenceBoxes(detail, staff) {
    let instances = [];
    for (let i = 0; i < this.cellRefByInstructor.length; i++) {
      let cellRef = this.cellRefByInstructor[i];
      if (
        staff.isNamed(cellRef.instructor) &&
        cellRef.type === detail &&
        !cellRef.detail
      ) {
        instances.push(cellRef);
      }
    }
    return instances;
  }

  addStaffTabClassReferences(excelSheet, staffRefBox, staffTabIndex) {
    const size = staffRefBox.endingRef - staffRefBox.startingRef;
    let classDetailCount = 1;
    let sheet_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .sessionName + " Detail";
    let includesClassComments = this.reportCompilerState.inputFilesArrays.questions.commentsCarryOverToStaffTabs.includes("Class");
    let endColumn = includesClassComments ? 7 : 6;
    while (classDetailCount <= size) {
      for (let k = 0; k < endColumn; k++) {
        if (k === 5) {
          excelSheet[this.alphabet.charAt(k) + staffTabIndex] = {
            t: "s",
            f:
              "'" +
              sheet_name +
              "'!" +
              this.alphabet.charAt(k) +
              (classDetailCount + staffRefBox.startingRef),
            z: "$##,##0.00",
          };
        } else {
          excelSheet[this.alphabet.charAt(k) + staffTabIndex] = {
            t: "s",
            f:
              "'" +
              sheet_name +
              "'!" +
              this.alphabet.charAt(k) +
              (classDetailCount + staffRefBox.startingRef),
          };
        }
      }
      classDetailCount++;
      staffTabIndex++;
    }
    excelSheet["A" + staffTabIndex] = { t: "s", v: "" };
    staffTabIndex++;
    return staffTabIndex;
  }

  //  addStaffTabTimeReferences(excelSheet, staffRefBox, staffTabIndex) {
  //   let timeDetailCount = 1;
  //   excelSheet["A" + staffTabIndex] = { t: "s", v: "Time Clock" };
  //   staffTabIndex++;

  //   const size = staffRefBox.endingRef - staffRefBox.startingRef;
  //   while (timeDetailCount <= size) {
  //     excelSheet["A" + staffTabIndex] = {
  //       t: "s",
  //       f: "'Time Detail'!A" + (timeDetailCount + staffRefBox.startingRef),
  //     };
  //     excelSheet["B" + staffTabIndex] = {
  //       t: "s",
  //       f: "'Time Detail'!B" + (timeDetailCount + staffRefBox.startingRef),
  //     };
  //     excelSheet["D" + staffTabIndex] = {
  //       t: "s",
  //       f: "'Time Detail'!C" + (timeDetailCount + staffRefBox.startingRef),
  //       z: "$##,##0.00",
  //     };
  //     excelSheet["E" + staffTabIndex] = {
  //       t: "s",
  //       f: "'Time Detail'!D" + (timeDetailCount + staffRefBox.startingRef),
  //     };
  //     excelSheet["F" + staffTabIndex] = {
  //       t: "n",
  //       f: "'Time Detail'!E" + (timeDetailCount + staffRefBox.startingRef),
  //       z: "$##,##0.00",
  //     };
  //     excelSheet["G" + staffTabIndex] = {
  //       t: "s",
  //       f: "'Time Detail'!F" + (timeDetailCount + staffRefBox.startingRef),
  //     };
  //     timeDetailCount++;
  //     staffTabIndex++;
  //   }
  //   excelSheet["A" + staffTabIndex] = { t: "s", v: "" };
  //   staffTabIndex++;
  //   return staffTabIndex;
  // }

  addStaffTabTimeDetailReferences(
    excelSheet,
    staffRefBoxes,
    staffTabIndex,
    staff
  ) {
    let location_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .locationName;
    let incudeTimeComments = this.reportCompilerState.inputFilesArrays.questions.commentsCarryOverToStaffTabs.includes("Time");
    // let timeDetailCount = 1;
    let timeFormulas = this.createTimeReferenceFormula(staff);
    if (timeFormulas.length !== 0 || staffRefBoxes.length !== 0) {
      excelSheet["A" + staffTabIndex] = { t: "s", v: "Time Clock" };
      staffTabIndex++;
      excelSheet["A" + staffTabIndex] = { t: "s", v: "Type" };
      excelSheet["B" + staffTabIndex] = { t: "s", v: location_name };
      excelSheet["C" + staffTabIndex] = { t: "s", v: "" };
      excelSheet["D" + staffTabIndex] = { t: "s", v: "Pay Rate" };
      excelSheet["E" + staffTabIndex] = { t: "s", v: "Hours" };
      excelSheet["F" + staffTabIndex] = { t: "s", v: "Pay" };
      excelSheet["G" + staffTabIndex] = { t: "s", v: "Comments" };
      staffTabIndex++;
    }

    let time_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .timeName + " Detail";

    for (let i = 0; i < timeFormulas.length; i++) {
      excelSheet["A" + staffTabIndex] = {
        t: "s",
        v: timeFormulas[i][0],
      };
      excelSheet["B" + staffTabIndex] = {
        t: "s",
        v: timeFormulas[i][1],
      };
      excelSheet["C" + staffTabIndex] = {
        t: "s",
        v: "",
      };
      excelSheet["D" + staffTabIndex] = {
        t: "s",
        f: timeFormulas[i][2],
        z: "$##,##0.00",
      };
      excelSheet["E" + staffTabIndex] = {
        t: "s",
        f: timeFormulas[i][3],
      };
      excelSheet["F" + staffTabIndex] = {
        t: "n",
        f: "D" + staffTabIndex + " * E" + staffTabIndex,
        z: "$##,##0.00",
      };
      // if(incudeTimeComments){
      //   excelSheet["G" + staffTabIndex] = {
      //     t: "s",
      //     f: timeFormulas[i][4]
      //   };
      // }
      // excelSheet["G" + staffTabIndex] = {
      //   t: "s",
      //   f: "F" + staffTabIndex
      // };
      staffTabIndex++;
    }

    //if(staffRefBoxes !== null){
    for (let i = 0; i < staffRefBoxes.length; i++) {
      let staffRefBox = staffRefBoxes[i];
      excelSheet["A" + staffTabIndex] = {
        t: "s",
        f: "'" + time_name + "'!A" + staffRefBox.row,
      };
      excelSheet["B" + staffTabIndex] = {
        t: "s",
        f: "'" + time_name + "'!B" + staffRefBox.row,
      };
      excelSheet["D" + staffTabIndex] = {
        t: "s",
        f: "'" + time_name + "'!C" + staffRefBox.row,
        z: "$##,##0.00",
      };
      excelSheet["E" + staffTabIndex] = {
        t: "s",
        f: "'" + time_name + "'!D" + staffRefBox.row,
      };
      excelSheet["F" + staffTabIndex] = {
        t: "n",
        f: "'" + time_name + "'!E" + staffRefBox.row,
        z: "$##,##0.00",
      };
      if(incudeTimeComments){
        excelSheet["G" + staffTabIndex] = {
          t: "s",
          f: "'" + time_name + "'!F" + (staffRefBox.row),
        };
      }
      staffTabIndex++;
    }
    //}

    excelSheet["A" + staffTabIndex] = { t: "s", v: "" };
    staffTabIndex++;
    return staffTabIndex;
  }

  createTimeReferenceFormula(staffObj) {
    let time_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .timeName + " Detail";
    let timeFormulas = [];
    for (
      let i = 0;
      i < this.reportCompilerState.payrollInformation.studiosInInput.length;
      i++
    ) {
      let location =
        this.reportCompilerState.payrollInformation.studiosInInput[i];
      let excelRow = ["Regular Totals", location, "", ""];
      let formula = "";

      let firstRow = null;
      for (let j = 0; j < this.cellRefByInstructor.length; j++) {
        if (
          this.cellRefByInstructor[j].type === "Time" &&
          staffObj.isNamed(this.cellRefByInstructor[j].instructor) &&
          location === this.cellRefByInstructor[j].studio &&
          this.cellRefByInstructor[j].detail === true
        ) {
          excelRow[2] =
            "'" + time_name + "'!C" + this.cellRefByInstructor[j].row;
          formula =
            formula +
            "'" +
            time_name +
            "'!D" +
            this.cellRefByInstructor[j].row +
            ",";

            if(firstRow === null){
              firstRow = this.cellRefByInstructor[j].row;
            }
        }
      }
      formula = "SUM(" + formula.substring(0, formula.length - 1) + ")";
      excelRow[3] = formula;
      excelRow[4] = "'" + time_name + "'!F" + firstRow
      if (formula.length > 7) {
        timeFormulas.push(excelRow);
      }
    }
    return timeFormulas;
  }

  hasCommission(staff) {
    const agreements = this.reportCompilerState.inputFilesArrays.AGREE;
    const retail = this.reportCompilerState.inputFilesArrays.SALE;

    // this.commissionDetailRefBoxList
    for (let x = 0; x < this.commissionDetailRefBoxList.length; x++) {
      if (staff.isNamed(this.commissionDetailRefBoxList[x].staffName)) {
        return true;
      }
    }

    for (let d = 0; d < agreements.length; d++) {
      if (
        staff.isNamed(agreements[d].salespeople.PrimarySalesperson) ||
        staff.isNamed(agreements[d].salespeople.SecondarySalesperson)
      ) {
        return true;
      }
    }

    //used to eb retail[e].salseperson
    for (let e = 0; e < retail.length; e++) {
      if (
        staff.isNamed(retail[e].salespeople.PrimarySalesperson) ||
        staff.isNamed(retail[e].salespeople.SecondarySalesperson)
      ) {
        return true;
      }
    }
    return false;
  }

  addStaffTabCommissionReferences(excelSheet, staff, staffTabIndex) {
    if(this.reportCompilerState.inputFilesArrays.questions.salesBuckets && this.reportCompilerState.inputFilesArrays.questions.salesBuckets.length > 0){
      //commissionDetailWS['!autofilter'] = { ref: "A5:T5" };
      let salesTypes = this.reportCompilerState.inputFilesArrays.questions.salesBuckets;
      salesTypes.sort((a, b) => {
        if (a.type < b.type) {
          return -1;
        }
        if (a.type > b.type) {
          return 1;
        }
        return 0;
      });
      for(let i = 0; i < salesTypes.length; i++){
        staffTabIndex = this.addStaffTabsSalesCommissionReferencesAll(excelSheet, staff, staffTabIndex, salesTypes[i].name);
      }
      return staffTabIndex;
    }
    staffTabIndex = this.addStaffTabsIntroCommissionReferences(
      excelSheet,
      staff,
      staffTabIndex
    );
    staffTabIndex = this.addStaffTabsNonIntroCommissionReferences(
      excelSheet,
      staff,
      staffTabIndex
    );
    staffTabIndex = this.addStaffTabsUpgradeReferences(
      excelSheet,
      staff,
      staffTabIndex
    );
    staffTabIndex = this.addStaffTabsRetailReferences(
      excelSheet,
      staff,
      staffTabIndex
    );
    return staffTabIndex;
  }

  addStaffTabsSalesCommissionReferencesAll(excelSheet, staff, staffTabIndex, type) {
    let commission_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .commissionName + " Detail";
    let location_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .locationName;
    let member_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .memberName;
    let includeCommissionComments = this.reportCompilerState.inputFilesArrays.questions.commentsCarryOverToStaffTabs.includes("Commission");

    let commissionCount = 0;
    for (let i = 0; i < this.commissionDetailRefBoxList.length; i++) {
      if (
        staff.isNamed(this.commissionDetailRefBoxList[i].staffName) &&
        this.commissionDetailRefBoxList[i].type.includes(type) &&
        this.shouldBeDisplayedInStaffTab(this.commissionDetailRefBoxList[i].staffType, this.commissionDetailRefBoxList[i].type)
      ) {
        if (commissionCount === 0) {
          excelSheet["A" + staffTabIndex] = {
            t: "s",
            v: type + " Sales",
          };
          staffTabIndex++;
          excelSheet["A" + staffTabIndex] = { t: "s", v: "Item -- Type" };
          excelSheet["B" + staffTabIndex] = { t: "s", v: location_name };
          excelSheet["C" + staffTabIndex] = { t: "s", v: "Date" };
          excelSheet["D" + staffTabIndex] = { t: "s", v: "New " + member_name };
          excelSheet["E" + staffTabIndex] = { t: "s", v: "Payment" };
          excelSheet["F" + staffTabIndex] = { t: "s", v: "Commission" };
          excelSheet["G" + staffTabIndex] = { t: "s", v: "Comments" };
          staffTabIndex++;
        }

        let payColumn = "";
        if(this.commissionDetailRefBoxList[i].staffType === "Opened"){
          payColumn = "P";
        } else if(this.commissionDetailRefBoxList[i].staffType === "Closed"){
          payColumn = "L";
        } else if(this.commissionDetailRefBoxList[i].staffType === "Instructor"){
          payColumn = "R";
        } else if(this.commissionDetailRefBoxList[i].staffType === "Secondary"){
          payColumn = "N";
        }
        excelSheet["A" + staffTabIndex] = {
          t: "s",
          f: "'Commission Detail'!H" + this.commissionDetailRefBoxList[i].ref + 
             " & \" [\" & " +
             "'Commission Detail'!E" + this.commissionDetailRefBoxList[i].ref + 
             " & \" \" & " +
             "'Commission Detail'!F" + this.commissionDetailRefBoxList[i].ref + 
             " & \"] {\" & " +
             "'Commission Detail'!G" + this.commissionDetailRefBoxList[i].ref + 
             " & \"} -- \" & \"" + this.commissionDetailRefBoxList[i].staffType + "\""
        };

        excelSheet["B" + staffTabIndex] = {
          t: "s",
          f:
            "'" +
            commission_name +
            "'!A" +
            this.commissionDetailRefBoxList[i].ref,
        };
        excelSheet["C" + staffTabIndex] = {
          t: "s",
          f:
            "'" +
            commission_name +
            "'!B" +
            this.commissionDetailRefBoxList[i].ref,
        };
        excelSheet["D" + staffTabIndex] = {
          t: "s",
          f:
            "'" +
            commission_name +
            "'!C" +
            this.commissionDetailRefBoxList[i].ref,
        };
        excelSheet["E" + staffTabIndex] = {
          t: "s",
          f:
            "'" +
            commission_name +
            "'!D" +
            this.commissionDetailRefBoxList[i].ref,
          z: "$##,##0.00",
        };
        excelSheet["F" + staffTabIndex] = {
          t: "n",
          f:
            "'" +
            commission_name +
            "'!" + payColumn + "" +
            this.commissionDetailRefBoxList[i].ref,
          z: "$##,##0.00",
        };
          
        if(includeCommissionComments){
          excelSheet["G" + staffTabIndex] = {
            t: "s",
            f: "'" + commission_name + "'!S" + this.commissionDetailRefBoxList[i].ref,
          };
        }
        staffTabIndex++;
        commissionCount++;
      }
    }
    if (commissionCount > 0) {
      excelSheet["A" + staffTabIndex] = {
        t: "s",
        v: type + " Sales Totals",
      };
      excelSheet["F" + staffTabIndex] = {
        t: "n",
        f:
          "SUM(F" +
          (staffTabIndex - 1) +
          ":" +
          "F" +
          (staffTabIndex - commissionCount) +
          ")",
        z: "$##,##0.00",
      };
      staffTabIndex++;
      excelSheet["A" + staffTabIndex] = { t: "s", v: "" };
      staffTabIndex++;
      
      commissionCount = 0;
    }

    return staffTabIndex;
  }

  shouldBeDisplayedInStaffTab(staffType, salesType) {
    //console.log("staff type: ", staffType)
    //console.log("salesType: ", salesType)
    //unfinished
    let lastSpaceIndex = salesType.lastIndexOf(' ');
    let type = salesType.substring(0, lastSpaceIndex);
    let status = salesType.substring(lastSpaceIndex + 1);
    let rate = null;
    try{
      rate = this.reportCompilerState.inputFilesArrays.questions.salesBuckets.find(x => x.name === type).rates[status];
    } catch(e){
      // console.log(type);
      // console.log(status);

      // console.log(this.reportCompilerState.inputFilesArrays.questions.salesBuckets.find(x => x.name === type));
      
      // console.log(e);
      return false;
    }
    if(staffType === "Opened" && rate.salespeople.includes("Open")){
      return true;
    } else if(staffType === "Closed" && rate.salespeople.includes("Close")){
      return true;
    } else if((staffType === "Instructor" && rate.salespeople.includes("Instructor")) || rate.sessionConversionInstructorBonus > 0){
      return true;
    } else if(staffType === "Secondary" && rate.salespeople.includes("Second")){
      return true;
    }
    return false;
  }

  addStaffTabsIntroCommissionReferences(excelSheet, staff, staffTabIndex) {
    let commission_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .commissionName + " Detail";
    let location_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .locationName;
    let member_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .memberName;
    let includeCommissionComments = this.reportCompilerState.inputFilesArrays.questions.commentsCarryOverToStaffTabs.includes("Commission");
    let introCommissionsCount = 0;
    for (let i = 0; i < this.commissionDetailRefBoxList.length; i++) {
      if (
        staff.isNamed(this.commissionDetailRefBoxList[i].staffName) &&
        this.commissionDetailRefBoxList[i].type === "Intro"
      ) {
        if (introCommissionsCount === 0) {
          excelSheet["A" + staffTabIndex] = {
            t: "s",
            v: "New Agreements (Intros)",
          };
          staffTabIndex++;
          excelSheet["A" + staffTabIndex] = { t: "s", v: "Agreement -- Role" };
          excelSheet["B" + staffTabIndex] = { t: "s", v: location_name };
          excelSheet["C" + staffTabIndex] = { t: "s", v: "Date" };
          excelSheet["D" + staffTabIndex] = { t: "s", v: "New " + member_name };
          excelSheet["E" + staffTabIndex] = { t: "s", v: "Payment" };
          excelSheet["F" + staffTabIndex] = { t: "s", v: "Commission" };
          excelSheet["G" + staffTabIndex] = { t: "s", v: "Comments" };
          staffTabIndex++;
        }

        excelSheet["B" + staffTabIndex] = {
          t: "s",
          f:
            "'" +
            commission_name +
            "'!A" +
            this.commissionDetailRefBoxList[i].ref,
        };
        excelSheet["C" + staffTabIndex] = {
          t: "s",
          f:
            "'" +
            commission_name +
            "'!B" +
            this.commissionDetailRefBoxList[i].ref,
        };
        excelSheet["D" + staffTabIndex] = {
          t: "s",
          f:
            "'" +
            commission_name +
            "'!C" +
            this.commissionDetailRefBoxList[i].ref,
        };
        excelSheet["E" + staffTabIndex] = {
          t: "s",
          f:
            "'" +
            commission_name +
            "'!D" +
            this.commissionDetailRefBoxList[i].ref,
          z: "$##,##0.00",
        };
        const refVar =
          "'" +
          commission_name +
          "'!E" +
          this.commissionDetailRefBoxList[i].ref;
        if (this.commissionDetailRefBoxList[i].staffType === "Opened") {
          excelSheet["A" + staffTabIndex] = {
            t: "s",
            //f: 'CONCATENATE("Opened -- " & ' + refVar + ")",
            f: "CONCATENATE(" + refVar + '& " -- Opened: IA" )',
          };
          excelSheet["F" + staffTabIndex] = {
            t: "n",
            f:
              "'" +
              commission_name +
              "'!G" +
              this.commissionDetailRefBoxList[i].ref,
            z: "$##,##0.00",
          };
        } else if (this.commissionDetailRefBoxList[i].staffType === "Closed") {
          excelSheet["A" + staffTabIndex] = {
            t: "s",
            //f: 'CONCATENATE("Closed -- " & ' + refVar + ")",
            f: "CONCATENATE(" + refVar + '& " -- Closed: IA" )',
          };
          excelSheet["F" + staffTabIndex] = {
            t: "n",
            f:
              "'" +
              commission_name +
              "'!I" +
              this.commissionDetailRefBoxList[i].ref,
            z: "$##,##0.00",
          };
        } else if (
          this.commissionDetailRefBoxList[i].staffType === "Instructor"
        ) {
          excelSheet["A" + staffTabIndex] = {
            t: "s",
            //f: 'CONCATENATE("Instructor -- " & ' + refVar + ")",
            f: "CONCATENATE(" + refVar + '& " -- Instructor: IA" )',
          };
          excelSheet["F" + staffTabIndex] = {
            t: "n",
            f:
              "'" +
              commission_name +
              "'!K" +
              this.commissionDetailRefBoxList[i].ref,
            z: "$##,##0.00",
          };
        } else if (
          this.commissionDetailRefBoxList[i].staffType === "Secondary"
        ) {
          excelSheet["A" + staffTabIndex] = {
            t: "s",
            //f: 'CONCATENATE("Secondary -- " & ' + refVar + ")",
            f: "CONCATENATE(" + refVar + '& " -- Secondary: IA" )',
          };
          excelSheet["F" + staffTabIndex] = {
            t: "n",
            f:
              "'" +
              commission_name +
              "'!M" +
              this.commissionDetailRefBoxList[i].ref,
            z: "$##,##0.00",
          };
          
        }
        if(includeCommissionComments){
          excelSheet["G" + staffTabIndex] = {
            t: "s",
            f: "'" + commission_name + "'!N" + this.commissionDetailRefBoxList[i].ref,
          };
        }
        staffTabIndex++;
        introCommissionsCount++;
      }
    }

    if (introCommissionsCount > 0) {
      excelSheet["A" + staffTabIndex] = {
        t: "s",
        v: "Post-Intro Agreement Totals",
      };
      excelSheet["F" + staffTabIndex] = {
        t: "n",
        f:
          "SUM(F" +
          (staffTabIndex - 1) +
          ":" +
          "F" +
          (staffTabIndex - introCommissionsCount) +
          ")",
        z: "$##,##0.00",
      };
      staffTabIndex++;
      excelSheet["A" + staffTabIndex] = { t: "s", v: "" };
      staffTabIndex++;
    }
    return staffTabIndex;
  }

  addStaffTabsNonIntroCommissionReferences(excelSheet, staff, staffTabIndex) {
    let commission_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .commissionName + " Detail";
    let member_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .memberName;
    let location_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .locationName;
    let includeCommissionComments = this.reportCompilerState.inputFilesArrays.questions.commentsCarryOverToStaffTabs.includes("Commission");
    let nonIntroCommissionsCount = 0;
    for (let i = 0; i < this.commissionDetailRefBoxList.length; i++) {
      if (
        staff.isNamed(this.commissionDetailRefBoxList[i].staffName) &&
        this.commissionDetailRefBoxList[i].type === "Non-Intro"
      ) {
        if (nonIntroCommissionsCount === 0) {
          excelSheet["A" + staffTabIndex] = {
            t: "s",
            v: "New Agreements (Non-Intros)",
          };
          staffTabIndex++;
          excelSheet["A" + staffTabIndex] = { t: "s", v: "Agreement -- Role" };
          excelSheet["B" + staffTabIndex] = { t: "s", v: location_name };
          excelSheet["C" + staffTabIndex] = { t: "s", v: "Date" };
          excelSheet["D" + staffTabIndex] = { t: "s", v: "New " + member_name };
          excelSheet["E" + staffTabIndex] = { t: "s", v: "Payment" };
          excelSheet["F" + staffTabIndex] = { t: "s", v: "Commission" };
          excelSheet["G" + staffTabIndex] = { t: "s", v: "Comments" };
          staffTabIndex++;
        }

        excelSheet["B" + staffTabIndex] = {
          t: "s",
          f:
            "'" +
            commission_name +
            "'!A" +
            this.commissionDetailRefBoxList[i].ref,
        };
        excelSheet["C" + staffTabIndex] = {
          t: "s",
          f:
            "'" +
            commission_name +
            "'!B" +
            this.commissionDetailRefBoxList[i].ref,
        };
        excelSheet["D" + staffTabIndex] = {
          t: "s",
          f:
            "'" +
            commission_name +
            "'!C" +
            this.commissionDetailRefBoxList[i].ref,
        };
        excelSheet["E" + staffTabIndex] = {
          t: "s",
          f:
            "'" +
            commission_name +
            "'!D" +
            this.commissionDetailRefBoxList[i].ref,
          z: "$##,##0.00",
        };
        const refVar =
          "'" +
          commission_name +
          "'!E" +
          this.commissionDetailRefBoxList[i].ref;
        if (this.commissionDetailRefBoxList[i].staffType === "Closed") {
          excelSheet["A" + staffTabIndex] = {
            t: "s",
            //f: 'CONCATENATE("Closed -- " & ' + refVar + ")",
            f: "CONCATENATE(" + refVar + '& " -- Closed: NA" )',
          };
          excelSheet["F" + staffTabIndex] = {
            t: "n",
            f:
              "'" +
              commission_name +
              "'!G" +
              this.commissionDetailRefBoxList[i].ref,
            z: "$##,##0.00",
          };
        } else if (
          this.commissionDetailRefBoxList[i].staffType === "Secondary"
        ) {
          excelSheet["A" + staffTabIndex] = {
            t: "s",
            //f: 'CONCATENATE("Secondary -- " & ' + refVar + ")",
            f: "CONCATENATE(" + refVar + '& " -- Secondary: NA" )',
          };
          excelSheet["F" + staffTabIndex] = {
            t: "n",
            f:
              "'" +
              commission_name +
              "'!I" +
              this.commissionDetailRefBoxList[i].ref,
            z: "$##,##0.00",
          };
        }
        if(includeCommissionComments){
          excelSheet["G" + staffTabIndex] = {
            t: "s",
            f: "'" + commission_name + "'!J" + this.commissionDetailRefBoxList[i].ref,
          };
        }
        staffTabIndex++;
        nonIntroCommissionsCount++;
      }
    }

    if (nonIntroCommissionsCount > 0) {
      excelSheet["A" + staffTabIndex] = {
        t: "s",
        v: "Non-Intro Agreement Totals",
      };
      excelSheet["F" + staffTabIndex] = {
        t: "n",
        f:
          "SUM(F" +
          (staffTabIndex - 1) +
          ":" +
          "F" +
          (staffTabIndex - nonIntroCommissionsCount) +
          ")",
        z: "$##,##0.00",
      };
      staffTabIndex++;
      excelSheet["A" + staffTabIndex] = { t: "s", v: "" };
      staffTabIndex++;
    }
    return staffTabIndex;
  }

  addStaffTabsUpgradeReferences(excelSheet, staff, staffTabIndex) {
    let commission_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .commissionName + " Detail";
    let member_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .memberName;
    let location_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .locationName;
    
    let includeCommissionComments = this.reportCompilerState.inputFilesArrays.questions.commentsCarryOverToStaffTabs.includes("Commission");
    let upgradeCommissionsCount = 0;
    for (let i = 0; i < this.commissionDetailRefBoxList.length; i++) {
      if (
        staff.isNamed(this.commissionDetailRefBoxList[i].staffName) &&
        this.commissionDetailRefBoxList[i].type === "Upgrade-Downgrade"
      ) {
        if (upgradeCommissionsCount === 0) {
          excelSheet["A" + staffTabIndex] = { t: "s", v: "Upgrade/Downgrade" };
          staffTabIndex++;
          excelSheet["A" + staffTabIndex] = {
            t: "s",
            v: "Type -- Agreement -- Role",
          };
          excelSheet["B" + staffTabIndex] = { t: "s", v: location_name };
          excelSheet["C" + staffTabIndex] = { t: "s", v: "Date" };
          excelSheet["D" + staffTabIndex] = { t: "s", v: member_name };
          excelSheet["E" + staffTabIndex] = { t: "s", v: "Payment" };
          excelSheet["F" + staffTabIndex] = { t: "s", v: "Commission" };
          excelSheet["G" + staffTabIndex] = { t: "s", v: "Comments" };
          staffTabIndex++;
        }

        excelSheet["B" + staffTabIndex] = {
          t: "s",
          f:
            "'" +
            commission_name +
            "'!A" +
            this.commissionDetailRefBoxList[i].ref,
        };
        excelSheet["C" + staffTabIndex] = {
          t: "s",
          f:
            "'" +
            commission_name +
            "'!B" +
            this.commissionDetailRefBoxList[i].ref,
        };
        excelSheet["D" + staffTabIndex] = {
          t: "s",
          f:
            "'" +
            commission_name +
            "'!C" +
            this.commissionDetailRefBoxList[i].ref,
        };
        excelSheet["E" + staffTabIndex] = {
          t: "s",
          f:
            "'" +
            commission_name +
            "'!D" +
            this.commissionDetailRefBoxList[i].ref,
          z: "$##,##0.00",
        };

        const agreementVar =
          "'" +
          commission_name +
          "'!E" +
          this.commissionDetailRefBoxList[i].ref;
        const refVar =
          "'" +
          commission_name +
          "'!G" +
          this.commissionDetailRefBoxList[i].ref;
        const seperator = '" -- "';
        if (this.commissionDetailRefBoxList[i].staffType === "Closed") {
          excelSheet["A" + staffTabIndex] = {
            t: "s",
            f:
              "CONCATENATE(" +
              refVar +
              " & " +
              seperator +
              " & " +
              agreementVar +
              '& " -- Closed: UA" )',
          };
          excelSheet["F" + staffTabIndex] = {
            t: "s",
            f:
              "'" +
              commission_name +
              "'!I" +
              this.commissionDetailRefBoxList[i].ref,
            z: "$##,##0.00",
          };
        } else if (
          this.commissionDetailRefBoxList[i].staffType === "Secondary"
        ) {
          excelSheet["A" + staffTabIndex] = {
            t: "s",
            //f: 'CONCATENATE("Secondary -- " & ' + refVar + ")",
            f:
              "CONCATENATE(" +
              refVar +
              " & " +
              seperator +
              " & " +
              agreementVar +
              '& " -- Secondary: UA" )',
          };
          excelSheet["F" + staffTabIndex] = {
            t: "s",
            f:
              "'" +
              commission_name +
              "'!K" +
              this.commissionDetailRefBoxList[i].ref,
            z: "$##,##0.00",
          };
        }
        if(includeCommissionComments){
          excelSheet["G" + staffTabIndex] = {
            t: "s",
            f: "'" + commission_name + "'!L" + this.commissionDetailRefBoxList[i].ref,
          };
        }
        staffTabIndex++;
        upgradeCommissionsCount++;
      }
    }

    if (upgradeCommissionsCount > 0) {
      excelSheet["A" + staffTabIndex] = {
        t: "s",
        v: "Upgrade/Downgrade Totals",
      };
      excelSheet["F" + staffTabIndex] = {
        t: "n",
        f:
          "SUM(F" +
          (staffTabIndex - 1) +
          ":" +
          "F" +
          (staffTabIndex - upgradeCommissionsCount) +
          ")",
        z: "$##,##0.00",
      };
      staffTabIndex++;
      excelSheet["A" + staffTabIndex] = { t: "s", v: "" };
      staffTabIndex++;
    }
    return staffTabIndex;
  }

  addStaffTabsRetailReferences(excelSheet, staff, staffTabIndex) {
    let commission_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .commissionName + " Detail";
    let location_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .locationName;
    let member_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .memberName;
        
    let includeCommissionComments = this.reportCompilerState.inputFilesArrays.questions.commentsCarryOverToStaffTabs.includes("Commission");
    let retailCommissionsCount = 0;
    for (let i = 0; i < this.commissionDetailRefBoxList.length; i++) {
      if (
        staff.isNamed(this.commissionDetailRefBoxList[i].staffName) &&
        this.commissionDetailRefBoxList[i].type === "Retail"
      ) {
        if (retailCommissionsCount === 0) {
          excelSheet["A" + staffTabIndex] = { t: "s", v: "Retail Sales" };
          staffTabIndex++;
          excelSheet["A" + staffTabIndex] = { t: "s", v: "Item -- Role" };
          excelSheet["B" + staffTabIndex] = { t: "s", v: location_name };
          excelSheet["C" + staffTabIndex] = { t: "s", v: "Date" };
          excelSheet["D" + staffTabIndex] = { t: "s", v: member_name };
          excelSheet["E" + staffTabIndex] = { t: "s", v: "Payment" };
          excelSheet["F" + staffTabIndex] = { t: "s", v: "Commission" };
          excelSheet["G" + staffTabIndex] = { t: "s", v: "Comments" };
          staffTabIndex++;
        }

        excelSheet["B" + staffTabIndex] = {
          t: "s",
          f:
            "'" +
            commission_name +
            "'!A" +
            this.commissionDetailRefBoxList[i].ref,
        };
        excelSheet["C" + staffTabIndex] = {
          t: "s",
          f:
            "'" +
            commission_name +
            "'!B" +
            this.commissionDetailRefBoxList[i].ref,
        };
        excelSheet["D" + staffTabIndex] = {
          t: "s",
          f:
            "'" +
            commission_name +
            "'!C" +
            this.commissionDetailRefBoxList[i].ref,
        };
        excelSheet["E" + staffTabIndex] = {
          t: "s",
          f:
            "'" +
            commission_name +
            "'!D" +
            this.commissionDetailRefBoxList[i].ref,
          z: "$##,##0.00",
        };
        const refVar =
          "'" +
          commission_name +
          "'!E" +
          this.commissionDetailRefBoxList[i].ref;
        if (this.commissionDetailRefBoxList[i].staffType === "Closed") {
          excelSheet["A" + staffTabIndex] = {
            t: "s",
            //f: 'CONCATENATE("Closed -- " & ' + refVar + ")",
            f: "CONCATENATE(" + refVar + '& " -- Closed: NR" )',
          };
          excelSheet["F" + staffTabIndex] = {
            t: "s",
            f:
              "'" +
              commission_name +
              "'!G" +
              this.commissionDetailRefBoxList[i].ref,
            z: "$##,##0.00",
          };
        } else if (
          this.commissionDetailRefBoxList[i].staffType === "Secondary"
        ) {
          excelSheet["A" + staffTabIndex] = {
            t: "s",
            //f: 'CONCATENATE("Secondary -- " & ' + refVar + ")",
            f: "CONCATENATE(" + refVar + '& " -- Secondary: NR" )',
          };
          excelSheet["F" + staffTabIndex] = {
            t: "s",
            f:
              "'" +
              commission_name +
              "'!I" +
              this.commissionDetailRefBoxList[i].ref,
            z: "$##,##0.00",
          };
        }
        if(includeCommissionComments){
          excelSheet["G" + staffTabIndex] = {
            t: "s",
            f: "'" + commission_name + "'!H" + this.commissionDetailRefBoxList[i].ref,
          };
        }
        staffTabIndex++;
        retailCommissionsCount++;
      }
    }

    if (retailCommissionsCount > 0) {
      excelSheet["A" + staffTabIndex] = { t: "s", v: "Retail Totals" };
      excelSheet["F" + staffTabIndex] = {
        t: "n",
        f:
          "SUM(F" +
          (staffTabIndex - 1) +
          ":" +
          "F" +
          (staffTabIndex - retailCommissionsCount) +
          ")",
        z: "$##,##0.00",
      };
      staffTabIndex++;
      excelSheet["A" + staffTabIndex] = { t: "s", v: "" };
      staffTabIndex++;
    }
    return staffTabIndex;
  }

  addCommissionFromSummaryTab(excelSheet, staff, staffTabIndex) {
    let location_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .locationName;
    let commissionAmountColumn = this.findHoursColumn() + 6;
    let commissionPayColumn = commissionAmountColumn + 1;
    //this.alphabet.charAt(timeColumn+2)
    let summaryCommissionCount = 0;
    for (let i = 0; i < this.summaryStudioInstructorLocations.length; i++) {
      if (staff.isNamed(this.summaryStudioInstructorLocations[i][1])) {
        if (summaryCommissionCount === 0) {
          excelSheet["A" + staffTabIndex] = { t: "s", v: "Commission Summary" };
          staffTabIndex++;
          excelSheet["A" + staffTabIndex] = { t: "s", v: "Type" };
          excelSheet["B" + staffTabIndex] = { t: "s", v: location_name };
          excelSheet["E" + staffTabIndex] = { t: "s", v: "Amount" };
          excelSheet["F" + staffTabIndex] = { t: "s", v: "Pay" };
          staffTabIndex++;
        }

        excelSheet["A" + staffTabIndex] = {
          t: "s",
          v: "Overall",
        };
        excelSheet["B" + staffTabIndex] = {
          t: "s",
          v: this.summaryStudioInstructorLocations[i][0],
        };
        excelSheet["E" + staffTabIndex] = {
          t: "s",
          f:
            "'Summary'!" +
            this.alphabet.charAt(commissionAmountColumn) +
            this.summaryStudioInstructorLocations[i][2],
        };
        excelSheet["F" + staffTabIndex] = {
          t: "s",
          f:
            "'Summary'!" +
            this.alphabet.charAt(commissionPayColumn) +
            +this.summaryStudioInstructorLocations[i][2],
          z: "$##,##0.00",
        };
        staffTabIndex++;
        summaryCommissionCount++;
      }
    }

    if (summaryCommissionCount > 0) {
      excelSheet["A" + staffTabIndex] = { t: "s", v: "Summary Totals" };
      excelSheet["E" + staffTabIndex] = {
        t: "n",
        f:
          "COUNTIF(E" +
          (staffTabIndex - 1) +
          ":" +
          "E" +
          (staffTabIndex - summaryCommissionCount) +
          ', "<>0")',
      };
      excelSheet["F" + staffTabIndex] = {
        t: "n",
        f:
          "SUM(F" +
          (staffTabIndex - 1) +
          ":" +
          "F" +
          (staffTabIndex - summaryCommissionCount) +
          ")",
        z: "$##,##0.00",
      };
      staffTabIndex++;
      excelSheet["A" + staffTabIndex] = { t: "s", v: "" };
      staffTabIndex++;
    }

    return staffTabIndex;
  }

  addStaffTabFooter(excelSheet, staff, staffTabIndex) {
    excelSheet["A" + staffTabIndex] = { t: "s", v: "" };
    staffTabIndex++;

    if (
      this.reportCompilerState.inputFilesArrays.questions
        .includePayTypeSubtotals.value
    ) {
      staffTabIndex = this.addTypeSubtotals(excelSheet, staffTabIndex);
    }

    if (
      this.reportCompilerState.inputFilesArrays.questions.includeStudioSubtotals
        .value
    ) {
      staffTabIndex = this.addStudioSubtotals(excelSheet, staffTabIndex);
    }

    excelSheet["A" + staffTabIndex] = {
      t: "s",
      v: staff.primaryName + " Totals",
    };
    excelSheet["F" + staffTabIndex] = {
      t: "n",
      f:
        "SUMIF(A1:A" +
        (staffTabIndex - 1) +
        ',"*Totals*",F1:F' +
        (staffTabIndex - 1) +
        ")",
      z: "$##,##0.00",
    };
  }

  addTypeSubtotals(excelSheet, staffTabIndex) {
    let session_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .sessionName;
    let time_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .timeName;
    let commission_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .commissionName;

    let buckets =
      this.reportCompilerState.inputFilesArrays.questions.classBuckets;
    let bucketNames = buckets.map((bucket) => `"=${bucket.type} Totals*"`);

    let finalString = ",{" + bucketNames.join(",") + "}))";

    let sumifsClassFunction =
      "SUM(SUMIFS(F1:F" +
      (staffTabIndex - 1) +
      ",A1:A" +
      (staffTabIndex - 1) +
      finalString;
    excelSheet["A" + staffTabIndex] = { t: "s", v: session_name + " Subtotal" };
    excelSheet["F" + staffTabIndex] = {
      t: "n",
      f: sumifsClassFunction,
      z: "$##,##0.00",
    };
    staffTabIndex++;
    let sumifsTimeFunction =
      "SUM(SUMIFS(F1:F" +
      (staffTabIndex - 2) +
      ",A1:A" +
      (staffTabIndex - 2) +
      ',{"=Regular*","=Overtime*","=Reimbursement*"}))';
    excelSheet["A" + staffTabIndex] = { t: "s", v: time_name + " Subtotal" };
    excelSheet["F" + staffTabIndex] = {
      t: "n",
      f: sumifsTimeFunction,
      z: "$##,##0.00",
    };
    staffTabIndex++;
    let sumifsCommissionFunction =
      "SUM(SUMIFS(F1:F" +
      (staffTabIndex - 3) +
      ",A1:A" +
      (staffTabIndex - 3) +
      ',{"=Post-Intro Agreement*","=Non-Intro Agreement*","=Upgrade/Downgrade*","=Retail*","=Summary Totals*"}))';
    excelSheet["A" + staffTabIndex] = {
      t: "s",
      v: commission_name + " Subtotal",
    };
    excelSheet["F" + staffTabIndex] = {
      t: "n",
      f: sumifsCommissionFunction,
      z: "$##,##0.00",
    };
    staffTabIndex++;
    excelSheet["A" + staffTabIndex] = { t: "s", v: "" };
    staffTabIndex++;

    return staffTabIndex;
  }

  addStudioSubtotals(excelSheet, staffTabIndex) {
    // const studioArray = this.reportCompilerState.studiosInformation.studiosArray; ~~~~
    const studioArray =
      this.reportCompilerState.payrollInformation.studiosInInput.sort();
    for (let i = 0; i < studioArray.length; i++) {
      let sumifsCommissionFunction =
        "SUMIF(B1:B" +
        staffTabIndex +
        ',"' +
        studioArray[i] +
        '",F1:F' +
        staffTabIndex +
        ")";
      excelSheet["A" + staffTabIndex] = {
        t: "s",
        v: studioArray[i] + " Subtotal",
      };
      excelSheet["F" + staffTabIndex] = {
        t: "n",
        f: sumifsCommissionFunction,
        z: "$##,##0.00",
      };
      staffTabIndex++;
    }
    excelSheet["A" + staffTabIndex] = { t: "s", v: "" };
    staffTabIndex++;
    return staffTabIndex;
  }

  /**** CREATE SUMMARY DETAIL ****/
  addSummaryTabToExcelWorkbook(wb) {
    wb.SheetNames.push("Summary");
    const summaryTabOutput = [];
    this.createSummaryArray(summaryTabOutput);

    const summary = XLSX.utils.aoa_to_sheet(summaryTabOutput);
    this.addSummaryReferencesTest(summary);
    // const usePercentDif = this.reportCompilerState.inputFilesArrays.questions
    //   .hoursVsClassesPercentFlag.valid;
    // const maxDif = this.reportCompilerState.inputFilesArrays.questions
    //   .hoursVsClassesPercentFlag.percentError;
    // if (usePercentDif) {
    //   //if(maxDif !== 1){
    //   this.addTimeVsClassesFlag(summary);
    // }
    wb.Sheets["Summary"] = summary;
  }

  createSummaryArray(summaryTabOutput) {
    this.addDetailHeader(summaryTabOutput);

    const summaryRowsArray = [];
    const summaryHeader = this.createSummaryHeader();

    this.addOverallSummary(summaryTabOutput, summaryRowsArray, summaryHeader);
    this.addStudioSummaries(summaryTabOutput, summaryRowsArray, summaryHeader);

    return summaryRowsArray;
  }

  createSummaryHeader() {
    let session_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .sessionName;
    let time_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .timeName;

    let buckets =
      this.reportCompilerState.inputFilesArrays.questions.classBuckets.sort(
        (a, b) => {
          if (a.type < b.type) return -1;
          if (a.type > b.type) return 1;
          return 0;
        }
      );

    let salesBuckets = this.reportCompilerState.inputFilesArrays.questions.salesBuckets.sort(
      (a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }
    );

    let summaryHeader = ["Employee"];
    for (let i = 0; i < buckets.length; i++) {
      summaryHeader.push(buckets[i].type + " " + session_name + " Amount");
      summaryHeader.push(buckets[i].type + " " + session_name + " Pay");
    }

    summaryHeader.push("All " + session_name + " Amount");
    summaryHeader.push("All " + session_name + " Pay");

    summaryHeader.push(time_name + " Clock Hours");
    summaryHeader.push(time_name + " Clock Pay");

    if(this.reportCompilerState.inputFilesArrays.questions.salesBuckets.length > 0){
      for (let i = 0; i < salesBuckets.length; i++) {
        summaryHeader.push(salesBuckets[i].name + " Amount");
        summaryHeader.push(salesBuckets[i].name + " Pay");
      }
      summaryHeader.push("Sales Amount");
      summaryHeader.push("Sales Pay");
    } else {
      summaryHeader.push("Agreements Amount");
      summaryHeader.push("Agreements Pay");

      summaryHeader.push("Retail Amount");
      summaryHeader.push("Retail Pay");

      summaryHeader.push("Commission Amount");
      summaryHeader.push("Commission Pay");
    }

    summaryHeader.push("Total Pay");

    if (
      this.reportCompilerState.inputFilesArrays.questions
        .includeCostPerClassMetric.value
    ) {
      this.commentColumn = 21;
      summaryHeader.push("Cost Per " + session_name);
    }
    summaryHeader.push("Comments");
    return summaryHeader;
  }

  findCurrencyLocations() {
    let buckets =
      this.reportCompilerState.inputFilesArrays.questions.classBuckets;
    let salesBuckets = this.reportCompilerState.inputFilesArrays.questions.salesBuckets;

    let locations = [];
    let columnNumber = 0;
    for (let i = 0; i < buckets.length; i++) {
      columnNumber += 2;
      locations.push(columnNumber);
    }

    columnNumber += 2;
    locations.push(columnNumber);

    columnNumber += 2;
    locations.push(columnNumber);

    if(salesBuckets.length > 0){
      for (let i = 0; i < salesBuckets.length; i++) {
        columnNumber += 2;
        locations.push(columnNumber);
      }
      columnNumber += 2;
      locations.push(columnNumber);
    } else {
      columnNumber += 2;
      locations.push(columnNumber);

      columnNumber += 2;
      locations.push(columnNumber);

      columnNumber += 2;
      locations.push(columnNumber);
    }
    
    columnNumber += 1;
    locations.push(columnNumber);

    if (
      this.reportCompilerState.inputFilesArrays.questions
        .includeCostPerClassMetric.value
    ) {
      columnNumber += 1;
      locations.push(columnNumber);
    }

    return locations;
  }

  findTotalsCurrencyLocations() {
    let buckets =
      this.reportCompilerState.inputFilesArrays.questions.classBuckets;

    let locations = [];
    let columnNumber = 0;
    for (let i = 0; i < buckets.length; i++) {
      columnNumber += 2;
      locations.push(columnNumber);
    }

    columnNumber += 4;
    locations.push(columnNumber);

    // columnNumber += 2;
    // locations.push(columnNumber);

    columnNumber += 2;
    locations.push(columnNumber);

    columnNumber += 2;
    locations.push(columnNumber);

    return locations;
  }

  findRowLength() {
    let buckets =
      this.reportCompilerState.inputFilesArrays.questions.classBuckets;

    let salesBuckets = this.reportCompilerState.inputFilesArrays.questions.salesBuckets;

    let length = 1;
    for (let i = 0; i < buckets.length; i++) {
      length += 2;
    }

    length += 2;

    length += 2;

    if(salesBuckets.length > 0){
      for (let i = 0; i < salesBuckets.length; i++) {
        length += 2;
      }
      length += 2;
    } else {
      length += 2;
      length += 2;
      length += 2;
    }

    length += 1;

    if (
      this.reportCompilerState.inputFilesArrays.questions
        .includeCostPerClassMetric.value
    ) {
      length += 1;
    }

    length += 1;

    return length;
  }

  findHoursColumn() {
    let buckets =
      this.reportCompilerState.inputFilesArrays.questions.classBuckets;
    return 1 + 2 * buckets.length + 2;
  }

  addOverallSummary(summaryTabOutput, summaryRowsArray, summaryHeader) {
    // const studiosArray = this.reportCompilerState.studiosInformation.studiosArray; ~~~~
    const studiosArray =
      this.reportCompilerState.payrollInformation.studiosInInput.sort();
    const overallTotal = this.addOverallSummaryTemplate();
    const currencyLocations = this.findCurrencyLocations();

    summaryTabOutput.push(["Overall"]);
    summaryTabOutput.push(summaryHeader);
    for (let a = 0; a < overallTotal.length; a++) {
      summaryTabOutput.push(overallTotal[a].slice(0, overallTotal[a].length));
      summaryRowsArray.push(summaryTabOutput.length);

      // this.addSummaryCurrencyLocationArray(
      //   [2, 4, 6, 8, 10, 12, 14, 16, 18, 19],
      //   summaryTabOutput.length
      // );

      this.addSummaryCurrencyLocationArray(
        currencyLocations,
        summaryTabOutput.length
      );

      // if (
      //   this.reportCompilerState.inputFilesArrays.questions
      //     .includeCostPerClassMetric.value
      // ) {
      //   this.addSummaryCurrencyLocationArray([20], summaryTabOutput.length);
      // }
      if (studiosArray.length === 1) {
        this.summaryStudioInstructorLocations.push([
          studiosArray[0],
          overallTotal[a][0],
          summaryTabOutput.length,
        ]);
      }
    }
  }

  addOverallSummaryTemplate() {
    const overallTotal = [];

    const staffArray = this.reportCompilerState.studiosInformation.staffArray;
    for (let z = 0; z < staffArray.length; z++) {
      const instructorTotalLine = this.createZeroedOutRow(
        staffArray[z].primaryName,
        "Overall"
      );
      overallTotal.push(instructorTotalLine);
    }
    const overallTotalsLine = this.createZeroedOutRow(
      "Overall Totals",
      "Overall"
    );
    overallTotal.push(overallTotalsLine);
    return overallTotal;
  }

  addStudioSummaries(summaryTabOutput, summaryRowsArray, summaryHeader) {
    // const studiosArray = this.reportCompilerState.studiosInformation.studiosArray; ~~~~
    const studiosArray =
      this.reportCompilerState.payrollInformation.studiosInInput.sort();
    const totalsByStudio = this.addStudioSummariesTemplates();
    let currencyLocations = this.findCurrencyLocations();

    if (studiosArray.length > 1) {
      for (let b = 0; b < totalsByStudio.length; b++) {
        summaryTabOutput.push(this.blankExcelRow);
        summaryTabOutput.push([
          totalsByStudio[b][0][totalsByStudio[b][0].length - 1],
        ]);
        summaryTabOutput.push(summaryHeader);

        for (let w = 0; w < totalsByStudio[b].length; w++) {
          summaryTabOutput.push(
            totalsByStudio[b][w].slice(0, totalsByStudio[b][w].length - 1)
          );
          summaryRowsArray.push(summaryTabOutput.length);

          // this.addSummaryCurrencyLocationArray(
          //   [2, 4, 6, 8, 10, 12, 14, 16, 18, 19],
          //   summaryTabOutput.length
          // );

          this.addSummaryCurrencyLocationArray(
            currencyLocations,
            summaryTabOutput.length
          );

          this.summaryStudioInstructorLocations.push([
            totalsByStudio[b][0][totalsByStudio[b][0].length - 1],
            totalsByStudio[b][w][0],
            summaryTabOutput.length,
          ]);
        }
      }
    }
  }

  addStudioSummariesTemplates() {
    // const studiosArray = this.reportCompilerState.studiosInformation.studiosArray; ~~~~
    const studiosArray =
      this.reportCompilerState.payrollInformation.studiosInInput.sort();

    const staffArray = this.reportCompilerState.studiosInformation.staffArray;
    const totalsByStudio = [];

    for (let i = 0; i < studiosArray.length; i++) {
      totalsByStudio.push([]);
      for (let z = 0; z < staffArray.length; z++) {
        const instructorTeach = this.staffWorkedAtStudio(
          studiosArray[i],
          staffArray[z]
        );

        if (instructorTeach) {
          let instTotalPayLine = this.createZeroedOutRow(
            staffArray[z].primaryName,
            studiosArray[i]
          );
          totalsByStudio[i].push(instTotalPayLine);
        }
      }
      const totalTotalPayLine = this.createZeroedOutRow(
        studiosArray[i] + " Totals",
        studiosArray[i]
      );
      totalsByStudio[i].push(totalTotalPayLine);
    }
    return totalsByStudio;
  }

  createZeroedOutRow(staffName, location) {
    let row = [staffName];
    for (let j = 1; j < this.findRowLength() - 1; j++) {
      row.push(0);
    }
    //Comment Column
    row.push("");
    if (location !== "Overall") {
      row.push(location);
    }
    return row;
  }

  //may need to use
  namesAreSameStaff(staffName1, staffName2) {
    try {
      // Type check for staffName1
      // if (typeof staffName1 !== "string") {
      //   throw new TypeError("staffName1 should be a string.");
      // }

      // Type check for staffName2
      // if (typeof staffName2 !== "string") {
      //   throw new TypeError("staffName2 should be a string.");
      // }

      const staffArray = this.reportCompilerState.studiosInformation.staffArray;
      if (staffName1.length > 0 && staffName2.length > 0) {
        for (let i = 0; i < staffArray.length; i++) {
          if (
            staffArray[i].isNamed(staffName1) &&
            staffArray[i].isNamed(staffName2)
          ) {
            return true;
          }
        }
      }
      return false;
    } catch (error) {
      // Handle the exceptions
      // console.error(
      //   "An error occurred:",
      //   error.message,
      //   " staffName1: ",
      //   staffName1,
      //   " staffName2: ",
      //   staffName2
      // );

      // You can choose to rethrow the error or return a specific value
      // throw error;
      // return false;
    }
  }

  addSummaryReferencesTest(worksheet) {
    const range = XLSX.utils.decode_range(worksheet["!ref"]); // e.g. { s: { c: 0, r: 0 }, e: { c: 10, r: 10 } }

    let studios = ["Overall"].concat(
      this.reportCompilerState.payrollInformation.studiosInInput
    );
    let staffNameArray =
      this.reportCompilerState.studiosInformation.staffArray.map(
        (obj) => obj.primaryName
      );
    let bucketNames =
      this.reportCompilerState.inputFilesArrays.questions.classBuckets.map(
        (bucket) => bucket.type
      );

    let salesBucketNames = this.reportCompilerState.inputFilesArrays.questions.salesBuckets.map(
      (bucket) => bucket.name
    );
    let summaryHeader = this.createSummaryHeader();
    let session_sheet_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .sessionName + " Detail";
    let time_sheet_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .timeName + " Detail";
    let commission_sheet_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .commissionName + " Detail";
    let timeColumn = this.findHoursColumn();
    let totalLocations = this.findTotalsCurrencyLocations();

    const usePercentDif =
      this.reportCompilerState.inputFilesArrays.questions
        .hoursVsClassesPercentFlag.valid;

    let currentStudio = null;
    let currentHeaderRow = null;

    let currentStaff = null;
    let currentStaffObj = null;
    let currentDetail = null;

    for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
      for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
        // Translate the row and column number to an Excel cell reference
        let cellRef = XLSX.utils.encode_cell({ r: rowNum, c: colNum });

        // Get the cell object from the worksheet
        let cell = worksheet[cellRef];

        // Check if the cell exists (it might not if, for example, it's empty in the Excel file)
        if (!cell) {
          continue;
        }
        if (cell.v !== 0) {
          // Log or process the cell's value
          if (studios.includes(cell.v)) {
            currentStudio = cell.v;
            currentHeaderRow = rowNum + 2;
            continue;
          }
          if (colNum === 0 && staffNameArray.includes) {
            currentStaff = cell.v;
            currentStaffObj = this.getStaffByName(currentStaff);
            continue;
          }
          //console.log(cell.v);
        }

        let columnValue = summaryHeader[colNum];

        if (
          columnValue.includes("Comments") &&
          usePercentDif &&
          cell.v !== "Comments"
        ) {
          this.calculateTimeVsClasses(cell, rowNum);
        }

        if (
          currentStudio === null ||
          currentStaff === null ||
          (currentStaffObj === null && !currentStaff.includes("Totals")) ||
          cell.v !== 0
        ) {
          continue;
        }

        // if(columnValue.includes('Comments') && usePercentDif){
        //     this.calculateTimeVsClasses(cell, rowNum);
        //   }

        if (currentStaff.includes(" Totals")) {
          cell.f =
            "SUM(" +
            this.alphabet.charAt(colNum) +
            (currentHeaderRow + 1) +
            ":" +
            this.alphabet.charAt(colNum) +
            rowNum +
            ")";
          if (columnValue.includes("Pay")) {
            cell.z = "$##,##0.00";
          }
          continue;
        }

        if (columnValue.includes("All ") && columnValue.includes("Amount")) {
          let totalCountFormula = this.createSubTotalsFunction(
            rowNum + 1,
            colNum,
            bucketNames.length
          );
          cell.f = totalCountFormula;
          continue;
        } else if (
          columnValue.includes("All ") &&
          columnValue.includes("Pay")
        ) {
          let totalPayFormula = this.createSubTotalsFunction(
            rowNum + 1,
            colNum,
            bucketNames.length
          );
          cell.f = totalPayFormula;
          cell.z = "$##,##0.00";
          continue;
        } else if (columnValue.includes("Sales ") && columnValue.includes("Amount")) {
          let totalCountFormula = this.createSubTotalsFunction(
            rowNum + 1,
            colNum,
            salesBucketNames.length
          );
          cell.f = totalCountFormula;
          continue;
        } else if (
          columnValue.includes("Sales ") &&
          columnValue.includes("Pay")
        ) {
          let totalPayFormula = this.createSubTotalsFunction(
            rowNum + 1,
            colNum,
            salesBucketNames.length
          );
          cell.f = totalPayFormula;
          cell.z = "$##,##0.00";
          continue;
        } else if (columnValue.includes("Commission Amount")) {
          cell.f =
            "SUM(" +
            this.alphabet.charAt(timeColumn + 2) +
            (rowNum + 1) +
            "," +
            this.alphabet.charAt(timeColumn + 4) +
            (rowNum + 1) +
            ")";
          delete cell.z;
          continue;
        } else if (columnValue.includes("Commission Pay")) {
          cell.f =
            "SUM(" +
            this.alphabet.charAt(timeColumn + 3) +
            (rowNum + 1) +
            "," +
            this.alphabet.charAt(timeColumn + 5) +
            (rowNum + 1) +
            ")";
          cell.z = "$##,##0.00";
          continue;
        } else if (columnValue === "Total Pay") {
          const columnLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

          // Convert array index to Excel column letter
          const indexToColumnLetter = (index) => {
            if (index < 26) {
              return columnLetters[index];
            } else {
              // If index is >= 26, it becomes a 2-letter column, e.g., 26 -> AA, 27 -> AB
              const firstLetter = columnLetters[Math.floor(index / 26) - 1];
              const secondLetter = columnLetters[index % 26];
              return firstLetter + secondLetter;
            }
          };

          // Convert numbers array to cell references
          const cells = totalLocations.map((num, idx) => {
            const colLetter = indexToColumnLetter(num);
            return `${colLetter}${rowNum + 1}`;
          });

          // Join the cells into the SUM formula
          cell.f = `SUM(${cells.join(",")})`;
          cell.z = "$##,##0.00";
          continue;
        } else if (columnValue.includes("Cost Per")) {
          const costPerClass =
            "IFERROR( C" + (rowNum + 1) + "/ B" + (rowNum + 1) + ', "")';
          cell.f = costPerClass;
          cell.z = "$##,##0.00";
        } else if (columnValue.includes("Comments") && usePercentDif) {
          this.calculateTimeVsClasses(cell, rowNum);
        }

        for (let k = 0; k < bucketNames.length; k++) {
          if (columnValue.includes(bucketNames[k])) {
            currentDetail = session_sheet_name;
            break;
          }
        }
        for(let u = 0; u < salesBucketNames.length; u++){
          if(columnValue.includes(salesBucketNames[u])){
            currentDetail = commission_sheet_name;
            break;
          }
        }
        if (columnValue.includes("Clock")) {
          currentDetail = time_sheet_name;
        }
        if (
          columnValue.includes("Agreement") ||
          columnValue.includes("Retail")
        ) {
          currentDetail = commission_sheet_name;
        }

        let formula = "";

        if (currentStudio && currentStaff && currentDetail) {
          for (let j = 0; j < this.cellRefByInstructor.length; j++) {
            //current staff and instructor may be weird
            if (
              // columnValue.includes(this.cellRefByInstructor[j].type) &&
              (columnValue.substring(0, columnValue.indexOf(" ")) ===
                this.cellRefByInstructor[j].type ||
                (columnValue.includes("Agreement") &&
                  this.cellRefByInstructor[j].type.includes("Agree"))) &&
              (currentStudio === this.cellRefByInstructor[j].studio ||
                currentStudio.includes("Overall")) &&
              currentStaffObj.isNamed(this.cellRefByInstructor[j].instructor)
              // currentStaff === this.cellRefByInstructor[j].instructor
            ) {
              if (columnValue.includes("Amount")) {
                formula +=
                  '"' +
                  "'" +
                  currentDetail +
                  "'!" +
                  this.alphabet.charAt(this.cellRefByInstructor[j].column - 1) +
                  this.cellRefByInstructor[j].row +
                  '",';
              } else if (columnValue.includes("Pay")) {
                formula +=
                  "'" +
                  currentDetail +
                  "'!" +
                  this.alphabet.charAt(this.cellRefByInstructor[j].column) +
                  this.cellRefByInstructor[j].row +
                  ",";
              } else if (columnValue.includes("Clock Hours")) {
                formula +=
                  "'" +
                  currentDetail +
                  "'!" +
                  this.alphabet.charAt(this.cellRefByInstructor[j].column - 1) +
                  this.cellRefByInstructor[j].row +
                  ",";
              }
            }
          }
        }

        if (formula.length === 0) {
          continue;
        }

        if (columnValue.includes("Amount")) {
          let [sessionOptions, unitOptions] = this.reportCompilerState.inputFilesArrays.questions.displayCountsFormat;
          if(sessionOptions === "All"){
            formula = '=COUNTA(' + formula.substring(0, formula.length - 1) + ')';
          } else if(sessionOptions === "Completed"){
            let testFormula = '=SUM(';
            let cellsArray = formula.substring(0, formula.length - 1).replace(/\"/g, '').split(',');
            cellsArray = cellsArray.filter(cell => cell.trim() !== '');

            // Loop through the array and create the formula
            for (let i = 0; i < cellsArray.length; i++) {
              testFormula += `--(${cellsArray[i]}<>0)`;
                if (i < cellsArray.length - 1) {
                  testFormula += ', ';
                }
            }

            testFormula += ')';
            formula = testFormula;
          }
          // formula =
          //   "SUM(COUNTIF(INDIRECT({" +
          //   formula.substring(0, formula.length - 1) +
          //   '}), "<>0"))';
        } else if (columnValue.includes("Pay")) {
          formula = "SUM(" + formula.substring(0, formula.length - 1) + ")";
          cell.z = "$##,##0.00";
        } else if (columnValue.includes("Clock Hours")) {
          formula = "SUM(" + formula.substring(0, formula.length - 1) + ")";
        }

        cell.f = formula;
      }
      currentDetail = null;
    }
  }

  getStaffByName(name) {
    let staffArray = this.reportCompilerState.studiosInformation.staffArray;
    for (let i = 0; i < staffArray.length; i++) {
      if (staffArray[i].isNamed(name)) {
        return staffArray[i];
      }
    }
    return null;
  }

  createSubTotalsFunction(rowNum, colNum, items) {
    let itemColumns = [];
    for (let i = 1; i <= items; i++) {
      let column = colNum - (2 * i);
      itemColumns.push(column);
    }

    const columnLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    // Convert array index to Excel column letter
    const indexToColumnLetter = (index) => {
      if (index < 26) {
        return columnLetters[index];
      } else {
        // If index is >= 26, it becomes a 2-letter column, e.g., 26 -> AA, 27 -> AB
        const firstLetter = columnLetters[Math.floor(index / 26) - 1];
        const secondLetter = columnLetters[index % 26];
        return firstLetter + secondLetter;
      }
    };

    const cells = itemColumns.map((num, idx) => {
      const colLetter = indexToColumnLetter(num);
      return `${colLetter}${rowNum}`;
    });

    // Join the cells into the SUM formula
    return `SUM(${cells.join(",")})`;

  }

  createSessionTotalFunction(rowNum, colNum, payColumn) {
    let sessionColumns = this.oddNumbersBelow(colNum - 1);

    if (payColumn) {
      sessionColumns = this.evenNumbersBelow(colNum - 1);
    }

    const columnLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    // Convert array index to Excel column letter
    const indexToColumnLetter = (index) => {
      if (index < 26) {
        return columnLetters[index];
      } else {
        // If index is >= 26, it becomes a 2-letter column, e.g., 26 -> AA, 27 -> AB
        const firstLetter = columnLetters[Math.floor(index / 26) - 1];
        const secondLetter = columnLetters[index % 26];
        return firstLetter + secondLetter;
      }
    };

    // Convert numbers array to cell references
    const cells = sessionColumns.map((num, idx) => {
      const colLetter = indexToColumnLetter(num);
      return `${colLetter}${rowNum}`;
    });

    // Join the cells into the SUM formula
    return `SUM(${cells.join(",")})`;
  }

  evenNumbersBelow(n) {
    const result = [];
    for (let i = 2; i < n; i += 2) {
      result.push(i);
    }
    return result;
  }

  oddNumbersBelow(n) {
    const result = [];
    for (let i = 1; i < n; i += 2) {
      result.push(i);
    }
    return result;
  }

  columnCreator(x, y, isEven) {
    let result = [];
    for (let i = x; i <= y; i++) {
      if (isEven && i % 2 === 0) {
        result.push(i);
      } else if (!isEven && i % 2 !== 0) {
        result.push(i);
      }
    }
    return result;
  }

  addCostPerClassReference(worksheet, excelRow) {
    const classCost = "=IFERROR( C" + excelRow + "/ B" + excelRow + ', "")';
    worksheet[this.alphabet.charAt(20) + excelRow] = {
      t: "n",
      f: classCost,
      z: "$##,##0.00",
    };
  }

  addTimeVsClassesFlag(worksheet) {
    let range = XLSX.utils.decode_range(worksheet["!ref"]);
    for (let row = range.s.r; row <= range.e.r; row++) {
      let firstColValue = worksheet[XLSX.utils.encode_cell({ c: 0, r: row })].v;
      if (this.isStaff(firstColValue)) {
        this.calculateTimeVsClasses(worksheet, row);
      }
    }
  }

  calculateTimeVsClasses(cell, excelRow) {
    let hoursVsClassesObj =
      this.reportCompilerState.inputFilesArrays.questions
        .hoursVsClassesPercentFlag;

    let timeColumn = this.findHoursColumn();
    let classColumn = timeColumn - 2;

    let hoursCell = XLSX.utils.encode_cell({ c: timeColumn, r: excelRow });
    let classesCell = XLSX.utils.encode_cell({ c: classColumn, r: excelRow });

    let adjustedClassHours =
      "(" + classesCell + " * " + hoursVsClassesObj.averageClassHours + ")";
    let dif = hoursCell + " - " + adjustedClassHours;
    let percentDif = "(" + dif + ") / " + adjustedClassHours;
    let absDif = "ABS(" + percentDif + ")";
    let absDifCheck = "" + absDif + " < " + hoursVsClassesObj.percentError;
    //let absWithOr = "OR(" + adjustedClassHours + " =0, " + absDifCheck +")";
    let formula = "IF(" + absDifCheck + ',"","Hours Do Not Match Classes")';
    let errorFormula = "IFERROR(" + formula + ',"")';

    cell.f = errorFormula;
    cell.t = "s";

    return;
  }

  addTimeSummaryReferences(
    worksheet,
    countString,
    payString,
    classTypeIndex,
    excelRow
  ) {
    const countFunc = "sum(" + countString + ")";
    const sumFunc = "sum(" + payString + ")";

    worksheet[this.alphabet.charAt(classTypeIndex - 1) + excelRow] = {
      t: "n",
      f: countFunc,
    };
    worksheet[this.alphabet.charAt(classTypeIndex) + excelRow] = {
      t: "n",
      f: sumFunc,
      z: "$##,##0.00",
    };
  }

  addCommissionSummaryReferences(
    worksheet,
    countString,
    payString,
    classTypeIndex,
    excelRow
  ) {
    const countFunc = "sum(countif(indirect({" + countString + '}), ">0"))';
    const sumFunc = "sum(" + payString + ")";

    worksheet[this.alphabet.charAt(classTypeIndex - 1) + excelRow] = {
      t: "n",
      f: countFunc,
    };
    worksheet[this.alphabet.charAt(classTypeIndex) + excelRow] = {
      t: "n",
      f: sumFunc,
      z: "$##,##0.00",
    };
  }

  isSummaryTotalRow(row, location) {
    return (
      row.length > 2 && row[0].includes(location) && row[0].includes("Total")
    );
  }

  createSumFormulaRange(firstCell, secondCell) {
    return "sum(" + firstCell + ":" + secondCell + ")";
  }

  createSumFormulaCells(cellArray, row) {
    let formula = "sum(";
    for (let i = 0; i < cellArray.length; i++) {
      formula = formula + this.alphabet.charAt(cellArray[i]) + row + "+";
    }
    return formula.substring(0, formula.length - 1) + ")";
  }

  addSummaryCurrencyLocationArray(locations, row) {
    for (let i = 0; i < locations.length; i++) {
      this.currencyLocations.push([3, locations[i], row]);
    }
  }

  /**** CREATE TIME DETAIL ****/
  addTimeDetailToExcelWorkbook(wb) {
    let time_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .timeName + " Detail";
    this.timeClockPayFormulas = [];
    wb.SheetNames.push(time_name);
    let timeDetail = [];
    if (this.reportCompilerState.inputFilesArrays.TIME != null) {
      timeDetail = this.createTimeDetail();
    }
    const timeDetailWS = XLSX.utils.aoa_to_sheet(timeDetail);
    this.addTimeClockPayFormulas(timeDetailWS);
    wb.Sheets[time_name] = timeDetailWS;
  }

  //   hasKeyword(str, keyword) {
  //     return str.includes(keyword);
  // }

  // // Function to parse date and time from string
  // parseDateAndTime(str) {
  //   const dateTimeRegex = /(\d{1,2}\/\d{1,2}\/\d{4}) \((\d{1,2}:\d{2} (AM|PM))/;
  //   const match = str.match(dateTimeRegex);
  //   if (match) {
  //       let date = new Date(match[1]);
  //       let [hour, minute] = match[2].split(':');
  //       let period = match[3];
  //       if (period === 'PM' && hour !== '12') hour = parseInt(hour) + 12;
  //       if (period === 'AM' && hour === '12') hour = '00';
  //       date.setHours(parseInt(hour));
  //       date.setMinutes(parseInt(minute));
  //       return date;
  //   } else {
  //       return null;
  //   }
  // }

  createTimeDetail() {
    function hasKeyword(str, keyword) {
      return str.includes(keyword);
    }

    // Function to parse date and time from string
    function parseDateAndTime(str) {
      const dateTimeRegex =
        /(\d{1,2}\/\d{1,2}\/\d{4}) \((\d{1,2}:\d{2} (AM|PM))/;
      const match = str.match(dateTimeRegex);
      if (match) {
        let date = new Date(match[1]);
        let [hour, minute] = match[2].split(":");
        let period = match[3];
        if (period === "PM" && hour !== "12") hour = parseInt(hour) + 12;
        if (period === "AM" && hour === "12") hour = "00";
        date.setHours(parseInt(hour));
        date.setMinutes(parseInt(minute));
        return date;
      } else {
        return null;
      }
    }

    const staffArray = this.reportCompilerState.studiosInformation.staffArray;
    const timeTable = this.reportCompilerState.inputFilesArrays.TIME.sort(
      function (a, b) {
        // Compare by location
        if (a.location < b.location) {
          return -1;
        }
        if (a.location > b.location) {
          return 1;
        }

        let aDate = parseDateAndTime(a.description);
        let bDate = parseDateAndTime(b.description);

        if (aDate && bDate) {
          // Both strings have a date and time, sort by date and time
          return aDate - bDate;
        } else if (aDate) {
          // Only string A has a date and time
          return -1;
        } else if (bDate) {
          // Only string B has a date and time
          return 1;
        }

        // If both or neither have a date and time, proceed to check for keywords

        let aHasRegular = hasKeyword(a.description, "Regular");
        let bHasRegular = hasKeyword(b.description, "Regular");

        if (aHasRegular && !bHasRegular) {
          // String A has 'Regular', but B does not
          return -1;
        } else if (bHasRegular && !aHasRegular) {
          // String B has 'Regular', but A does not
          return 1;
        }

        // If both or neither have 'Regular', proceed to check for 'Ordinary'

        let aHasOrdinary = hasKeyword(a.description, "Ordinary");
        let bHasOrdinary = hasKeyword(b.description, "Ordinary");

        if (aHasOrdinary && !bHasOrdinary) {
          // String A has 'Ordinary', but B does not
          return -1;
        } else if (bHasOrdinary && !aHasOrdinary) {
          // String B has 'Ordinary', but A does not
          return 1;
        }

        // If both or neither have 'Ordinary', sort alphabetically
        return a.description.localeCompare(b.description);

        // If both location and description are the same, return 0
        return 0;
      }
    );

    const timeDetail = [];
    this.addDetailHeader(timeDetail);
    let location_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .locationName;
    let systemClockOutTime =
      this.reportCompilerState.inputFilesArrays.questions.systemClockOutTime
        .value;

    let mealBreakFlag = this.reportCompilerState.inputFilesArrays.questions.mealBreakFlag;
    mealBreakFlag = mealBreakFlag === "hours~-1" ? null : mealBreakFlag;

    for (let m = 0; m < staffArray.length; m++) {
      let timeEventCount = 0;

      for (let i = 0; i < timeTable.length; i++) {
        if (timeTable[i]?.staffName) {
          // ~~~~ scott bug - timeTable[i].staffName undefined
          if (staffArray[m].isNamed(timeTable[i].staffName)) {
            let type = timeTable[i].description + " Totals";

            let locations = this.findLocations(
              timeTable[i].staffName,
              timeTable[i].location
            );

            for (let l = 0; l < locations.length; l++) {
              if (timeEventCount === 0) {
                timeDetail.push([timeTable[i].staffName]);
                timeDetail.push([
                  "Type",
                  location_name,
                  "Pay Rate",
                  "Hours",
                  "Pay",
                  "Comments",
                ]);
              }
              const timeDetailInstructorRefBox = new DetailReferenceBox(
                "Time",
                staffArray[m].primaryName
              );
              //timeDetailInstructorRefBox.location = locations[l];
              timeDetailInstructorRefBox.startingRef = timeDetail.length;
              // let comment = "";

              let extraComment = "";
              if (type.includes("???")) {
                //extraComment = "MISSING PUNCH OUT";
              }
              if (type.includes("-" + systemClockOutTime)) {
                extraComment = "SYSTEM CLOCK OUT";
              }

              let hoursWorked = timeTable[i].hoursWorked;

              if(mealBreakFlag !== null && hoursWorked > (parseFloat(mealBreakFlag)/60)){
                extraComment = extraComment + " - MEAL BREAK VIOLATION";
              }

              
              let isException = false;
              if(hoursWorked < 0){
                hoursWorked = 0;
                isException = true;
              }

              timeDetail.push([
                type,
                locations[l],
                timeTable[i].payRate,
                hoursWorked,
                timeTable[i].payTotal,
                timeTable[i].comment + extraComment,
              ]);

              timeDetailInstructorRefBox.endingRef = timeDetail.length;
              timeDetailInstructorRefBox.detail = timeTable[i].detail;

              this.detailInstructorRefBoxList.push(timeDetailInstructorRefBox);

              let cellRef = new CellReference(
                "Time",
                timeTable[i].staffName,
                locations[l],
                timeDetail.length,
                4
              );
              cellRef.detail = timeTable[i].detail;
              cellRef.value = type;

              this.cellRefByInstructor.push(cellRef);

              timeTable[i].addedToExcel = true;
              if(!isException){
                this.timeClockPayFormulas.push(timeDetail.length);
              }

              this.currencyLocations.push([1, 2, timeDetail.length]);
              this.currencyLocations.push([1, 4, timeDetail.length]);

              timeEventCount++;
            }
          }
        } else {
          console.warn(`No staffName found for timeTable[${i}]`, timeTable[i]);
        }
      }

      if (timeEventCount > 0) {
        timeDetail.push(this.blankExcelRow);
      }
    }

    return timeDetail;
  }

  findLocations(staffName, locationStr) {
    if (locationStr === "*Studios Worked At*") {
      return this.findLocationsWorkedAt(staffName);
    }
    return [locationStr];
  }

  findLocationsWorkedAt(staffName) {
    let locations = [];
    let classes = this.reportCompilerState.studiosInformation.classes;
    let times = this.reportCompilerState.inputFilesArrays.TIME;
    let commissions = this.reportCompilerState.studiosInformation.commissions;
    let staffObj = this.findStaffObject(staffName);

    for (let i = 0; i < classes.length; i++) {
      if (staffObj.isNamed(classes[i].instructor)) {
        locations.push(classes[i].location);
      }
    }

    for (let j = 0; j < times.length; j++) {
      if (staffObj.isNamed(times[j].staffName)) {
        locations.push(times[j].location);
      }
    }

    for (let k = 0; k < commissions.length; k++) {
      if (
        staffObj.isNamed(commissions[k].salespeople.open) ||
        staffObj.isNamed(commissions[k].salespeople.close) ||
        staffObj.isNamed(commissions[k].salespeople.instructor) ||
        staffObj.isNamed(commissions[k].salespeople.secondary)
      ) {
        locations.push(commissions[k].location);
      }
    }

    let allLocations = [...new Set(locations)];
    let onlyStudios = allLocations.filter(
      (item) => item !== "*Studios Worked At*"
    );
    return onlyStudios;
  }

  findStaffObject(name) {
    let staffArray = this.reportCompilerState.studiosInformation.staffArray;
    for (let i = 0; i < staffArray.length; i++) {
      if (staffArray[i].isNamed(name)) {
        return staffArray[i];
      }
    }
    return null;
  }

  addTimeClockPayFormulas(timeWS) {
    for (let i = 0; i < this.timeClockPayFormulas.length; i++) {
      timeWS["E" + this.timeClockPayFormulas[i]] = {
        t: "n",
        f:
          "C" +
          this.timeClockPayFormulas[i] +
          "* D" +
          this.timeClockPayFormulas[i],
        z: "$##,##0.00",
      };
    }
  }

  /**** CREATE UNADDED TAB ****/
  addUnaddedTab(wb) {
    const unaddedClasses = this.findUnaddedClasses();
    const unaddedTime = this.findUnaddedTime();
    const uncommissionedSales = this.findUncommissionedSales();
    if (
      unaddedClasses.length === 0 &&
      unaddedTime.length === 0 &&
      uncommissionedSales.length === 0
    ) {
      return;
    }
    wb.SheetNames.push("Unadded");
    const unaddedArray = [];
    this.addDetailHeader(unaddedArray);

    this.formatUnaddedClassesArray(unaddedArray, unaddedClasses);
    this.formatUnaddedTimeArray(unaddedArray, unaddedTime);
    this.formatUncommissionedSalesArray(unaddedArray, uncommissionedSales);
    const unaddedWS = XLSX.utils.aoa_to_sheet(unaddedArray);
    wb.Sheets["Unadded"] = unaddedWS;
  }

  findUnaddedClasses() {
    const unaddedClasses = [];
    const classes = this.reportCompilerState.studiosInformation.classes;

    for (let i = 0; i < classes.length; i++) {
      if (classes[i]) {
        if (!classes[i].addedToExcel) {
          unaddedClasses.push(classes[i]);
        }
      } else {
        //console.warn(classes[i]);
      }
    }
    return unaddedClasses;
  }

  findUnaddedTime() {
    const unaddedTime = [];
    const timeEvents = this.reportCompilerState.inputFilesArrays.TIME;

    for (let i = 0; i < timeEvents.length; i++) {
      if (timeEvents[i].hasOwnProperty("addedToExcel")) {
        // ~~~~ scott bug - timeEvents[i]?.addedToExcelundefined
        if (!timeEvents[i].addedToExcel) {
          unaddedTime.push(timeEvents[i]);
        }
      } else {
        console.warn("timeEvents[i].addedToExcel is undefined");
      }
    }
    return unaddedTime;
  }

  findUncommissionedSales() {
    const uncommissionedSales = [];
    const commissionArray =
      this.reportCompilerState.studiosInformation.commissions;

    for (let i = 0; i < commissionArray.length; i++) {
      let commissionItem = commissionArray[i];
      let salespeople = commissionItem.salespeople;
      let salespay = commissionItem.salespay;
      if (
        this.isRealSalesperson(salespeople.open) &&
        this.isNotStaff(salespeople.open)
      ) {
        uncommissionedSales.push({
          location: commissionItem.location,
          item: commissionItem.description,
          date: commissionItem.dateSold,
          type: "Opened",
          staff: salespeople.open,
          pay: salespay.open,
        });
      }
      if (
        this.isRealSalesperson(salespeople.close) &&
        this.isNotStaff(salespeople.close)
      ) {
        uncommissionedSales.push({
          location: commissionItem.location,
          item: commissionItem.description,
          date: commissionItem.dateSold,
          type: "Closed",
          staff: salespeople.close,
          pay: salespay.close,
        });
      }
      if (
        this.isRealSalesperson(salespeople.instructor) &&
        this.isNotStaff(salespeople.instructor)
      ) {
        uncommissionedSales.push({
          location: commissionItem.location,
          item: commissionItem.description,
          date: commissionItem.dateSold,
          type: "Instructor",
          staff: salespeople.instructor,
          pay: salespay.instructor,
        });
      }
      if (
        this.isRealSalesperson(salespeople.secondary) &&
        this.isNotStaff(salespeople.secondary)
      ) {
        uncommissionedSales.push({
          location: commissionItem.location,
          item: commissionItem.description,
          date: commissionItem.dateSold,
          type: "Secondary",
          staff: salespeople.secondary,
          pay: salespay.secondary,
        });
      }
    }

    return uncommissionedSales;
  }

  isRealSalesperson(salesperson) {
    let isReal = !(
      salesperson === null ||
      salesperson === "N/A" ||
      salesperson === "System Admin" ||
      salesperson === "Online" || 
      salesperson === "-"
    );
    return isReal;
  }

  isNotStaff(name) {
    const staffArray = this.reportCompilerState.studiosInformation.staffArray;
    for (let i = 0; i < staffArray.length; i++) {
      if (staffArray[i].isNamed(name)) {
        return false;
      }
    }
    return true;
  }

  formatUnaddedClassesArray(unaddedArray, unaddedClasses) {
    let session_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .sessionName;
    let sessions_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .sessionsName;
    let specialist_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .specialistName;
    let location_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .locationName;
    if (unaddedClasses.length > 0) {
      unaddedArray.push(["Unadded " + sessions_name]);
      unaddedArray.push([
        session_name + " Name",
        specialist_name,
        location_name,
        "Date",
        "Time",
        sessions_name + " Size",
      ]);
      for (let i = 0; i < unaddedClasses.length; i++) {
        const tempArray = [
          unaddedClasses[i].name,
          unaddedClasses[i].instructor,
          unaddedClasses[i].location,
          unaddedClasses[i].date.toDateString(),
          unaddedClasses[i].date.toLocaleTimeString("en-US"),
          unaddedClasses[i].attendeeCount,
        ];
        unaddedArray.push(tempArray);
      }
      unaddedArray.push("");
      unaddedArray.push("");
    }
  }

  formatUnaddedTimeArray(unaddedArray, unaddedTime) {
    let time_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .timeName;
    if (unaddedTime.length > 0) {
      unaddedArray.push(["Unadded " + time_name]);
      unaddedArray.push(["Location", "Staff", "Hours"]);
      for (let i = 0; i < unaddedTime.length; i++) {
        const tempArray = [
          unaddedTime[i].location,
          unaddedTime[i].staffName,
          unaddedTime[i].hoursWorked,
        ];
        unaddedArray.push(tempArray);
      }
      unaddedArray.push("");
      unaddedArray.push("");
    }
  }

  formatUncommissionedSalesArray(unaddedArray, uncommissionedSales) {
    let commission_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .commissionName;
    let location_name =
      this.reportCompilerState.inputFilesArrays.questions.namingConventions
        .locationName;
    if (uncommissionedSales.length > 0) {
      unaddedArray.push(["Unadded " + commission_name]);
      unaddedArray.push([
        "Item",
        location_name,
        "Date Sold",
        "Staff Type",
        "Staff",
        "Payment",
      ]);
      for (let i = 0; i < uncommissionedSales.length; i++) {
        const tempArray = [
          uncommissionedSales[i].item,
          uncommissionedSales[i].location,
          uncommissionedSales[i].date,
          uncommissionedSales[i].type,
          uncommissionedSales[i].staff,
          uncommissionedSales[i].pay,
        ];
        unaddedArray.push(tempArray);
      }
    }
  }

  /**** EXCEL OUTPUT GLOBAL VARIABLES ****/
  resetExcelVariables() {
    this.currencyLocations = [];
    this.cellRefByInstructor = [];
    this.detailInstructorRefBoxList = [];
    this.commissionDetailRefBoxList = [];
    this.summaryStudioInstructorLocations = [];
  }

  /**** WRITE EXCEL ****/

  writeExcel(outputFolder = false) {
    this.resetExcelVariables();

    // this.reportCompilerState.studiosInformation.studiosArray = this.createStudioArray();
    // reportCompilerDispatch({
    //   type: "UPDATE_REPORT_COMPILER_PROPERTY",
    //   path: `studiosInformation.studiosArray`,
    //   value: this.reportCompilerState.payrollInformation.studiosInInput.sort(),
    //   replace: true
    // });
    this.reportCompilerState.studiosInformation.studiosArray =
      this.reportCompilerState.payrollInformation.studiosInInput;
    const wb = this.createExcelFile();
    this.addClassDetailToExcelWorkbook(wb);
    this.addTimeDetailToExcelWorkbook(wb);
    this.addCommissionDetailToExcelWorkbook(wb);

    this.addSummaryTabToExcelWorkbook(wb);
    if (
      this.reportCompilerState.inputFilesArrays.questions.processorConfig !==
      null
    ) {
      let processor =
        this.reportCompilerState.inputFilesArrays.questions.processorConfig
          .processorName;
      wb.SheetNames.push(processor + " export");
    }
    this.addUnaddedTab(wb);
    if (
      this.reportCompilerState.inputFilesArrays.questions.displayScheduleTab
        .value
    ) {
      let increment = 15;
      //try{
      let sheet = this.createScheduleTabTemplate(increment);
      this.addTimeScheduleSheet(wb, sheet);
      //} catch(e){
      //console.log(e);
      //}
    }

    this.addStaffTabs(wb);

    let processorMapping =
      this.reportCompilerState.inputFilesArrays.questions.processorConfig;
    if (processorMapping !== null && processorMapping.columns.length > 0) {
      let processor =
        this.reportCompilerState.inputFilesArrays.questions.processorConfig
          .processorName;
      this.addProcessorTabs(wb, processor + " export");
    }

    this.addCurrencyLocations(wb);

    const excelOutputName = this.createExcelName();

    if (outputFolder) {
      XLSX.writeFile(wb, outputFolder + "/" + excelOutputName, {
        cellStyles: true,
      });
    } else {
      console.warn("No output folder...");
      XLSX.writeFile(wb, excelOutputName, { cellStyles: true });
    }

    this.reportCompilerState.output.name = excelOutputName;
    this.reportCompilerState.output.workbook = wb;
    this.reportCompilerState.output.downloaded = true;
  }

  createStudioArray() {
    const belTable = this.reportCompilerState.inputFilesArrays.BEL;
    const studiosArray = [];

    for (let i = 0; i < belTable.length; i++) {
      if (!studiosArray.includes(belTable[i].location)) {
        studiosArray.push(belTable[i].location);
      }
    }
    studiosArray.sort();
    return this.reportCompilerState.payrollInformation.studiosInInput.sort();
  }

  createExcelName() {
    const studiosString = this.buildFormatedStudioString("-");

    const today = new Date();
    const date = today.getMonth() + 1 + "-" + today.getDate();
    return studiosString + "_" + date + "_PayWell.xlsx";
  }

  //   buildFormatedStudioString(seperator) {
  //     // Get pulled locations and sort them
  //     let studiosA = getPulledLocations(this.reportCompilerState).sort();

  //     // Filter out any items that include "Studios Worked At"
  //     studiosA = studiosA.filter(studio => !studio.includes("Studios Worked At"));

  //     // Replace "Club Pilates" with "CP" in each studio name
  //     for (let i = 0; i < studiosA.length; i++) {
  //       studiosA[i] = studiosA[i].replace("Club Pilates", "CP");
  //     }

  //     // Join the array into a string separated by the given separator
  //     return studiosA.join(seperator);
  // }

  // ~~~~ 1/31/24 scott I remade this function due to an error that would occur if studiosA inlcuded a number
  buildFormatedStudioString(seperator) {
    // Get pulled locations and sort them
    let studiosA = getPulledLocations(this.reportCompilerState).sort();

    // Ensure all elements are strings
    studiosA = studiosA.map((studio) => studio.toString()).sort();

    // Filter out any items that include "Studios Worked At"
    studiosA = studiosA.filter(
      (studio) => !studio.includes("Studios Worked At")
    );

    // Replace "Club Pilates" with "CP" in each studio name
    studiosA = studiosA.map((studio) => studio.replace("Club Pilates", "CP"));

    // Join the array into a string separated by the given separator
    return studiosA.join(seperator);
  }

  addDetailHeader(array) {
    const belPayPeriods =
      this.reportCompilerState.payrollInformation.belPayPeriods;

    array.push([this.buildFormatedStudioString("/")]);
    let dateRange = "??";
    try {
      dateRange =
        belPayPeriods[0].toDateString() +
        " - " +
        belPayPeriods[1].toDateString();
    } catch (e) {
      //console.log(e);
    }
    array.push(["Pay Period: " + dateRange]);
    array.push(this.blankExcelRow);
    array.push(this.blankExcelRow);
  }

  staffWorkedAtStudio(studio, staff) {
    if (!staff || !staff.primaryName) {
      console.warn("Error: Invalid staff or missing staff name.");
      return false;
    }

    const organizedTable =
      this.reportCompilerState?.studiosInformation?.classes || [];
    const timeTable = this.reportCompilerState?.inputFilesArrays?.TIME || [];
    const agreementsTable =
      this.reportCompilerState?.inputFilesArrays?.AGREE || [];

    const retailTable = this.reportCompilerState?.inputFilesArrays?.SALE || [];

    for (let i = 0; i < organizedTable.length; i++) {
      const instructor = organizedTable[i]?.instructor;
      if (
        instructor &&
        organizedTable[i].location === studio &&
        staff.isNamed(instructor) //huh
      ) {
        return true;
      }
    }

    for (let j = 0; j < timeTable.length; j++) {
      const staffName = timeTable[j]?.staffName;
      if (
        staffName &&
        timeTable[j].location === studio &&
        staff.isNamed(staffName)
      ) {
        return true;
      }
    }

    for (let x = 0; x < agreementsTable.length; x++) {
      const primarySalesperson =
        agreementsTable[x]?.salespeople?.PrimarySalesperson;
      const secondarySalesperson =
        agreementsTable[x]?.salespeople?.SecondarySalesperson;
      if (
        (primarySalesperson || secondarySalesperson) &&
        agreementsTable[x].location === studio &&
        (staff.isNamed(primarySalesperson) ||
          staff.isNamed(secondarySalesperson))
      ) {
        return true;
      }
    }

    for (let r = 0; r < retailTable.length; r++) {
      const primarySalesperson =
        retailTable[r]?.salespeople?.PrimarySalesperson;
      const secondarySalesperson =
        retailTable[r]?.salespeople?.SecondarySalesperson;
      if (
        (primarySalesperson || secondarySalesperson) &&
        retailTable[r].location === studio &&
        (staff.isNamed(primarySalesperson) ||
          staff.isNamed(secondarySalesperson))
      ) {
        return true;
      }
    }

    return false;
  }

  isStaff(name) {
    const staffArray = this.reportCompilerState.studiosInformation.staffArray;
    let staff = false;
    for (let i = 0; i < staffArray.length; i++) {
      if (staffArray[i].isNamed(name)) {
        staff = true;
      }
    }
    return staff;
  }
  addCurrencyLocations(wb) {
    for (let q = 0; q < this.currencyLocations.length; q++) {
      try {
        const worksheet =
          wb.Sheets[wb.SheetNames[this.currencyLocations[q][0]]];
        const cellName =
          this.alphabet.charAt(this.currencyLocations[q][1]) +
          this.currencyLocations[q][2];
        worksheet[cellName].z = "$##,##0.00"; // ~~~~ scott, this is throwing an error, undefined .z?
      } catch (e) {
        console.warn(e);
        //console.warn(this.currencyLocations[q]);
      }
    }
  }
  createExcelFile() {
    const wb = XLSX.utils.book_new();
    wb.Props = {
      Title: "Payroll",
      Author: "PayWell",
    };
    return wb;
  }

  /**** ON DOWNLOAD ****/
  addCloseCounterpart = (names) => {
    let multiName = {};
    for (let v = 0; v < names.length; v++) {
      multiName[names[v]] = {
        name: null,
        distance: names[v].length - names[v].length / 2,
      };
      const staffArray = this.reportCompilerState.studiosInformation.staffArray;
      for (let i = 0; i < staffArray.length; i++) {
        let staffNames = staffArray[i].getNames();
        for (let n = 0; n < staffNames.length; n++) {
          let distance = ExcelOutput.levenshteinDistance(
            names[v],
            staffNames[n]
          );
          if (distance < multiName[names[v]].distance) {
            multiName[names[v]] = {
              name: staffNames[n],
              distance: distance,
            };
          }
        }
      }
    }
    return multiName;
  };

  static levenshteinDistance = (s, t) => {
    if (!s.length) return t.length;
    if (!t.length) return s.length;
    const arr = [];
    for (let i = 0; i <= t.length; i++) {
      arr[i] = [i];
      for (let j = 1; j <= s.length; j++) {
        arr[i][j] =
          i === 0
            ? j
            : Math.min(
              arr[i - 1][j] + 1,
              arr[i][j - 1] + 1,
              arr[i - 1][j - 1] + (s[j - 1] === t[i - 1] ? 0 : 1)
            );
      }
    }
    return arr[t.length][s.length];
  };
  createUnaddedList(type) {

    if (this.reportCompilerState.payrollInformation.staffInFiles.length > 0) {
      let typeArray =
        this.reportCompilerState.payrollInformation.staffInFiles.filter(
          (staff) => staff.type === type || type === "ALL"
        );
      let nonStaffArray = typeArray.filter((staff) =>
        this.isNotStaff(staff.name)
      );
      let uniqueArray = [];
      for (let i = 0; i < nonStaffArray.length; i++) {
        if (uniqueArray.includes(nonStaffArray[i].name)) {
          continue;
        }
        uniqueArray.push(nonStaffArray[i].name);
      }
      let cleanArray = uniqueArray.filter(
        (name) =>
          !(
            name.length === 0 ||
            name.includes("Guest Instructor") ||
            name === "N/A" ||
            name === "System Admin" ||
            name === "Online" ||
            name === "-"
          )
      );

      let namesWithCounterparts = this.addCloseCounterpart(cleanArray);
      return namesWithCounterparts;
    }
  }

  createUnaddedSessions() {
    let unaddedSessions = [];
    // let data = this.reportCompilerState.inputFilesArrays;

    // // Step 1: Filter keys that start with "bel"
    // const belKeys = Object.keys(data).filter((key) => key.startsWith("bel"));

    // // Step 2 & 3: Map over these keys to get arrays and concatenate them
    // const concatenatedArrays = belKeys.flatMap((key) => data[key]);

    // // Step 4: Filter by className
    // const filteredByClassName = concatenatedArrays.map(
    //   (item) => item.className
    // );

    // const finalClasses = Array.from(new Set(filteredByClassName));

    let finalClasses = this.reportCompilerState.studiosInformation.classes.map(
      (item) => item.name
    );

    finalClasses = Array.from(new Set(finalClasses));

    if (finalClasses.length > 0) {
      for (let i = 0; i < finalClasses.length; i++) {
        if (!this.findNearestClassName(finalClasses[i])) {
          unaddedSessions.push(finalClasses[i]);
        }
      }
    }
    //do we want to add similar options, the more I think about it, the less I think its neccesary
    return unaddedSessions;
  }

  findNearestClassName(name) {
    let classNamesArray = this.getClassNameArray();
    for (let i = 0; i < classNamesArray.length; i++) {
      if (name.includes(classNamesArray[i])) {
        return true;
      }
    }
    return false;
  }

  getClassNameArray() {
    let classNames = [];
    let classBuckets =
      this.reportCompilerState.inputFilesArrays.questions.classBuckets;
    for (let i = 0; i < classBuckets.length; i++) {
      classNames = classNames.concat(classBuckets[i].classNames);
    }
    return classNames;
  }

  // Before GPT optimization
    // createUnaddedStaffLists() {
    //   let unaddedStaffObjs = [];
    //   let unaddedCloseStaffObjs = [];
    //   let unaddedStaffLocationObjs = [];
    //   let incorrectTypeStaffObjs = [];
 
    //   let staffInFiles = this.reportCompilerState.payrollInformation.staffInFiles;
    //   if (staffInFiles.length > 0) {
    //     for (let i = 0; i < staffInFiles.length; i++) {
    //       let nameInQuestion = staffInFiles[i].name;
    //       let locationInQuestion = staffInFiles[i].location;
    //       if (
    //         nameInQuestion.length === 0 ||
    //         nameInQuestion.includes("Guest Instructor") ||
    //         nameInQuestion === "N/A" ||
    //         nameInQuestion === "System Admin" ||
    //         nameInQuestion === "Online"
    //       ) {
    //         continue;
    //       }
    //       let nearestName = this.findNearestStaffName(nameInQuestion);
    //       if (
    //         nearestName.distance > 0 &&
    //         nearestName.distance < nameInQuestion.length / 2
    //       ) {
    //         unaddedCloseStaffObjs.push({
    //           error: "Close Name Match",
    //           name: nameInQuestion,
    //           nearestName: nearestName.name,
    //           //location: locationInQuestion
    //         });
    //       } else if (nearestName.distance === 0) {
    //         if (!nearestName.nameObj.location.includes(locationInQuestion)) {
    //           unaddedStaffLocationObjs.push({
    //             error: "No Location Match",
    //             name: nameInQuestion,
    //             location: locationInQuestion,
    //           });
    //         } else {
    //           let staffTypes =
    //             this.reportCompilerState.inputFilesArrays.questions.staffTypes;
    //           let staffClassTypes = staffTypes
    //             .filter((type) => type.sessionPay)
    //             .map((type) => type.name);
    //           let staffType = nearestName.nameObj.getProperty(
    //             locationInQuestion,
    //             "type"
    //           );
    //           if (
    //             !staffClassTypes.includes(staffType) &&
    //             staffInFiles[i].type === "class"
    //           ) {

    //             //try this out
    //             let classStaffTypes = this.getClassStaffTypes();
    //             incorrectTypeStaffObjs.push({
    //               error: "Incorrect Type",
    //               name: nameInQuestion,
    //               type: staffType,

    //               //location: locationInQuestion,
    //             });
    //           }
    //         }
    //       } else {
    //         unaddedStaffObjs.push({
    //           error: "No Name Match",
    //           name: nameInQuestion,
    //           nearestName: null,
    //           //location: locationInQuestion
    //         });
    //       }
    //     }
    //   }

    //   return {
    //     unaddedStaffObjs: unaddedStaffObjs,
    //     unaddedCloseStaffObjs: unaddedCloseStaffObjs,
    //     unaddedStaffLocationObjs: unaddedStaffLocationObjs,
    //     incorrectTypeStaffObjs: incorrectTypeStaffObjs,
    //   };
    // }


  createUnaddedStaffLists() {
    const unaddedStaffObjs = [];
    const unaddedCloseStaffObjs = [];
    const unaddedStaffLocationObjs = [];
    const incorrectTypeStaffObjs = [];

    const staffInFiles = this.reportCompilerState.payrollInformation.staffInFiles;
    if (staffInFiles.length === 0) return { unaddedStaffObjs, unaddedCloseStaffObjs, unaddedStaffLocationObjs, incorrectTypeStaffObjs };

    // Pre-compute and cache staffClassTypes
    const staffTypes = this.reportCompilerState.inputFilesArrays.questions.staffTypes;
    const staffClassTypesSet = new Set(staffTypes.filter(type => type.sessionPay).map(type => type.name));

    // Cache result of getClassStaffTypes if it's independent of each iteration
    // const classStaffTypes = this.getClassStaffTypes(); // Uncomment if needed

    for (const staff of staffInFiles) {
      const { name: nameInQuestion, location: locationInQuestion, type: staffFileType } = staff;
      if (!nameInQuestion || ["Guest Instructor", "N/A", "System Admin", "Online", "-"].includes(nameInQuestion)) {
        continue;
      }

      const nearestName = this.findNearestStaffName(nameInQuestion);
      if (nearestName.distance > 0 && nearestName.distance < nameInQuestion.length / 2) {
        unaddedCloseStaffObjs.push({ error: "Close Name Match", name: nameInQuestion, nearestName: nearestName.name });
      } else if (nearestName.distance === 0) {
        if (!nearestName.nameObj.location.includes(locationInQuestion)) {
          unaddedStaffLocationObjs.push({ error: "No Location Match", name: nameInQuestion, location: locationInQuestion });
        } else {
          const staffType = nearestName.nameObj.getProperty(locationInQuestion, "type");
          if (!staffClassTypesSet.has(staffType) && staffFileType === "class") {
            incorrectTypeStaffObjs.push({ error: "Incorrect Type", name: nameInQuestion, type: staffType });
          }
        }
      } else {
        unaddedStaffObjs.push({ error: "No Name Match", name: nameInQuestion, nearestName: null });
      }
    }

    return { unaddedStaffObjs, unaddedCloseStaffObjs, unaddedStaffLocationObjs, incorrectTypeStaffObjs };
  }

  getClassStaffTypes() {
    let staffTypes = this.reportCompilerState.inputFilesArrays.questions.staffTypes;
    let classStaffTypes = staffTypes.filter(type => type.sessionPay).map(type => type.name);
    return classStaffTypes;
  }

  findNearestStaffName(name) {
    let staffArray = this.reportCompilerState.studiosInformation.staffArray;
    let nearestName = null;
    let distance = 999999999;
    for (let i = 0; i < staffArray.length; i++) {
      //staff found
      if (staffArray[i].isNamed(name)) {
        return {
          name: staffArray[i].primaryName,
          nameObj: staffArray[i],
          distance: 0,
        };
      }
      let staffNames = staffArray[i].getNames();
      for (let n = 0; n < staffNames.length; n++) {
        let tempDistance = ExcelOutput.levenshteinDistance(name, staffNames[n]);
        if (tempDistance < distance) {
          distance = tempDistance;
          nearestName = staffArray[i];
        }
      }
    }
    return {
      name: nearestName.primaryName,
      nameObj: nearestName,
      distance: distance,
    };
  }

  // ~~~~ This is not altering the state/output, ask scott what this does and what "unadded" array should be added to.
  adjustAgreements() {
    let unadded = [];
    for (
      let i = 0;
      i < this.reportCompilerState.inputFilesArrays.SALE.length;
      i++
    ) {
      let salesItem = this.reportCompilerState.inputFilesArrays.SALE[i];
      if (salesItem.id === "") {
        continue;
      }
      let found = false;
      for (
        let j = 0;
        j < this.reportCompilerState.inputFilesArrays.AGREE.length;
        j++
      ) {
        let agreeItem = this.reportCompilerState.inputFilesArrays.AGREE[j];
        if (
          salesItem.id === agreeItem.id &&
          salesItem.description === agreeItem.description
        ) {
          found = true;
          if (
            //salesItem.salesPerson !== agreeItem.salespeople.PrimarySalesperson
            salesItem.salespeople.PrimarySalesperson !==
            agreeItem.salespeople.PrimarySalesperson
          ) {
            // agreeItem.salespeople.PrimarySalesperson = salesItem.salesPerson;
            agreeItem.salespeople.PrimarySalesperson =
              salesItem.salespeople.PrimarySalesperson;
          }
        }
      }
      if (!found) {
        unadded.push(salesItem);
      }
    }
  }

  resetGlobalVariables() {
    return initialReportCompiler;
  }
}
